import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Button, Form, Header } from "semantic-ui-react";

import * as GeneralActions from "../../../../actions";
import { Input } from "../../../../components/input";
import { Spacer, SpacerSize } from "../../../../components/spacer";
import {
  BreakpointMedia,
  BreakpointSize,
  PageContainer,
  styled
} from "../../../../theme";
import { TeamSketchCard } from "../../components/BoxContainer";
import userProfileFormReducer, {
  initialState,
  actionUserFormSetFormValue,
  actionUserFormUpdateSession
} from "../../reducers/userProfileFormReducer";
import { State } from "../../store";
import { ThunkDispatch } from "redux-thunk";
import { updateUserInfo } from "../../actions/user";
import { submitFeedback } from "../../components/FeedbackButton";
import { Dispatch } from "redux";
import UserSubscriptionList from "../../components/billing/UserSubscriptionList";
import PaymentSourcesTable from "../../components/billing/PaymentSourcesTable";
import { CreatePaymentSourceModal } from "../../components/billing/CreateSourceModal";

interface ArtistPageProps extends ReturnType<typeof mapStateToProps> {
  dispatch: ThunkDispatch<{}, {}, any>;
}

export const StyledBoxGird = styled.div`
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  @media ${BreakpointMedia.laptopL} {
    margin-left: -20px;
    margin-right: -20px;
  }
`;

export const StyledBoxCell = styled.div`
  width: 100%;
  @media (max-width: ${BreakpointSize.tablet - 1}px) {
    ${TeamSketchCard} {
      padding-left: 0;
      padding-right: 0;
      background-color: transparent;
      box-shadow: none;
    }
  }
  @media ${BreakpointMedia.laptopL} {
    width: 50%;
    &:first-child {
      padding-right: 10px;
    }
    &:nth-child(2n) {
      padding-left: 10px;
    }
  }
`;

const UserActionsCard: React.FC<{}> = props => {
  const { t } = useTranslation();
  return (
    <TeamSketchCard>
      <Header as="h2" content="User Actions" />
      {/* <Menu text vertical> */}
      <Button
        onClick={() => {
          submitFeedback("support request");
        }}
      >
        {t("CONTACT_SUPPORT")}
      </Button>
      <Button name="Logout" onClick={e => GeneralActions.logout()}>
        {t("LOGOUT")}
      </Button>
    </TeamSketchCard>
  );
};

const mapStateToProps = (state: State) => ({
  session: state.user.session,
  pricing: state.env.pricing.show
});

interface UserPageProps extends ReturnType<typeof mapStateToProps> {
  dispatch: Dispatch;
}

/**
 * Artist/User Page
 */
const UserPage: React.FC<ArtistPageProps> = (props: UserPageProps) => {
  const { t } = useTranslation();
  if (!props.session) {
    return null;
  }
  return (
    <PageContainer>
      <div>
        <h2>{t("USER_SETTINGS", { name: props.session.user.name })}</h2>
        <Spacer size={SpacerSize.tiny} />
      </div>
      <StyledBoxGird>
        <StyledBoxCell>
          <TeamSketchCard>
            <Header as="h2" content="Profile Settings" />
            <ProfileEditor {...props} />
          </TeamSketchCard>
        </StyledBoxCell>

        <StyledBoxCell>
          <UserActionsCard />

          {props.pricing && (
            <>
              <TeamSketchCard>
                <UserSubscriptionList
                  onSelect={sub => console.log(sub)}
                  artistid={props.session.user.id}
                />
              </TeamSketchCard>
              <TeamSketchCard>
                <PaymentSourcesTable />
                <CreatePaymentSourceModal />
              </TeamSketchCard>
            </>
          )}
        </StyledBoxCell>
      </StyledBoxGird>
    </PageContainer>
  );
};

export default connect(mapStateToProps)(UserPage);
UserPage.displayName = "ArtistPage";

/**
 * Utility components
 */

const ProfileEditor = (props: ArtistPageProps) => {
  const [userFormState, userFormDispatch] = React.useReducer(
    userProfileFormReducer,
    initialState(props.session)
  );

  const [didChange, setDidChange] = React.useState(false);

  const dispatch = props.dispatch;
  const user = props.session.user;
  const onSubmit = React.useCallback(() => {
    dispatch(
      updateUserInfo(
        userFormState.formData["userName"].value,
        userFormState.formData["firstName"].value,
        userFormState.formData["lastName"].value,
        userFormState.formData["salutation"].value,
        userFormState.formData["password"].value,
        userFormState.formData["description"].value
      )
    );
  }, [userFormState, dispatch]);

  React.useEffect(() => {
    userFormDispatch(actionUserFormUpdateSession(props.session));
  }, [props.session]);
  React.useEffect(() => {
    const didChange =
      userFormState.formData["userName"].value !== user.name ||
      userFormState.formData["firstName"].value !== user.firstName ||
      userFormState.formData["lastName"].value !== user.lastName ||
      userFormState.formData["salutation"].value !== user.salutation ||
      userFormState.formData["password"].value !== "" ||
      userFormState.formData["description"].value !== user.description;
    setDidChange(didChange);
  }, [setDidChange, userFormState, user]);

  const { t } = useTranslation();
  return (
    <Form onSubmit={onSubmit}>
      <div className="fields">
        <div className="six wide field">
          <Input
            label={t("SALUTATION")}
            placeholder={t("SALUTATION")}
            value={userFormState.formData["salutation"]}
            onChange={val =>
              userFormDispatch(actionUserFormSetFormValue("salutation", val))
            }
          />
        </div>
        <div className="ten wide field">
          <Input
            label={t("USERNAME")}
            placeholder={t("USERNAME")}
            value={userFormState.formData["userName"]}
            onChange={val =>
              userFormDispatch(actionUserFormSetFormValue("userName", val))
            }
          />
        </div>
      </div>
      <div className="fields">
        <div className="six wide field">
          <Input
            label={t("FIRST_NAME")}
            placeholder={t("FIRST_NAME")}
            value={userFormState.formData["firstName"]}
            onChange={val =>
              userFormDispatch(actionUserFormSetFormValue("firstName", val))
            }
          />
        </div>
        <div className="ten wide field">
          <Input
            label={t("LAST_NAME")}
            placeholder={t("LAST_NAME")}
            value={userFormState.formData["lastName"]}
            onChange={val =>
              userFormDispatch(actionUserFormSetFormValue("lastName", val))
            }
          />
        </div>
      </div>
      <Input
        label={t("EMAIL")}
        autoComplete="email"
        placeholder={t("EMAIL")}
        value={userFormState.formData["email"]}
        onChange={val =>
          userFormDispatch(actionUserFormSetFormValue("email", val))
        }
        type="email"
        lowercase={false}
      />
      <Input
        autoComplete="current-password"
        label={t("PASSWORD")}
        placeholder="leave empty to keep old password"
        type="password"
        value={userFormState.formData["password"]}
        onChange={val =>
          userFormDispatch(actionUserFormSetFormValue("password", val))
        }
      />
      {userFormState.complete ? null : (
        <p>Please fill in the missing information!</p>
      )}
      <Button
        disabled={!didChange}
        type="submit"
        active={userFormState.complete}
      >
        Update your profile
      </Button>
    </Form>
  );
};
