import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";

import { loginError, loginSetField, setPage } from "../../../actions";
import {
  actionAddErrorToast,
  actionAddInfoToast
} from "../../../reducers/toast";
import { getClient } from "../../../services-async/transport";
import { ServiceClient as UserClient } from "../../../services-async/users";
import * as UsersVo from "../../../services-async/vo/users";
import * as Actions from "../actions";
import { handleErr, reload } from "../middleware";
import { PAGES } from "../Page";
import { loadTeamState } from "../reducers/teams";
import Router from "../router";
import { State } from "../store";
import { SIGNUP_FORM_ID } from "../reducers/signup";
import { actionSetUserSession } from "../reducers/user";

const userClient = getClient(UserClient);

/**
 * register a new user using the new v2 api
 * @param email
 * @param password
 */
export const register = (
  email: string,
  password: string
): ThunkAction<Promise<void>, State, {}, AnyAction> => async (
  dispatch,
  getState
) => {
  userClient
    .registerv2(email, password)
    .then(() => {
      reload(dispatch, () => {
        Router.home();
      });
    })
    .catch(err => {
      switch (err.errorType) {
        case UsersVo.GoConst.ErrorUserExists:
          dispatch(
            actionAddErrorToast("REGISTER_ERR", "REGISTER_USER_TAKEN", true)
          );
          break;
        case UsersVo.GoConst.ErrorInvalidEmail:
          dispatch(
            actionAddErrorToast("REGISTER_ERR", "REGISTER_EMAIL_INVALID", true)
          );
          break;
      }
    });
};

/**
 *
 */
export const setPasswordAndLogin = (
  email: string,
  password: string,
  newPassword: string
): ThunkAction<Promise<any>, State, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, any>,
  getState: () => State
) => {
  try {
    const result = await userClient.setPasswordAndLogin(
      email,
      password,
      newPassword
    );

    // update user data
    await dispatch(loadTeamState(false));

    // redirect to home
    Router.home();
    return result;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export const forgotPassword = (email: string) => async (
  dispatch: ThunkDispatch<{}, {}, any>,
  getState: () => State
) =>
  userClient
    .resetPassword(email)
    .then(() => setPage(PAGES.forgotPasswordSuccess, { email }))
    .catch(err => {
      switch (err.errorType) {
        case UsersVo.GoConst.ErrorUnknownEmail:
          dispatch(
            actionAddErrorToast(
              "FORGOT_PASS_ERR",
              "FORGOT_PASS_UNKNOWN_EMAIL",
              true
            )
          );
          return;
      }
      handleErr(dispatch);
    });

/**
 * update a users profile information
 * @param userName
 * @param firstName
 * @param lastName
 * @param salutation
 * @param password
 * @param description
 */
export const updateUserInfo = (
  userName?: string,
  firstName?: string,
  lastName?: string,
  salutation?: string,
  password?: string,
  description?: string
) => async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => State) => {
  userClient
    .update(
      userName,
      firstName,
      lastName,
      salutation,
      password,
      description,
      []
    )
    .then(() =>
      reload(dispatch, () =>
        dispatch(actionAddInfoToast("User Info updated", ""))
      )
    )
    .catch(err => handleErr(dispatch));
};

/**
 * Async actions
 */
export const checkInvited = (email: string) => async (
  dispatch: ThunkDispatch<{}, {}, any>,
  getState: () => State
) => {
  try {
    let invited = await userClient.invited(email);
    dispatch({ type: Actions.ACTION_INVITED, invited });
    let st: State = getState();
    if (st.invite.invited) {
      // jsCookie.set("cookies", "yes", { expires: 7, path: "/" });
      // login();
      loginSetField("email", email);
      Actions.formInput({ prefix: SIGNUP_FORM_ID, id: "email" }, email);
      console.log(
        "you are invited ------ let us continue at",
        st.router.forbidden
      );
      Router.tryForbidden(st.router.forbidden || "/");
    } else {
      console.log(" he should be invited by now", st.invite.invited);
    }
  } catch (e) {
    console.error("checkInvited failed:", e);
  }
};

export const login = (
  email: string,
  pass: string,
  redirect: boolean = true
) => async (dispatch: ThunkDispatch<{}, {}, any>, getState: () => State) => {
  try {
    const data = await userClient.login(email, pass);
    // set session data
    dispatch(actionSetUserSession(data));

    // reload client data
    dispatch(loadTeamState());

    const state = getState();
    // redirect if login window was prompted due to access restrictions
    if (state.router && state.router.forbidden) {
      Router.tryForbidden(state.router.forbidden);
    } else {
      // otherwise redirect to home
      Router.home();
    }
  } catch (err) {
    if (err.errorType) {
      loginError(err.errorType);
    } else {
      handleErr(dispatch)(err);
    }
  }
};
