const stripeURL = "https://js.stripe.com/v3/";
export const stripePubKey = "pk_test_mes9aZDA15QavWtdgVAO7Avk";

const isStripeLoaded = () =>
  typeof window !== "undefined" && typeof window["Stripe"] !== "undefined";

const loadStripeIfRequired = (callback: () => void) => {
  // check if we need stripe
  if (isStripeLoaded()) {
    console.debug("Stripe is already loaded");
    return;
  }

  // load stripe
  const stripeScript = document.createElement("script");
  stripeScript.src = stripeURL;
  stripeScript.async = true;
  stripeScript.onload = callback;
  if (document.body) {
    document.body.appendChild(stripeScript);
  }
};

// define a class
class StripeProvider {
  // each instance of the Observer class
  // starts with an empty array of things (observers)
  // that react to a state change
  constructor() {
    this.observers = [];
  }
  private stripeInstance: stripe.Stripe;
  private observers: Array<(stripeInstance: stripe.Stripe) => void>;

  // add the ability to subscribe to a new object / DOM element
  // essentially, add something to the observers array
  getStripeInstance(f: (stripeInstance: stripe.Stripe) => void) {
    if (this.stripeInstance) {
      f(this.stripeInstance);
      return;
    }

    this.observers.push(f);
    loadStripeIfRequired(this.notify);
  }

  // add the ability to unsubscribe from a particular object
  // essentially, remove something from the observers array
  unsubscribe(f: (stripeInstance: stripe.Stripe) => void) {
    this.observers = this.observers.filter(subscriber => subscriber !== f);
  }

  // update all subscribed objects
  // and pass some data to each of them
  notify = () => {
    // Try creating a stripe instance
    this.stripeInstance = Stripe(stripePubKey);
    console.log("Loaded stripe", this.stripeInstance);
    this.observers.forEach(observer => observer(this.stripeInstance));
  };
}

export const GlobalStripeProvider = new StripeProvider();
