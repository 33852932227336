import * as React from "react";
import { Filter } from "../../../services/vo/client";
import * as Actions from "../actions";
import { FilteredSketchList } from "../components/sketchList/filteredSketchList";
import { SKETCH_DELETION_CONTEXT } from "../middleware/project";
import Router from "../router";
import { State } from "../store";
import { PageContainer } from "../../../theme";

export const ProjectPage: React.FC<{ state: State }> = ({ state }) => {
  const projectState = state.project;
  return (
    <PageContainer>
      <FilteredSketchList
        label={projectState.project.name}
        tag="Project"
        list={{
          teamsState: state.teams,
          deletionContext: SKETCH_DELETION_CONTEXT,
          filter: (filters: Filter[]) => {
            Router.project(projectState.project.id, filters);
          },
          globalFilters: state.client.globalFilters,
          selection: projectState.selection,
          listing: projectState.listing,
          nextPageLoader: Actions.getProjectNextPage
        }}
        menuItems={[
          {
            handler: e => Router.trash(projectState.project.id),
            label: "Trash"
          },
          {
            handler: e => Router.editProject(projectState.project.id),
            label: "Settings"
          }
        ]}
      >
        {projectState.project.description}
      </FilteredSketchList>
    </PageContainer>
  );
};

ProjectPage.displayName = "ProjectPage";
