import * as React from "react";
import { styled } from "../theme";
const FixedOuterWrapper = styled.div<{ percentage: number }>`
  position: relative;
  width: 100%;
  height: auto;
  padding-top: ${props => props.percentage}%;
  overflow: hidden;
`;

const FixedContentWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0px;
`;

interface FixedAspectRatioBoxProps {
  style?: { [index: string]: any };
  ratio?: string;
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  className?: string;
}

const FixedAspectRatioBox = (props: FixedAspectRatioBoxProps) => {
  const ratioString = typeof props.ratio !== "undefined" ? props.ratio : "16:9";
  // Destructure resulting array into 'width' and 'height'
  const [widthString, heightString] = ratioString.split(":");

  if (!widthString || !heightString) {
    throw new Error(
      "FixedAspectRation element ratio must be of form: width:height"
    );
  }

  const width = parseFloat(widthString);
  const height = parseFloat(heightString);

  // Calculate percentage value using 'width' and 'height'
  const percentage = (height / width) * 100;

  return (
    <FixedOuterWrapper
      className={props.className}
      style={props.style}
      percentage={percentage}
    >
      <FixedContentWrapper onClick={props.onClick}>
        {props.children}
      </FixedContentWrapper>
    </FixedOuterWrapper>
  );
};

export default FixedAspectRatioBox;
