import * as GeneralActions from "../../../actions";
import * as Actions from "../actions";

import {
  newSelection,
  SketchSelection,
  sketchSelection
} from "./sketchSelection";

import { Result } from "../../../services/vo/search";
import { USER_SELECT_SKETCH, USER_SET_SELECTION_ENABLED } from "./user";

export interface SearchState {
  q: string;
  result?: Result;
  selection: SketchSelection;
}

const SELECTION_ID = "searchSketchSelection";

let selectionReducer = sketchSelection(SELECTION_ID);

export const search = (
  state: SearchState = {
    q: "",
    result: null,
    selection: newSelection(SELECTION_ID)
  },
  action: any
) => {
  switch (action.type) {
    case Actions.LOAD_SEARCH_RESULT:
      return Object.assign({}, state, { result: action.result });
    case Actions.SEARCH_SKETCHES:
      return Object.assign({}, state, { q: action.q });
    case GeneralActions.SET_PAGE:
    case USER_SET_SELECTION_ENABLED:
    case USER_SELECT_SKETCH:
      return Object.assign({}, state, {
        selection: selectionReducer(state.selection, action)
      });
  }
  return state;
};
