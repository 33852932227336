import * as Actions from "../actions";

export const allUsers = (state, action) => {
  if (state === undefined) {
    return [];
  }
  switch (action.type) {
    case Actions.LOAD_ALL_USER_INFOS:
      return action.userInfos;
  }
  return state;
};
