import * as React from "react";

import {} from "semantic-ui-react";
import { Section } from "../../../components/section";
import { ContentText, PageContainer } from "../../../theme";
import { Grid } from "semantic-ui-react";
import { Header } from "semantic-ui-react";

export const SignupSuccessPage = (props: {}) => {
  return (
    <Section>
      <PageContainer>
        <Grid>
          <Grid.Row>
            <Grid.Column textAlign="center">
              <ContentText>
                <Header as="h1" content="Welcome!" />
                <p>
                  You have successfully registered - please check your mail and
                  follow the instructions in the email.
                </p>
              </ContentText>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </PageContainer>
    </Section>
  );
};
