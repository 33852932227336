import * as GeneralActions from '../../../actions';
import {ServiceClient as ProjectsClient} from '../../../services/projects';
import {ServiceClient as SearchClient} from '../../../services/search';
import * as Actions from '../actions';
import * as Middleware from '../middleware';
import {PAGES} from '../Page';
import {State} from '../store';

export const SKETCH_DELETION_CONTEXT = 'user';

export const searchMiddleware = (store: any) => (next: any) =>
    (action: any) => {
      let st: State = store.getState();
      switch (action.type) {
        case Actions.DELETE_SKETCHES:
          if (action.context === SKETCH_DELETION_CONTEXT) {
            ProjectsClient.defaultInst.deleteSketches(action.sketchIds, err => {
              Actions.searchSketches(
                  st.search.q,
                  st.search.result ? st.search.result.listing.filters : null);
            }, Middleware.handleErr(store.dispatch));
          }
          break;
        case Actions.SEARCH_SKETCHES:
          SearchClient.defaultInst.search(
              action.q, action.filters, (result, err) => {
                GeneralActions.setPage(PAGES.search);
                Actions.loadSearchResult(result);
              }, Middleware.handleErr(store.dispatch));
          break;
      }
      return next(action);
    };
