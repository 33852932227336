import * as React from "react";
import {
  CardElement,
  injectStripe,
  ReactStripeElements
} from "react-stripe-elements";
import {
  Button,
  ButtonGroup,
  Dimmer,
  Divider,
  Form,
  Input,
  Loader,
  FormProps
} from "semantic-ui-react";
import { Spacer, SpacerSize } from "../../../../../components/spacer";
import { ServiceClient as UsersClient } from "../../../../../services/users";
import { ServiceError } from "../../../../../services-async/vo/services";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      backgroundColor: "#EEE",
      fontSize: "18px",
      letterSpacing: "0.025em",
      "::placeholder": {
        color: "#ECECEC"
      }
    },
    invalid: {
      color: "#9e2146"
    }
  }
};

interface CheckoutProps extends ReactStripeElements.InjectedStripeProps {
  onSubmitted?: { (res: ServiceError | XMLHttpRequest): void };
}

class _CheckoutForm extends React.Component<
  CheckoutProps,
  { isLoading: boolean }
> {
  constructor(props: CheckoutProps) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  handleSubmit = (ev: React.FormEvent<HTMLFormElement>, data: FormProps) => {
    ev.preventDefault();
    this.setState({
      isLoading: true
    });
    if (this.props.stripe) {
      this.props.stripe
        .createSource({
          type: "card",
          currency: "eur",
          owner: {
            name: (ev.target as any).name.value
          }
        })
        .then(payload => {
          // When we get the stripe response we should relay the data to our server
          this.sendCheckoutPayloadToServer(payload);
        });
    } else {
      console.log("Form submitted before Stripe.js loaded.");
      this.setState({
        isLoading: false
      });
    }
  };

  sendCheckoutPayloadToServer = (payload: stripe.SourceResponse) => {
    // console.log(payload);
    if (payload.error || !payload.source) {
      console.error("failed to send checkout payload:", payload.error);
    }
    UsersClient.defaultInst.addPaymentSource(
      payload.source!.id,
      "card",
      payload.source!.card!.fingerprint,
      e => {
        this.setState({
          isLoading: false
        });
        if (this.props.onSubmitted) {
          this.props.onSubmitted(e);
        }
      },
      e => {
        this.setState({
          isLoading: false
        });

        if (this.props.onSubmitted) {
          this.props.onSubmitted(e);
        }
      }
    );
  };

  render() {
    return this.props.stripe ? (
      <>
        <Dimmer active={this.state.isLoading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        <Form onSubmit={this.handleSubmit}>
          <Form.Group />
          <label>Full Name</label>
          <br />
          <Input name="name" type="text" placeholder="Full Name" required />
          <Spacer />
          <CardElement {...CARD_ELEMENT_OPTIONS} />
          <Spacer size={SpacerSize.small} />
          <Divider />
          <ButtonGroup floated="right">
            <Button>Add Payment Method</Button>
          </ButtonGroup>
          <Spacer size={SpacerSize.small} />
        </Form>
      </>
    ) : (
      <div className="StripeElement loading" />
    );
  }
}

export const CardCheckoutForm = injectStripe(_CheckoutForm);
