import { AnyAction, Reducer } from "redux";

import * as Actions from "../actions";
import { GoConst as UsersConst } from "../services/vo/users";

export interface LoginState {
  email: string;
  password: string;
  error: string;
  env: string;
  capsLock: boolean;
}

// {type:string;field:string;value:string;}|{type:string;error:string}
export const login: Reducer<LoginState, AnyAction> = (
  state: LoginState | undefined,
  action: any
): LoginState => {
  if (state === undefined) {
    return {
      email: "",
      password: "",
      error: "",
      env: "...",
      capsLock: false
    };
  }
  switch (action.type) {
    case Actions.SET_ENV:
      return Object.assign({}, state, { env: action.env });
    case Actions.LOGIN_CAPS_LOCK:
      return Object.assign({}, state, { capsLock: action.lock });
    case Actions.LOGIN_PREPARE:
    case Actions.LOGIN:
      return Object.assign({}, state, {
        email: action.email,
        password: action.password
      });
    case Actions.LOGIN_ERROR:
      var loginError = "login failed";
      switch (action.code) {
        case UsersConst.ErrorUnknownEmail:
          loginError = "maybe you used another email?";
          break;
        case UsersConst.ErrorAuthUnconfirmed:
          loginError = "Please confirm your account! Click here to request a new mail if your token link expired.";
          break;
        case UsersConst.ErrorAuthPassword:
          loginError = "password mismatch";
          break;
      }
      return Object.assign({}, state, { error: loginError });
    case Actions.LOGIN_SET_FIELD:
      var change: any = {
        error: ""
      };
      change[action.field] = action.value;
      return Object.assign({}, state, change);
  }
  return state;
};
