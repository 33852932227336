import * as GeneralActions from "../actions";
import { ServiceClient as UsersClient } from "../services/users";
import Router from "../apps/site/router";

export const logoutMiddleware = (store: any) => (next: any) => (
  action: any
) => {
  switch (action.type) {
    case GeneralActions.LOGOUT:
      UsersClient.defaultInst.logout(
        err => {
          Router.home();
          document.location.reload();
        },
        whatever => console.error("logout", whatever)
      );
      break;
  }
  return next(action);
};
