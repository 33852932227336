/* tslint:disable */
// hello commonjs - we need some imports - sorted in alphabetical order, by go package
import * as github_com_bestbytes_paperroll_server_billing from './../services/vo/billing'; // webpack/src/services-async/users.ts to webpack/src/services/vo/billing.ts
import * as github_com_bestbytes_paperroll_server_client from './vo/client'; // webpack/src/services-async/users.ts to webpack/src/services-async/vo/client.ts
import * as github_com_bestbytes_paperroll_server_relay from './vo/relay'; // webpack/src/services-async/users.ts to webpack/src/services-async/vo/relay.ts
import * as github_com_bestbytes_paperroll_server_services from './vo/services'; // webpack/src/services-async/users.ts to webpack/src/services-async/vo/services.ts
import * as github_com_bestbytes_paperroll_server_services_admin from './vo/admin'; // webpack/src/services-async/users.ts to webpack/src/services-async/vo/admin.ts
import * as github_com_bestbytes_paperroll_server_services_projects from './vo/projects'; // webpack/src/services-async/users.ts to webpack/src/services-async/vo/projects.ts
import * as github_com_bestbytes_paperroll_server_services_search from './vo/search'; // webpack/src/services-async/users.ts to webpack/src/services-async/vo/search.ts
import * as github_com_bestbytes_paperroll_server_services_subscriptions from './../services/vo/subscriptions'; // webpack/src/services-async/users.ts to webpack/src/services/vo/subscriptions.ts
import * as github_com_bestbytes_paperroll_server_services_teams from './vo/teams'; // webpack/src/services-async/users.ts to webpack/src/services-async/vo/teams.ts
import * as github_com_bestbytes_paperroll_server_services_users from './vo/users'; // webpack/src/services-async/users.ts to webpack/src/services-async/vo/users.ts
import * as github_com_stripe_stripe_go from './vo/stripe'; // webpack/src/services-async/users.ts to webpack/src/services-async/vo/stripe.ts

export class ServiceClient {
	public static defaultEndpoint = "/service/users";
	constructor(
		public transport:<T>(method: string, data?: any[]) => Promise<T>
	) {}
	async addPaymentSource(srcID:string, paymentType:string, fingerprint:string):Promise<void> {
		let response = await this.transport<{0:github_com_bestbytes_paperroll_server_services.ServiceError}>("AddPaymentSource", [srcID, paymentType, fingerprint])
		let err = response[0];
		if(err) { throw err }
	}
	async confirmationToken(tokenID:string):Promise<{email:string; password:string; loginQRLink:string; loginLink:string; }> {
		let response = await this.transport<{0:string; 1:string; 2:string; 3:string; 4:github_com_bestbytes_paperroll_server_services.ServiceError}>("ConfirmationToken", [tokenID])
		let err = response[4];
		if(err) { throw err }
		let responseObject = {email : response[0], password : response[1], loginQRLink : response[2], loginLink : response[3]};
		return responseObject;
	}
	async deletePaymentSource(sourceID:string):Promise<void> {
		let response = await this.transport<{0:github_com_bestbytes_paperroll_server_services.ServiceError}>("DeletePaymentSource", [sourceID])
		let err = response[0];
		if(err) { throw err }
	}
	async emailIsInUse(email:string):Promise<boolean> {
		let response = await this.transport<{0:boolean; 1:github_com_bestbytes_paperroll_server_services.ServiceError}>("EmailIsInUse", [email])
		let err = response[1];
		if(err) { throw err }
		return response[0]
	}
	async force():Promise<github_com_bestbytes_paperroll_server_services_users.Force> {
		return (await this.transport<{0:github_com_bestbytes_paperroll_server_services_users.Force}>("Force", []))[0]
	}
	async getAllUsers():Promise<github_com_bestbytes_paperroll_server_client.Info[]> {
		let response = await this.transport<{0:github_com_bestbytes_paperroll_server_client.Info[]; 1:github_com_bestbytes_paperroll_server_services.ServiceError}>("GetAllUsers", [])
		let err = response[1];
		if(err) { throw err }
		return response[0]
	}
	async getAvailablePaymentSources():Promise<github_com_bestbytes_paperroll_server_services_users.PaymentInfo[]> {
		let response = await this.transport<{0:github_com_bestbytes_paperroll_server_services_users.PaymentInfo[]; 1:github_com_bestbytes_paperroll_server_services.ServiceError}>("GetAvailablePaymentSources", [])
		let err = response[1];
		if(err) { throw err }
		return response[0]
	}
	async getLoginToken(uri:string):Promise<string> {
		let response = await this.transport<{0:string; 1:github_com_bestbytes_paperroll_server_services.ServiceError}>("GetLoginToken", [uri])
		let err = response[1];
		if(err) { throw err }
		return response[0]
	}
	async getMySketches():Promise<{summaries:github_com_bestbytes_paperroll_server_client.SketchSummary[]; projects:github_com_bestbytes_paperroll_server_client.Project[]; }> {
		let response = await this.transport<{0:github_com_bestbytes_paperroll_server_client.SketchSummary[]; 1:github_com_bestbytes_paperroll_server_client.Project[]; 2:github_com_bestbytes_paperroll_server_services.ServiceError}>("GetMySketches", [])
		let err = response[2];
		if(err) { throw err }
		let responseObject = {summaries : response[0], projects : response[1]};
		return responseObject;
	}
	async getProjectUsers(projectID:string):Promise<github_com_bestbytes_paperroll_server_client.Info[]> {
		let response = await this.transport<{0:github_com_bestbytes_paperroll_server_client.Info[]; 1:github_com_bestbytes_paperroll_server_services.ServiceError}>("GetProjectUsers", [projectID])
		let err = response[1];
		if(err) { throw err }
		return response[0]
	}
	async getSession():Promise<{user:github_com_bestbytes_paperroll_server_client.User; teams:github_com_bestbytes_paperroll_server_client.Team[]; projects:github_com_bestbytes_paperroll_server_client.Project[]; sessionID:string; env:string; }> {
		let response = await this.transport<{0:github_com_bestbytes_paperroll_server_client.User; 1:github_com_bestbytes_paperroll_server_client.Team[]; 2:github_com_bestbytes_paperroll_server_client.Project[]; 3:string; 4:string; 5:github_com_bestbytes_paperroll_server_services.ServiceError}>("GetSession", [])
		let err = response[5];
		if(err) { throw err }
		let responseObject = {user : response[0], teams : response[1], projects : response[2], sessionID : response[3], env : response[4]};
		return responseObject;
	}
	async getStripeUser():Promise<string> {
		let response = await this.transport<{0:string; 1:github_com_bestbytes_paperroll_server_services.ServiceError}>("GetStripeUser", [])
		let err = response[1];
		if(err) { throw err }
		return response[0]
	}
	async getTeamUsers(teamID:string):Promise<github_com_bestbytes_paperroll_server_client.Info[]> {
		let response = await this.transport<{0:github_com_bestbytes_paperroll_server_client.Info[]; 1:github_com_bestbytes_paperroll_server_services.ServiceError}>("GetTeamUsers", [teamID])
		let err = response[1];
		if(err) { throw err }
		return response[0]
	}
	async getUserAndSketches(userID:string, filters:github_com_bestbytes_paperroll_server_client.Filter[], page:number, pageSize:number):Promise<{user:github_com_bestbytes_paperroll_server_client.Info; listing:github_com_bestbytes_paperroll_server_client.SketchListing; }> {
		let response = await this.transport<{0:github_com_bestbytes_paperroll_server_client.Info; 1:github_com_bestbytes_paperroll_server_client.SketchListing; 2:github_com_bestbytes_paperroll_server_services.ServiceError}>("GetUserAndSketches", [userID, filters, page, pageSize])
		let err = response[2];
		if(err) { throw err }
		let responseObject = {user : response[0], listing : response[1]};
		return responseObject;
	}
	async invite(email:string, teamID:string, projectIDs:string[]):Promise<void> {
		let response = await this.transport<{0:github_com_bestbytes_paperroll_server_services.ServiceError}>("Invite", [email, teamID, projectIDs])
		let err = response[0];
		if(err) { throw err }
	}
	async inviteToken(tokenID:string):Promise<{email:string; password:string; userExists:boolean; }> {
		let response = await this.transport<{0:string; 1:string; 2:boolean; 3:github_com_bestbytes_paperroll_server_services.ServiceError}>("InviteToken", [tokenID])
		let err = response[3];
		if(err) { throw err }
		let responseObject = {email : response[0], password : response[1], userExists : response[2]};
		return responseObject;
	}
	async invited(email:string):Promise<boolean> {
		let response = await this.transport<{0:boolean; 1:github_com_bestbytes_paperroll_server_services.ServiceError}>("Invited", [email])
		let err = response[1];
		if(err) { throw err }
		return response[0]
	}
	async login(email:string, password:string):Promise<{user:github_com_bestbytes_paperroll_server_client.User; teams:github_com_bestbytes_paperroll_server_client.Team[]; projects:github_com_bestbytes_paperroll_server_client.Project[]; sessionID:string; env:string; }> {
		let response = await this.transport<{0:github_com_bestbytes_paperroll_server_client.User; 1:github_com_bestbytes_paperroll_server_client.Team[]; 2:github_com_bestbytes_paperroll_server_client.Project[]; 3:string; 4:string; 5:github_com_bestbytes_paperroll_server_services.ServiceError}>("Login", [email, password])
		let err = response[5];
		if(err) { throw err }
		let responseObject = {user : response[0], teams : response[1], projects : response[2], sessionID : response[3], env : response[4]};
		return responseObject;
	}
	async logout():Promise<void> {
		let response = await this.transport<{0:github_com_bestbytes_paperroll_server_services.ServiceError}>("Logout", [])
		let err = response[0];
		if(err) { throw err }
	}
	async promoteUserToProjectMember(userID:string, projectID:string):Promise<void> {
		let response = await this.transport<{0:github_com_bestbytes_paperroll_server_services.ServiceError}>("PromoteUserToProjectMember", [userID, projectID])
		let err = response[0];
		if(err) { throw err }
	}
	async promoteUserToProjectOwner(userID:string, projectID:string):Promise<void> {
		let response = await this.transport<{0:github_com_bestbytes_paperroll_server_services.ServiceError}>("PromoteUserToProjectOwner", [userID, projectID])
		let err = response[0];
		if(err) { throw err }
	}
	async registerv2(email:string, password:string):Promise<{user:github_com_bestbytes_paperroll_server_client.User; teams:github_com_bestbytes_paperroll_server_client.Team[]; projects:github_com_bestbytes_paperroll_server_client.Project[]; sessionID:string; env:string; }> {
		let response = await this.transport<{0:github_com_bestbytes_paperroll_server_client.User; 1:github_com_bestbytes_paperroll_server_client.Team[]; 2:github_com_bestbytes_paperroll_server_client.Project[]; 3:string; 4:string; 5:github_com_bestbytes_paperroll_server_services.ServiceError}>("Registerv2", [email, password])
		let err = response[5];
		if(err) { throw err }
		let responseObject = {user : response[0], teams : response[1], projects : response[2], sessionID : response[3], env : response[4]};
		return responseObject;
	}
	async resetPassword(email:string):Promise<void> {
		let response = await this.transport<{0:github_com_bestbytes_paperroll_server_services.ServiceError}>("ResetPassword", [email])
		let err = response[0];
		if(err) { throw err }
	}
	async resetToken(tokenID:string):Promise<{email:string; password:string; }> {
		let response = await this.transport<{0:string; 1:string; 2:github_com_bestbytes_paperroll_server_services.ServiceError}>("ResetToken", [tokenID])
		let err = response[2];
		if(err) { throw err }
		let responseObject = {email : response[0], password : response[1]};
		return responseObject;
	}
	async setPasswordAndLogin(email:string, password:string, newPassword:string):Promise<{user:github_com_bestbytes_paperroll_server_client.User; teams:github_com_bestbytes_paperroll_server_client.Team[]; projects:github_com_bestbytes_paperroll_server_client.Project[]; sessionID:string; env:string; }> {
		let response = await this.transport<{0:github_com_bestbytes_paperroll_server_client.User; 1:github_com_bestbytes_paperroll_server_client.Team[]; 2:github_com_bestbytes_paperroll_server_client.Project[]; 3:string; 4:string; 5:github_com_bestbytes_paperroll_server_services.ServiceError}>("SetPasswordAndLogin", [email, password, newPassword])
		let err = response[5];
		if(err) { throw err }
		let responseObject = {user : response[0], teams : response[1], projects : response[2], sessionID : response[3], env : response[4]};
		return responseObject;
	}
	async update(name:string, firstName:string, lastName:string, salutation:string, password:string, description:string, tags:string[]):Promise<void> {
		let response = await this.transport<{0:github_com_bestbytes_paperroll_server_services.ServiceError}>("Update", [name, firstName, lastName, salutation, password, description, tags])
		let err = response[0];
		if(err) { throw err }
	}
	async useLoginToken(tokenID:string):Promise<{ret:string; ret_1:string; }> {
		let response = await this.transport<{0:string; 1:string; 2:github_com_bestbytes_paperroll_server_services.ServiceError}>("UseLoginToken", [tokenID])
		let err = response[2];
		if(err) { throw err }
		let responseObject = {ret : response[0], ret_1 : response[1]};
		return responseObject;
	}
}