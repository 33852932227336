import * as React from "react";
import { DevStaticState } from "../reducers/devStatic";

export const DevStaticPage = (props: DevStaticState) => {
  let debug = [];
  for (let prop in props) {
    debug.push(<h2 key={"title-" + prop}>{prop}</h2>);
    debug.push(
      <div
        key={"content" + prop}
        dangerouslySetInnerHTML={{ __html: props[prop] }}
      />
    );
  }
  return (
    <div>
      <h1>Dev Static</h1>
      {debug}
    </div>
  );
};

DevStaticPage.displayName = "DevStaticPage";
