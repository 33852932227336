import * as React from "react";
import { Popup } from "semantic-ui-react";

import { styled, styleForSelectionMode, SelectionMode } from "../theme";

export const StyledUserCircle = styled.a<{size?: number;mode?: SelectionMode;}>`
  box-sizing: border-box;
  display: inline-block;
  border-radius: 100px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  /* padding: 15px; */
  height: ${props => props.size}px;
  width: ${props => props.size}px;
  text-align: center;
  font-size: ${props => props.size * 0.45}px;

  line-height: ${props => props.size - 2}px !important;
  color: white;

  :hover {
    color: white;
  }
  font-weight: 600;
  background-color: #ccc;

  ${styleForSelectionMode}
`;

export const StyledAddCircle = styled(StyledUserCircle)<{ size?: number }>`
  background-color: #efefef;
  color: #ccc;
`;

const UserCircle: React.FC<{
  name: string;
  id: string;
  size?: number;
  mode?: SelectionMode;
  className?: string;
  popupText?: (name: string) => React.ReactElement;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  noPopup?: boolean;
  href?: string;
}> = ({
  name,
  id,
  size,
  popupText,
  noPopup,
  mode,
  onClick,
  href,
  className
}) => {
  const Content = (
    <StyledUserCircle
      className={className}
      as={"span"}
      mode={mode}
      href={href}
      onClick={onClick}
      size={size ? size : 40}
      style={{ backgroundColor: "#" + intToRGB(hashCode(id)) }}
    >
      {name.substr(0, 2).toUpperCase()}
    </StyledUserCircle>
  );
  return noPopup ? (
    Content
  ) : (
    <Popup
      content={popupText ? popupText(name) : name}
      size={"mini"}
      trigger={Content}
    />
  );
};

export default UserCircle;

/**
 * Private utils
 */
const hashCode = (str: string): number => {
  let hash = 0;
  for (var i = 0; i < str.length; i++) {
    let part = (hash << 5) + hash;
    hash = str.charCodeAt(i) + part;
  }
  return hash;
};

const intToRGB = (i: number) => {
  const c = (i & 0x00ffffff).toString(16).toUpperCase();
  const color = "00000".substring(0, 6 - c.length) + c;
  return color;
};
