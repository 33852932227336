import * as React from "react";
import { styled, BreakpointMedia } from "../theme";

const StyledLogo = styled.div`
  position: relative;
  border: none;
  height: 100%;
  margin: 0 1rem 0 0;
  background: none !important;
  font-weight: normal !important;
  @media (min-width: 1024px) {
    margin: 0 2rem 0 0;
  }
  font-size: 2rem;
`;

const StyledLogoTag = styled.i`
  display: inline-block;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  transform: translateY(-4px);

  &:first-of-type {
    color: white;
    background-color: rgb(230, 230, 230);
    padding: 3px 6px;
    border-radius: 5px;
    display: none;
  }
  &:last-of-type {
    color: #ccc;
  }

  @media ${BreakpointMedia.tablet} {
    /* left: 8.2rem;
    top: 0.3rem; */
    margin-left: 0;
    &:first-of-type {
      display: inline-block;
    }
    &:last-of-type {
      display: none;
    }
  }
`;

const StyledTagContainer = styled.span`
  position: relative;
  padding-left: 5px;
`;

export const TeamSketchLogo: React.FC<{ className?: string }> = props => (
  <StyledLogo className={props.className} title="TeamSketch beta">
    <span>
      <strong>Team</strong>Sketch
    </span>
    <StyledTagContainer>
      <StyledLogoTag>BETA</StyledLogoTag>
      <StyledLogoTag>β</StyledLogoTag>
    </StyledTagContainer>
  </StyledLogo>
);

TeamSketchLogo.displayName = "TeamSketchLogo";
