import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Input } from "semantic-ui-react";

import { SketchListing } from "../../../services-async/vo/client";
import { styled } from "../../../theme";
import { State } from "../store";
import Router from "../router";

const StyledSearchInput = styled(Input)`
  width: 30vw;
  min-width: 280px;
  max-width: 600px;
  input {
    background: #eee !important;
    border: 0 !important;
    border-radius: 30px !important;

    border: 1px solid transparent !important;
    padding-top: 9px !important;
    padding-bottom: 9px !important;

    &:focus {
      background: #fff !important;
      border: 1px solid #eee !important;
      box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1) !important;
    }
  }
  i.icon {
    height: 2.4rem !important;
  }
`;

const SearchInput: React.FC<{
  searchListing?: SketchListing;
  className?: string;
}> = props => {
  const { t } = useTranslation();
  const [focus, setFocus] = React.useState(false);
  return (
    <StyledSearchInput
      className={props.className}
      icon="search"
      onKeyUp={e => {
        if (focus) {
          if (e.keyCode === 27) {
            // cancel shit
            console.log(
              "so, I implemented event handling to cancel search by hitting 'Escape', but what to do now ..."
            );
          }
        }
      }}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      onChange={e =>
        Router.search(
          e.currentTarget.value,
          props.searchListing ? props.searchListing.filters : []
        )
      }
      placeholder={t("SEARCH_PLACEHOLDER")}
    />
  );
};

const mapStateToProps = (state: State) => ({
  searchListing: state.search.result ? state.search.result.listing : null
});

export default connect(mapStateToProps)(SearchInput);
