import * as Actions from "../actions";

export interface DevStaticState {
  home: string;
  downloads: string;
}
export const devStatic = (
  state: DevStaticState | undefined,
  action: any
): DevStaticState => {
  if (state === undefined) {
    return {
      home: "not rendered",
      downloads: "not rendered"
    };
  }
  switch (action.type) {
    case Actions.DEV_STATIC:
      return Object.assign({}, action.content);
  }
  return state;
};
