import { Divider, Image } from "semantic-ui-react";
import * as styledComponents from "styled-components";

export const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider
} = styledComponents as styledComponents.ThemedStyledComponentsModule<
  typeof theme
>;

export const SectionDivider = styled(Divider)`
  padding-bottom: 100px;
`;

export const PageContainer = styled.div`
  max-width: 1920px;
  padding: 0.5rem 1rem;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1024px) {
    padding: 2rem 2rem;
  }
`;

export const Settings = styled.div`
  a {
    &:first-child {
      padding-left: 0 !important;
    }
    &:last-child {
      padding-right: 0 !important;
    }
  }

  @media (min-width: 768px) {
    float: right;
    /* right: 0;
    top: 0;
    position: absolute;
    padding-right: 1rem; */
  }
`;

export const ContentDivider = styled(Divider)`
  margin: 40px 0 30px !important;
`;

export const ContentText = styled.div`
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
`;

export const ContentDescription = styled.div`
  max-width: 700px;
  margin-bottom: 0.5rem;
`;

export const ContentImage = styled(Image)`
  min-width: 130%;
  float: ${props => (props.float ? props.float : "none")};
`;

export enum BreakpointName {
  mobileS,
  mobileM,
  mobileL,
  tablet,
  laptop,
  laptopM,
  laptopL,
  desktop,
  desktopM,
  desktopL
}

export const BreakpointSize = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptopM: 1280,
  laptopL: 1440,
  desktop: 1600,
  desktopM: 2560,
  desktopL: 3000
};

export const BreakpointMedia = {
  mobileS: `(min-width: ${BreakpointSize.mobileS}px)`,
  mobileM: `(min-width: ${BreakpointSize.mobileM}px)`,
  mobileL: `(min-width: ${BreakpointSize.mobileL}px)`,
  tablet: `(min-width: ${BreakpointSize.tablet}px)`,
  laptop: `(min-width: ${BreakpointSize.laptop}px)`,
  laptopM: `(min-width: ${BreakpointSize.laptopM}px)`,
  laptopL: `(min-width: ${BreakpointSize.laptopL}px)`,
  desktop: `(min-width: ${BreakpointSize.desktop}px)`,
  desktopM: `(min-width: ${BreakpointSize.desktopM}px)`,
  desktopL: `(min-width: ${BreakpointSize.desktopL}px)`
};

const borderColor = "rgba(0, 0, 0, .10)";
const borderWidth = "1px";
const border = borderWidth + " " + borderColor + " solid";
const fadeIn = (time = "0.5s") => `
	animation: fadeIn ${time};
	visibility: visible;
`;
const fadeOut = (time = "1s") => `
	animation: fadeOut ${time};
	visibility: hidden;
`;

const mobileZoom = 1.02;
const desktopZoom = 1.03;

export const HoverStyle = `
    transition: transform 0.22s ease-in;
    &:hover {
      transform: scale(${mobileZoom});
        @media (min-width: 768px) {
          transform: scale(${desktopZoom});
        }
    }    }

    &:active {
      @media (min-width: 768px) {
        transform: scale(0.989) !important;
      }
    }
`;

export const BoxStyle = (cornerRadius: string, interactive: boolean = true) => `
    overflow: hidden;
    border-radius: ${cornerRadius};
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.12);
    ${
      interactive
        ? `
    transition: transform 0.22s ease-in-out, box-shadow 0.25s ease-in-out;
    &:hover {
        box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.37);
        transform: scale(${mobileZoom});
        @media (min-width: 768px) {
          transform: scale(${desktopZoom});
        }
    }

    &:active {
      @media (min-width: 768px) {
        box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.12);
        transform: scale(0.989) !important;
        background-color: #FFF;
      }
    }
    `
        : ""
    }
`;

export const defaultContainerStyles = `
max-width: 1920px;
padding: 0 1rem;
margin-left: auto;
margin-right: auto;

@media (min-width: 1024px) {
  padding: 0 2rem;
}
`;

export enum SelectionMode {
  disabled,
  selected
}

export const styleForSelectionMode = (
  props: styledComponents.ThemedStyledProps<
    { mode?: SelectionMode; size: number },
    typeof theme
  >
) => {
  switch (props.mode) {
    case SelectionMode.disabled:
      return `
      background-color: #34495e11 !important;
      color: rgba(0, 0, 0, 0.1);
      cursor: not-allowed;
    `;
    case SelectionMode.selected:
      return `
      background-color: ${props.theme.colors.accent} !important;
      // color: ${props.theme.colors.accentDark} !important;
      border-color: ${props.theme.colors.accentDark} !important;
      border-width: 2px;
      line-height: ${props.size - 4}px !important;
      ${HoverStyle}
      `;
  }
  return HoverStyle;
};

export const theme = {
  borderColor,
  borderWidth,
  border,
  fadeIn,
  fadeOut,
  primaryColor: "#0FC6D6",
  treeStrokeColor: "#CCC",
  linkColor: "#0FC6D6",
  activeLinkColor: "#1d53d0",
  backgroundGrey: "#fafafa",
  accentColor: "#0FC6D6",
  bodyFontColor: "#162F31",
  bodyFontColorSecondary: "#808D8E",
  fontSize: "17px",
  tagColor: "#0FC6D6",
  tagColorDisabled: "#ABB9BA",
  backgroundGreyDisabled: "#fafafa",
  tagColorActive: "#0FC6D6",
  colors: {
    accent: "#0FC6D6",
    accentDark: "#2CA0B6",
    error: "#a22725",
    success: "#28944c"
  },
  transitionDuration: "0.4s",
  cornerRadius: "7px",
  transition: "cubic-bezier(0.445, 0.05, 0.55, 0.95)"
};
