import * as React from "react";
import { useTranslation } from "react-i18next";

import SketchImage, { ImageSize } from "../../../../components/SketchImage";
import { SketchSummary } from "../../../../services-async/vo/client";
import { BoxStyle, styled } from "../../../../theme";
import Router from "../../router";
import FixedAspectRatioBox from "../../../../components/FixedAspectRationBox";
import { StyledUserCircle } from "../../../../components/UserCircle";
import SiteUserCircle from "../UserCircle";

export enum SketchSelectionState {
  notSelected = 1,
  selected = 2
}

export const StyledSketch = styled.div<{selection?: undefined | SketchSelectionState;}>`
  display: inline-block;
  position: relative;
  cursor: pointer;

  img {
    border-radius: ${props => props.theme.cornerRadius};
    width: 100%;
    display: block;
  }

  .wrapper {
    margin: 0 !important;
    padding: 10px;
    transition: background-color 0.15s ${props => props.theme.transition};
    border-radius: ${props => props.theme.cornerRadius};
    ${props => {
      if (!props.selection) {
        return ``;
      }
      if (props.selection === SketchSelectionState.selected) {
        return `
          background-color: rgba(15, 198, 214, 0.9);`;
      }
    }}
  }

  h5 {
    /* fixes height when title undefined */
    min-height: 21px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 17px;
    padding-right: 30px;
    margin-top: 7px;
    margin-left: 5px;
  }

  ${StyledUserCircle} {
    display: block;
    position: absolute;
    right: 15px;
    bottom: 10px;
  }
`;

const ImageWrapper = styled(FixedAspectRatioBox)<{selection?: undefined | SketchSelectionState;}>`
  position: relative;
  ${props => {
    if (!props.selection) {
      return ``;
    }
    if (props.selection) {
      return `&:hover{
          transform: scale(1.0) !important;
      }`;
    }
  }}
  ${props => BoxStyle(props.theme.cornerRadius)}
`;
const onClick = (selection, onSelect, sketch) => e => {
  if (!selection) {
    if (e.ctrlKey || e.metaKey) {
      console.log("happy default", { ctrl: e.ctrlKey, meta: e.metaKey });
    } else {
      e.preventDefault();
      e.stopPropagation();
      Router.sketch(sketch.id);
      return false;
    }
  } else {
    e.preventDefault();
    e.stopPropagation();
    if (onSelect) {
      onSelect(sketch);
    }
    return false;
  }
};

export const SketchItem: React.FC<{
  sketch: SketchSummary;
  sizes: ImageSize[];
  selection?: SketchSelectionState;
  onSelect?: (sketch: SketchSummary) => void;
}> = ({ sketch, selection, onSelect, sizes }) => {
  const { t } = useTranslation();
  const popupTextRender = (name: string) => (
    <>
      {t("CREATED_BY")} <b>{name}</b>
    </>
  );
  const onClickHandler = onClick(selection, onSelect, sketch); 
  return (
    <StyledSketch
        selection={selection}
        onClick={onClickHandler}
        className={"grid-item"}
      >
        <div className="wrapper">
          <ImageWrapper
            ratio={sketch.size.width + ":" + sketch.size.height}
            selection={selection}
          >
            <SketchImage src={sketch.image} sizes={sizes} defaultSize={400} />
          </ImageWrapper>
          <h5>
            {sketch.name ? sketch.name : " "}
            <SiteUserCircle
              size={25}
              id={sketch.authorId}
              name={sketch.authorName}
              popupText={popupTextRender}
            />
          </h5>
        </div>
      </StyledSketch>
  );
};
