import { actionCreator } from "../apps/site/store";

/**
 * Variables & Constants
 */
let timeout = -1;

/**
 * Actions
 */
export const TOASTER_SERVER_ERROR = "TOASTER_SERVER_ERROR";
export const TOASTER_ADD_MSG = "TOASTER_ADD_MSG";
export const TOASTER_DISMISS_ALL = "TOASTER_DISMISS_ALL";
export const TOASTER_DISMISS = "TOASTER_DISMISS";

export enum ToastLevel {
  Info = "info",
  Error = "error"
}

/**
 * Types
 */
export interface Message {
  title?: string;
  message?: string;
  level: ToastLevel;
  // localize is set to true if message and title are i18next names
  localize?: boolean;
  id: number;
}

export interface ToasterState {
  messages: Message[];
}

/**
 * Action creators
 */

export const actionDismissAllToasts = () => actionCreator(TOASTER_DISMISS_ALL);

export const actionDismissToast = (toastId: number) =>
  actionCreator(TOASTER_DISMISS, toastId);

export const actionAddToast = (
  title: string,
  message: string,
  level: ToastLevel,
  localize?: boolean,
  id: number = new Date().getTime()
) => actionCreator(TOASTER_ADD_MSG, { title, message, level, localize, id });

export const actionAddErrorToast = (
  title: string,
  message: string,
  localize?: boolean
) => actionAddToast(title, message, ToastLevel.Error, localize);

export const actionAddInfoToast = (
  title: string,
  message: string,
  localize?: boolean
) => actionAddToast(title, message, ToastLevel.Info, localize);

type ActionType = ReturnType<
  | typeof actionAddToast
  | typeof actionDismissAllToasts
  | typeof actionDismissToast
>;

/**
 * toaster reducer
 * @param state Toaster State
 * @param action toaster actions as defined by ActionType
 */
const toastReducer = (
  state: ToasterState = {
    messages: []
  },
  action: ActionType
): ToasterState => {
  switch (action.type) {
    case TOASTER_DISMISS:
      return {
        ...state,
        messages: state.messages.filter(t => t.id !== action.payload)
      };
    case TOASTER_DISMISS_ALL:
      return { ...state, messages: [] };
    case TOASTER_ADD_MSG:
      return { ...state, messages: [action.payload, ...state.messages] };
  }
  return state;
};

export default toastReducer;

/**
 * Utils
 */

/**
 * when passed in a dispatch return a toaster instance that can dispatch an info or an error toast
 * WARNING: has side effects since local timeout let is changed
 * @param dispatch regular Redux Dispatch
 */
export const toaster = dispatch => ({
  info: (title: string, message: string = "") => {
    const toast = actionAddInfoToast(title, message);
    dispatch(toast);
    clearTimeout(timeout);
    setTimeout(() => {
      dispatch(actionDismissToast(toast.payload.id));
    }, 8000);
  },
  error: (title: string, message: string = "") =>
    dispatch(actionAddErrorToast(title, message))
});
