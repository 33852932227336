import * as React from "react";
import { ContentText, PageContainer } from "../../../theme";
import { Grid, Header } from "semantic-ui-react";
import { Section } from "../../../components/section";

export const ImprintPage = (props: {}) => {
  return (
    <Section>
      <PageContainer>
        <Grid>
          <Grid.Row>
            <Grid.Column textAlign="center">
              <ContentText>
                <Header as="h1" content="Imprint & Contact" />
              </ContentText>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <ContentText>
                <p>
                  Bavaria is famous for its outstanding cars, amazing soccer
                  team and delicious beer. At Bavarianapps we assume the same
                  for our apps. They are outstanding, amazing and delicious.
                  Come visit us and we will love to show you our work. And after
                  that we can have some freshly tapped beer together, watch
                  football and talk about cars.
                </p>
                <p>
                  <strong>Bavarianapps UG</strong> (haftungsbeschränkt)
                  <br />
                  Pienzenauerstr. 10
                  <br />
                  81679 Munich
                  <br />
                  Germany
                  <br />
                  <br />
                  Drop us a line via email:
                  <a href="mailto:support@bavarianapps.com">
                    support@bavarianapps.com
                  </a>
                  <br />
                  Or call us: +49 (0)89 307 62 399
                  <br />
                  <br />
                  Vertreten durch: Sven Böttcher, Hans Halfar
                  <br />
                  Registergericht: Amtsgericht München
                  <br />
                  Sitz der Gesellschaft: München
                  <br />
                  Registernummer: HRB 203535
                  <br />
                  Umsatzsteuer-Identifikationsnummer gemäß §27 a
                  Umsatzsteuergesetz: DE288619818
                </p>
              </ContentText>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </PageContainer>
    </Section>
  );
};

ImprintPage.displayName = "ImprintPage";
