import { ENV_PRICING_SHOW } from "../../site/actions";

const defaultState = () => ({
  pricing: {
    show: false
  }
});

export type EnvState = ReturnType<typeof defaultState>

export const env = (state: EnvState = defaultState(), action):EnvState => {
  switch (action.type) {
    case ENV_PRICING_SHOW:
      return { ...state, pricing: { ...state.pricing, show: action.show } };
  }
  return state;
};
