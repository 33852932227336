import * as GeneralActions from "../../../actions";
import {
  AudioInfo,
  Info as UserInfo,
  MemoryUsage,
  Project,
  SketchMeta,
  SketchSize,
  SketchSummary
} from "../../../services/vo/client";
import * as Actions from "../actions";
import { SketchTreeNode } from "../../../services-async/vo/client";

export interface SketchState {
  playbackSessionID: number;
  id: string | null;
  fullscreen: boolean;
  tags: string[];
  editable: boolean;
  tree?:SketchTreeNode;
  dirty: boolean;
  name: string;
  description: string;
  originalName: string;
  originalDescription: string;
  originalTags: string[];
  project?: Project;
  sketch?: SketchSummary;
  playing: boolean;
  progress: number;
  sketchMeta?: SketchMeta;
  speed: number;
  isPublic: boolean;
  authorId?: string | null;
  authorName?: string | null;
  renderWidth: number;
  defaultRenderWidth: number;
  timestamp: number;
  size?: SketchSize;
  // image?: string | null;
  backgroundImage?: string | null;
  memoryUsage?: MemoryUsage;
  audioInfos: AudioInfo[];
  listingContext: {
    user?: UserInfo;
    project?: Project;
    summaries: SketchSummary[];
  };
}

export const initialState: SketchState = {
  audioInfos: [],
  authorId: null,
  authorName: null,
  defaultRenderWidth: 0,
  description: "...",
  dirty: false,
  editable: false,
  fullscreen: false,
  id: null,
  isPublic: false,
  listingContext: { summaries: [] },
  memoryUsage: null,
  name: "...",
  originalDescription: "...",
  originalName: "...",
  originalTags: [],
  playbackSessionID: 0,
  playing: false,
  progress: 0,
  renderWidth: 0,
  speed: 1.0,
  tags: [],
  timestamp: 0
};

type SketchAction = ReturnType<
  | typeof Actions.setSketchFullscreen
  | typeof Actions.loadProject
  | typeof Actions.loadSketchesForTag
  | typeof Actions.loadUser
>;

export const sketch = (
  state: SketchState = initialState,
  action: SketchAction
): SketchState => {
  switch (action.type) {
    case Actions.SET_SKETCH_FULLSCREEN:
      return { ...state, fullscreen: action.fullscreen };
    case Actions.LOAD_PROJECT:
    case Actions.LOAD_SKETCHES_FOR_TAG:
      return {
        ...state,
        listingContext: { summaries: action.listing.summaries }
      };
    case Actions.LOAD_SKETCH_TREE:
      return {...state, tree: action.tree};
    case Actions.LOAD_USER:
      return {
        ...state,
        listingContext: { summaries: action.payload.listing.summaries }
      };
    case Actions.LOAD_PROJECT_NEXT_PAGE:
    case Actions.LOAD_SKETCHES_FOR_TAG_NEXT_PAGE:
      return {
        ...state,
        listingContext: {
          summaries: state.listingContext.summaries.concat(
            action.listing.summaries
          )
        }
      };
    case Actions.LOAD_USER_NEXT_PAGE:
      return {
        ...state,
        listingContext: {
          summaries: state.listingContext.summaries.concat(
            action.payload.listing.summaries
          ),
        }
      };
    case GeneralActions.SET_PAGE:
      return {
        ...state,
        fullscreen: false,
        playing: false,
        progress: 0,
        sketch: null,
        speed: 1.0
      };
    case Actions.REPLAY_SKETCH_COMPLETE:
      return { ...state, playing: false };
    case Actions.REPLAY_SKETCH:
      return {
        ...state,
        playbackSessionID: state.playbackSessionID + 1,
        playing: true,
        progress: 0,
        speed: action.speed
      };
    case Actions.SET_SKETCH_DEFAULT_RENDER_WIDTH:
      return {
        ...state,
        defaultRenderWidth: action.defaultRenderWidth,
        renderWidth: action.defaultRenderWidth
      };
    case Actions.SET_SKETCH_RENDER_WIDTH:
      return {
        ...state,
        playbackSessionID: state.playbackSessionID + 1,
        playing: true,
        progress: 0,
        renderWidth: action.renderWidth
      };
    case Actions.PLAY_SKETCH:
      return { ...state, progress: action.progress, speed: action.speed };
    case Actions.TOGGLE_PLAY_SKETCH:
      return { ...state, playing: !state.playing };
    case Actions.EDIT_SKETCH:
      const change = action.change;
      const newState = { ...state, ...change };
      return {
        ...newState,
        dirty:
          newState.name !== newState.originalName ||
          newState.description !== newState.originalDescription ||
          newState.tags !== newState.originalTags
      };
    case Actions.LOAD_SKETCH_PUBLIC:
      return {...state, isPublic: action.sketchPublic};
    case Actions.LOAD_SKETCH_SUMMARY:
      return {
        ...state,
        authorId: action.summary.authorId,
        authorName: action.summary.authorName,
        backgroundImage: action.summary.backgroundImage,
        description: action.summary.description,
        dirty: false,
        editable: action.editable,
        id: action.summary.id,
        // image: action.summary.image,
        isPublic: action.summary.isPublic,
        memoryUsage: action.summary.memoryUsage,
        name: action.summary.name,
        originalDescription: action.summary.description,
        originalName: action.summary.name,
        originalTags: action.summary.tags,
        playing: false,
        project: action.project,
        size: action.summary.size,
        tags: action.summary.tags,
        timestamp: action.summary.timestamp
      };
    case Actions.CANCEL_SKETCH_EDIT:
      return {
        ...state,
        description: state.originalDescription,
        dirty: false,
        name: state.originalName,
        tags: state.originalTags
      };
    case Actions.LOAD_SKETCH:
      const s: SketchSummary = action.sketch;
      return {
        ...state,
        ...{
          audioInfos: s.audioInfos,
          authorId: s.authorId,
          authorName: s.authorName,
          description: s.description || "",
          dirty: false,
          editable: action.editable,
          id: s.id,
          isPublic: s.isPublic,
          memoryUsage: s.memoryUsage,
          name: s.name,
          originalDescription: s.description,
          originalName: s.name,
          originalTags: s.tags,
          playbackSessionID: state.playbackSessionID + 1,
          playing: false,
          progress: 0,
          project: action.project,
          renderWidth: 0,
          size: s.size,
          sketch: s,
          sketchMeta: action.sketchMeta,
          tags: s.tags,
          timestamp: s.timestamp
        }
      };
  }
  return state;
};
