import * as React from "react";

import { PaymentInfo } from "../../../../services-async/vo/users";
import {
  deletePaymentSource,
  getPaymentSources
} from "../../actions/subscriptions";
import { BAD_WAY_TO_GET_REDUX_DISPATCH } from "../../store";

export type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;
export type Subtract<T, K> = Omit<T, keyof K>;
export interface PaymentSourceViewInterface {
  sources: PaymentInfo[] | null | undefined;
  onDelete: (sourceId: string) => any;
  onSelect?: { (info: PaymentInfo): void };
  // artist?: ArtistState;
}

export const withStripeSources = <P extends PaymentSourceViewInterface>(
  WrappedComponent: React.ComponentType<PaymentSourceViewInterface>
): React.FC<
  Subtract<P, PaymentSourceViewInterface> & {
    onSelect?: { (info: PaymentInfo): void };
  }
> => props => {
  const dispatch = BAD_WAY_TO_GET_REDUX_DISPATCH();
  const [sources, setSources] = React.useState<
    PaymentInfo[] | null | undefined
  >(undefined);
  React.useEffect(() => {
    (dispatch(getPaymentSources()) as any).then(infos => setSources(infos));
  }, [dispatch]);
  const onDelete = (sourceId: string) =>
    dispatch(deletePaymentSource(sourceId));
  return <WrappedComponent {...props} onDelete={onDelete} sources={sources} />;
};
