import * as React from "react";

import { Filter } from "../../../services/vo/client";
import * as Actions from "../actions";
import { FilteredSketchList } from "../components/sketchList/filteredSketchList";
import { TeamsState } from "../reducers/teams";
import { UserState } from "../reducers/user";
import Router from "../router";
import { SKETCH_DELETION_CONTEXT } from "../middleware/sketch";
import { State } from "../store";
import { connect } from "react-redux";
import { PageContainer } from "../../../theme";

const UserSketchesPage = (props: {
  globalFilters: boolean;
  userState: UserState;
  teamsState: TeamsState;
}) => {
  return (
    <PageContainer>
      <FilteredSketchList
        label={props.userState.userInfo.name}
        tag="User"
        list={{
          teamsState: props.teamsState,
          selection: props.userState.selection,
          filter: (filters: Filter[]) => {
            Router.user(props.userState.userInfo.id, filters);
          },
          listing: props.userState.listing,
          deletionContext: SKETCH_DELETION_CONTEXT,
          globalFilters: props.globalFilters,
          nextPageLoader: Actions.getUserNextPage,
          emptyListHint: (
            <div>
              {props.userState.userInfo.name} has not published any sketches yet
            </div>
          )
        }}
      />
    </PageContainer>
  );
};

const mapStateToProps = (state: State) => ({
  globalFilters: state.client.globalFilters,
  userState: state.user,
  teamsState: state.teams
});

export default connect(mapStateToProps)(UserSketchesPage);

UserSketchesPage.displayName = "UserPage";
