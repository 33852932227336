import * as React from "react";
import { Header } from "semantic-ui-react";

import { Link } from "../../../../components/link";
import { LinkList } from "../../../../components/linkList";
import { Spacer, SpacerSize } from "../../../../components/spacer";
import { Filter, SketchListing } from "../../../../services/vo/client";
import { ContentDescription, Settings } from "../../../../theme";
import { SketchSelection } from "../../reducers/sketchSelection";
import { TeamsState } from "../../reducers/teams";
import { breakpoints } from "../../site";
import SketchMover from "../sketchMover";
import SketchMasonryGrid from "../SketchGrid/SketchGrid";
import SketchInifinityScroller from "./SketchInfinityScroller";
import { SketchListingFilters } from "./sketchListingFilters";
import { StyledNakedTag } from "../Tag";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { actionSetSelectionEnabled } from "../../reducers/user";
import {
  actionToggleGlobalFilters
} from "../../reducers/client";

export const numberOfSketchesPerPage = () => {
  switch (true) {
    case window.innerWidth <= breakpoints.mobile:
      return 12;
    case window.innerWidth <= breakpoints.tablet:
      return 24;
    case window.innerWidth <= breakpoints.desktop:
      return 36;
    case window.innerWidth <= breakpoints.desktopLarge:
      return 48;
    default:
      return 64;
  }
};

export interface Props {
  listing: SketchListing;
  filter: (filters: Filter[]) => void;
  selection: SketchSelection;
  deletionContext: string;
  teamsState: TeamsState;
  globalFilters: boolean;
  loading?: boolean;
  nextPageLoader: () => void;
  emptyListHint?: string | JSX.Element;
}

const SelectionFilterTabMenu = connect()((props => {
  const atLeastOneFilterHasMoreThanOneOption =
    props.listing &&
    props.listing.filters.findIndex(f => f.options.length > 1) !== -1;
  const hasFilters =
    props.listing &&
    props.listing.summaries.length > 0 &&
    atLeastOneFilterHasMoreThanOneOption;

  return (
    <LinkList>
      <Link
        key="select"
        onClick={() =>
          props.dispatch(
            actionSetSelectionEnabled(
              props.selection.id,
              !props.selection.enabled
            )
          )
        }
        active={props.selection.enabled}
        disabled={!props.listing || props.listing.summaries.length === 0}
      >
        Select
      </Link>
      <Link
        key="toggle"
        onClick={e => props.dispatch(actionToggleGlobalFilters())}
        active={props.globalFilters}
        disabled={!hasFilters}
      >
        Filter
      </Link>
    </LinkList>
  );
}) as React.FC<Props & { dispatch: ThunkDispatch<{}, {}, any> }>);

export const FilteredSketchList: React.FC<{
  list: Props;
  label: string | JSX.Element;
  tag?: string;
  menuItems?: Array<{ label: string; handler: (e: any) => void }>;
}> = props => {
  const menuItems = props.menuItems ? props.menuItems : [];
  return (
    <>
      <Header
        as="h2"
        content={
          <>
            <>{props.label}</>
            <StyledNakedTag>{props.tag}</StyledNakedTag>
          </>
        }
      />
      {props.children && (
        <ContentDescription>{props.children}</ContentDescription>
      )}
      <Settings>
        {menuItems
          .map((item, index) => {
            return (
              <Link
                key={"item-" + item.label + "-" + index}
                onClick={item.handler}
              >
                {item.label}
              </Link>
            );
          })
          .reverse()}
      </Settings>
      <Spacer size={SpacerSize.tiny} />
      <SelectionFilterTabMenu {...props.list} />
      <Spacer size={SpacerSize.tiny} />
      <FilteredSketchListContents {...props.list} />
    </>
  );
};

FilteredSketchList.displayName = "FilteredSketchList";

const FilteredSketchListContents = (props: Props) => {
  return (
    <div>
      {/* Sketch filter UI */}
      {props.listing && props.listing.filters && (
        <SketchListingFilters
          filters={props.listing.filters}
          filter={props.filter}
          showFilters={props.globalFilters}
        />
      )}
      {/* Sketch move UI */}
      {props.listing && props.selection.enabled && (
        <SketchMover
          selection={props.selection}
          deleteEnabled={true}
          context={props.deletionContext}
        />
      )}
      {props.nextPageLoader ? (
        <SketchInifinityScroller
          listing={props.listing ? props.listing : null}
          selection={props.selection}
          nextPageLoader={props.nextPageLoader}
          emptyHint={props.emptyListHint}
        />
      ) : (
        <SketchMasonryGrid
          selection={props.selection}
          sketches={props.listing ? props.listing.summaries : []}
        />
      )}
    </div>
  );
};

FilteredSketchListContents.displayName = "FilteredSketchListContents";
