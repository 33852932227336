import * as React from "react";
import { BoxContainer } from "./BoxContainer";
import { styled, BreakpointMedia } from "../../../theme";

const StyledCenterBoxBackground = styled.img`
  position: absolute;
  display: none;
  @media ${BreakpointMedia.tablet} {
    display: block;
    width: 100vw;
    top: 20vh;
    right: -40vw;
  }
`;

export const CenterBoxPage: React.FC<{ img?: string }> = props => (
  <>
    {props.img && <StyledCenterBoxBackground src={props.img} />}
    <BoxContainer>{props.children}</BoxContainer>
  </>
);
