import axios, { AxiosResponse } from 'axios';

// axios transport
const getTransport = endpoint => async <T>(method, args = []) => {
  return new Promise<T>(async (resolve, reject) => {
    try {
      let axiosPromise: AxiosResponse<T> = await axios.post<T>(
        endpoint + '/' + encodeURIComponent(method),
        JSON.stringify(args),
      );
      return resolve(axiosPromise.data);
    } catch (e) {
      return reject(e);
    }
  });
};

export interface ServiceConstructor<ST> extends Function {
  defaultEndpoint: string;
  prototype: ST;
  new (transport: <T>(method: string, data?: any[]) => Promise<T>): ST;
}

export const getClient = <T>(clientClass: ServiceConstructor<T>) => {
  return new clientClass(getTransport(clientClass.defaultEndpoint));
};
