import * as React from "react";
import Router from "../router";
import { State } from "../store";
import SketchMover from "../components/sketchMover";
import { Divider, Grid, Header } from "semantic-ui-react";
import { Section } from "../../../components/section";
import { Settings, PageContainer, styled } from "../../../theme";
import { Link } from "../../../components/link";
import SketchMasonryGrid from "../components/SketchGrid/SketchGrid";

const StyledGridRow = styled(Grid.Row)`
  padding: 0 !important;
`;

export const TrashPage = (props: { state: State }) => {
  let trashState = props.state.trash;

  return (
    <Section background>
      <PageContainer>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header
                as="h2"
                content={
                  "Trash " +
                  (trashState.context.project
                    ? "for project " + trashState.context.project.name
                    : "for all Projects")
                }
              />
              <p>Restore sketches from trash</p>
              {trashState.context.project != null ? (
                <Settings>
                  <Link onClick={e => Router.trash()}>
                    Trash for all projects
                  </Link>
                </Settings>
              ) : (
                undefined
              )}
            </Grid.Column>
          </Grid.Row>
          <StyledGridRow>
            <Grid.Column>
              <Divider />
            </Grid.Column>
          </StyledGridRow>
          <Grid.Row>
            <Grid.Column>
              <SketchMover
                selection={props.state.trash.selection}
                deleteEnabled={false}
              />
              <SketchMasonryGrid
                sketches={trashState.summaries ? trashState.summaries : []}
                selection={trashState.selection}
                emptyHint={
                  <>
                    {
                      "Trash is empty, if you throw stomething away, it will land here."
                    }
                  </>
                }
              ></SketchMasonryGrid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </PageContainer>
    </Section>
  );
};
