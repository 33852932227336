import * as React from "react";
import { connect } from "react-redux";
import { Menu } from "semantic-ui-react";

import { Info, Project, Team } from "../../../../services-async/vo/client";
import {
  BreakpointSize,
  defaultContainerStyles,
  styled,
  theme
} from "../../../../theme";
import { scrollToTop, isTouchDevice } from "../../../../utils";
import Router from "../../router";
import { State } from "../../store";
import { MenuContentItem } from "./components";
import LoggedInMenuBar from "./LoggedInMenuBar";
import LoggedOutMenuBar from "./LoggedOutMenuBar";
import TopBarMenuLeft from "./MainMenuDesktop";
import { QuickMenu } from "./QuickMenu";
import { ThunkDispatch } from "redux-thunk";

export const DefaultPadding = `
padding-left: 1rem;
padding-right: 1rem;

@media (min-width: 1024px) {
  padding-left: 2rem;
  padding-right: 2rem;
}
`;

const StyledMenuBar = styled(Menu)`
  ${defaultContainerStyles};
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 4px !important;
  margin-bottom: 4px !important;
`;

const StyledNav = styled.nav`
  top: 0;
  left: 0;
  right: 0;
  border-bottom: ${theme.borderWidth} ${theme.borderColor} solid;
  position: fixed;
  z-index: 1000;

  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  background-color: rgba(255, 255, 255, 0.90);

  @media print {
    display: none;
  }
`;

const StyledNavContents = styled.nav`
  position: fixed;
  left: 0;
  right: 0;
  /* top: 83.56px; */
  top: 3.8rem;

  /* extend dropdown to the bottom for mobile devices */
  @media screen and (max-width: ${BreakpointSize.tablet}px) {
    top: 61px;
    bottom: 0;
    overflow-y: auto;
  }
  z-index: 999;
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(10px);
  background-color: #fcfcfcee;
  border-bottom: ${theme.border};
`;

// this container provides left and right spacing for navigation elements
const StyledNavContentsWrapper = styled.div`
  ${defaultContainerStyles}
`;

StyledNavContents.displayName = "StyledNavContents";

export enum MenuTab {
  teamsMenu,
  quickMenu
}

const iOSFixedPositionScrollFix = (e: any) => e.preventDefault();

const setBodyScroll = (enable: boolean) => {
  if (enable) {
    document.documentElement.style.overflow = "";
    document.body.style.overflow = "";
    document.body.removeEventListener(
      "touchmove",
      iOSFixedPositionScrollFix,
      false
    );
  } else {
    document.documentElement.style.overflow = "hidden";
    document.body.style.overflow = "hidden";
    document.body.addEventListener(
      "touchmove",
      iOSFixedPositionScrollFix,
      false
    );
  }
};

const StyledRightMenu = styled(Menu.Menu)`
  .item:last-child {
    padding-right: 0 !important;
  }
`;

const mapStateToProps = (state: State) => ({
  state,
  currentPage: state.page.name,
  isLoggedIn: !!state.user.session,
  teams: state.teams.teams,
  projects: state.teams.projects,
  allUsers: state.allUsers,
  userName: state.user.session && state.user.session.user.name,
  userId: state.user.session && state.user.session.user.id,
  pricing: state.env.pricing.show,
});

interface SiteMenuProps extends ReturnType<typeof mapStateToProps> {
  dispatch: ThunkDispatch<{}, {}, any>;
}


const SiteMenu: React.FC<SiteMenuProps> = props => {
  const [menuTab, setMenuTab] = React.useState<MenuTab | undefined>(undefined);
  const [menuItems, setMenuItems] = React.useState<
    MenuContentItem[] | undefined
  >(getTeamsMenuItems(props.teams, props.allUsers, props.projects));
  // toggle document scrolling when menu is fullscreen but only for quick menu
  React.useEffect(() => {
    // disable scrolling only for quick menu
    setBodyScroll(menuTab !== MenuTab.quickMenu);
    // provide react with a reset if component gets unmounted
    return () => setBodyScroll(true);
  }, [menuTab]);

  // if projects, teams or users change rerender menuItems
  React.useEffect(() => {
    setMenuItems(
      getTeamsMenuItems(props.teams, props.allUsers, props.projects)
    );
  }, [props.teams, props.allUsers, props.projects]);

  const close = () => {
    setMenuTab(undefined);
    scrollToTop();
  };

  const onHideList = () =>
    window.innerWidth > BreakpointSize.tablet && setMenuTab(undefined);

  const isMenuOpen = menuTab !== undefined;
  return (
    <React.Fragment>
      <StyledNav>
        <StyledMenuBar text id="menu-">
          <TopBarMenuLeft
            page={props.currentPage}
            menuTab={menuTab}
            pricing={props.pricing}
            onLogoClick={() => {
              Router.home();
              close();
            }}
            onLogoHover={() => {
              if (isTouchDevice()) {
                Router.home()
                return;
              }
              if (props.isLoggedIn) {
                setMenuTab(MenuTab.teamsMenu);
              }
            }}
            loggedIn={props.isLoggedIn}
          />

          {/* Right floated topbar menu */}
          <StyledRightMenu position="right">
            {props.isLoggedIn ? (
              <LoggedInMenuBar
                onClose={close}
                currentPage={props.currentPage}
                onHideList={onHideList}
                isQuickMenu={menuTab === MenuTab.quickMenu}
                userName={props.userName}
                userId={props.userId}
                onToggleQuickMenu={() =>
                  menuTab === MenuTab.quickMenu
                    ? setMenuTab(undefined)
                    : setMenuTab(MenuTab.quickMenu)
                }
              />
            ) : (
              <LoggedOutMenuBar
                currentPage={props.currentPage}
                onHideList={onHideList}
              />
            )}
          </StyledRightMenu>
        </StyledMenuBar>
      </StyledNav>
      {isMenuOpen && (
        <StyledNavContents onMouseLeave={onHideList}>
          <StyledNavContentsWrapper>
            {(menuTab === MenuTab.teamsMenu ||
              menuTab === MenuTab.quickMenu) && (
              <QuickMenu
                isLoggedIn={props.isLoggedIn}
                page={props.currentPage}
                items={menuItems}
                closeMenu={close}
                dispatch={props.dispatch}
                pricing={props.pricing}
              />
            )}
          </StyledNavContentsWrapper>
        </StyledNavContents>
      )}
    </React.Fragment>
  );
};

SiteMenu.displayName = "SiteMenu";

export default connect(mapStateToProps)(SiteMenu);

const getTeamsMenuItems = (
  teams: Team[],
  allUsers: Info[],
  projects: Project[]
): MenuContentItem[] | undefined => {
  const projectMap: Map<string, { name: string; id: string }[]> = new Map();
  // const userMap: Map<string, FilterOption> = new Map();
  projects.forEach(p => {
    let item = projectMap.get(p.teamId);
    if (!item) {
      item = [];
    }
    item.push({ id: p.id, name: p.name });
    projectMap.set(p.teamId, item);
  });

  // TODO: find a way around all users
  return teams.map(team => ({
    name: team.name,
    id: team.id,
    projects: projectMap.get(team.id),
    users: allUsers
      .filter(u => team.userIds.indexOf(u.id) > -1)
      .map(u => ({
        value: u.id,
        name: u.name,
        selected: false,
        enabled: true
      }))
  }));
};
