import { Dispatch, Store } from "redux";

import { ServiceClient as ProjectsClient } from "../../../services/projects";
import { ServiceClient as UsersClient } from "../../../services/users";
import * as Actions from "../actions";
import * as Middleware from "../middleware";
import { State } from "../store";
import { toaster } from "../../../reducers/toast";

const getProjectUsers = (dispatch: Dispatch, projectId: string) => {
  UsersClient.defaultInst.getProjectUsers(
    projectId,
    (infos, err) => {
      if (err === null) {
        Actions.loadProjectUserInfos(infos);
      } else {
        console.error(err);
      }
    },
    Middleware.handleErr(dispatch)
  );
};

export const editProjectMiddleware = (store: Store<State>) => (next: any) => (
  action: any
) => {
  const { info, error } = toaster(store.dispatch);
  switch (action.type) {
    case Actions.EDIT_PROJECT_MAKE_USER_MEMBER:
      UsersClient.defaultInst.promoteUserToProjectMember(
        action.userId,
        action.projectId,
        err => {
          if (err) {
            error("could not make user a normal member", err.msg);
          } else {
            info("user is a normal project member now");
          }
          getProjectUsers(store.dispatch, action.projectId);
        },
        Middleware.handleErr(store.dispatch)
      );
      break;
    case Actions.EDIT_PROJECT_MAKE_USER_OWNER:
      UsersClient.defaultInst.promoteUserToProjectOwner(
        action.userId,
        action.projectId,
        err => {
          if (err) {
            error("could not make user project owner", err.msg);
          } else {
            info("user is a project owner now");
          }
          getProjectUsers(store.dispatch, action.projectId);
        },
        Middleware.handleErr(store.dispatch)
      );
      break;
    case Actions.EDIT_PROJECT_ADD_USER:
      UsersClient.defaultInst.invite(
        action.email,
        action.teamId,
        [action.projectId],
        err => {
          if (!err) {
            info("user was added");
          } else {
            error("could not add user", err.msg);
          }
          getProjectUsers(store.dispatch, action.projectId);
        },
        Middleware.handleErr(store.dispatch)
      );
      break;
    case Actions.EDIT_PROJECT_RM_USER:
      ProjectsClient.defaultInst.removeUserFromProject(
        action.userId,
        action.projectId,
        err => {
          if (err) {
            error("could not remove user", err.msg);
          } else {
            info("user was removed");
          }
          getProjectUsers(store.dispatch, action.projectId);
        },
        Middleware.handleErr(store.dispatch)
      );
      break;
    case Actions.EDIT_PROJECT:
      if (action.project) {
        getProjectUsers(store.dispatch, action.project.id);
      }
      break;
  }
  return next(action);
};
