import { Team, User } from "../../../services-async/vo/client";
import {
  Info as UserInfo,
  Project,
  SketchListing
} from "../../../services/vo/client";
import * as Actions from "../actions";
import { actionCreator } from "../store";
import {
  newSelection,
  SketchSelection,
  sketchSelection
} from "./sketchSelection";
import { actionSetPage } from "../../../reducers/page";

export const USER_SET_SESSION = "USER_SET_SESSION";
export const USER_SELECT_SKETCH = "USER_SELECT_SKETCH";
export const USER_SET_SELECTION_ENABLED = "USER_SET_SELECTION_ENABLED";

export interface UserSession {
  user: User;
  teams: Team[];
  projects: Project[];
  sessionID: string;
  env: string;
}

export interface UserState {
  userInfo?: UserInfo;
  listing?: SketchListing;
  selection: SketchSelection;
  session?: UserSession;
}

const SELECTION_ID = "userSketchSelection";
const selectionReducer = sketchSelection(SELECTION_ID);

export const actionSetUserSession = (session?: UserSession) =>
  actionCreator(USER_SET_SESSION, session);

export const actionUserSelectSketch = (sketchId: string, selectionId: string) =>
  actionCreator(USER_SELECT_SKETCH, { sketchId, selectionId });
export const actionSetSelectionEnabled = (
  selectionId: string,
  enabled: boolean
) => actionCreator(USER_SET_SELECTION_ENABLED, { selectionId, enabled });

type ActionType = ReturnType<
  | typeof actionSetPage
  | typeof actionSetUserSession
  | typeof actionSetUserSession
  | typeof actionUserSelectSketch
  | typeof actionSetSelectionEnabled
  | typeof Actions.loadUserNextPage
  | typeof Actions.loadUser
>;

// TODO: rename or split this reducer since its state is not only the user but the currently loaded listings and selections
export const user = (
  state: UserState = { selection: newSelection(SELECTION_ID) },
  action: ActionType
): UserState => {
  switch (action.type) {
    case USER_SET_SESSION:
      return { ...state, session: action.payload };
    // handle selection
    case USER_SET_SELECTION_ENABLED:
    case USER_SELECT_SKETCH:
      return { ...state, selection: selectionReducer(state.selection, action) };
    case Actions.LOAD_USER_NEXT_PAGE:
      // update user info and append new listings to the current listings
      return {
        ...state,
        userInfo: action.payload.userInfo,
        listing: {
          ...state.listing,
          summaries: state.listing
            ? [...state.listing.summaries, ...action.payload.listing.summaries]
            : action.payload.listing.summaries
        }
      };
    case Actions.LOAD_USER:
      return { ...state, ...action.payload };
  }
  return state;
};
