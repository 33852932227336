import * as React from "react";
import { styled } from "../theme";
import { Icon } from "semantic-ui-react";

const VimeoContainer = styled.div`
  position: relative;
  background-color: grey;
  img {
    max-width: 100%;
  }
  iframe {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
`;

const VimeoCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.6);
  p, i  {
    color: white !important;
  }
`;

export const Vimeo = (props: {
  id: string;
  cookies: boolean;
  previewId: string;
}) => {
  const [started, setStarted] = React.useState(props.cookies);

  return (
    <VimeoContainer>
      <img
        alt={"vimeo preview " + props.id}
        src={
          "https://i.vimeocdn.com/video/" +
          escape(props.previewId) +
          ".jpg?mw=1280&mh=720&q=70"
        }
      />

      {started && (
        <iframe
            title={"vimeo video " + props.id}
          src={"https://player.vimeo.com/video/" + escape(props.id)}
          frameBorder="0"
          allow="autoplay; fullscreen"
          ref={(r: any) => {
            r.width = r.parentElement.offsetWidth;
            r.height = r.parentElement.offsetHeight;
          }}
          allowFullScreen
        ></iframe>
      )}
      {!started && (
        <>
          <VimeoCenter>
            <div>
              <Icon
                name="play circle outline"
                size="huge"
                onClick={e => setStarted(true)}
              />
              <p>
                We use vimeo.com for our videos, if you hit the play button, we
                will load the vimeo player and it uses a lot of cookies{" "}
                <a
                  href="https://vimeo.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://vimeo.com/privacy
                </a>
              </p>
            </div>
          </VimeoCenter>
        </>
      )}
    </VimeoContainer>
  );
};
