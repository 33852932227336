import fscreen from "fscreen";

import * as Form from "../../form";
import {
  Filter,
  Info as UserInfo,
  Project,
  SketchListing,
  SketchMeta,
  SketchSummary
} from "../../services/vo/client";
import { Result } from "../../services/vo/search";
import { actionCreator, BAD_WAY_TO_GET_REDUX_DISPATCH } from "./store";
import { SketchTreeNode } from "../../services-async/vo/client";

// import * as jsCookie from "js-cookie";

export const DEV_STATIC = "DEV_STATIC";

export const ACTION_INVITED = "ACTION_INVITED";

export const HOME = "HOME";
export const NEXT = "NEXT";
export const PREVIOUS = "PREVIOUS";

export const GET_SKETCHES_IN_TRASH = "GET_SKETCHES_IN_TRASH";
export const LOAD_SKETCHES_IN_TRASH = "LOAD_SKETCHES_IN_TRASH";

export const SET_CLIENT_INNER_WIDTH = "SET_CLIENT_INNER_WIDTH";

export const SET_FORBIDDEN_ROUTE = "SET_FORBIDDEN_ROUTE";
export const ARTIST_SET_FIELD = "ARTIST_SET_FIELD";
export const FORM_INPUT = "FORM_INPUT";
export const LOAD_SESSION = "LOAD_SESSION";
export const LOAD_PROJECTS = "LOAD_PROJECTS";

export const SEARCH_SKETCHES = "SEARCH_SKETCHES";
export const LOAD_SEARCH_RESULT = "LOAD_SEARCH_RESULT";
export const LOAD_SEARCH_SUGGESTIONS = "LOAD_SEARCH_SUGGESTIONS";
export const SEARCH_SKETCHES_NEXT_PAGE = "SEARCH_SKETCHES_NEXT_PAGE";
export const LOAD_SEARCH_RESULT_NEXT_PAGE = "LOAD_SEARCH_RESULT_NEXT_PAGE";

export const GET_SKETCHES_FOR_TAG = "GET_SKETCHES_FOR_TAG";
export const GET_SKETCHES_FOR_TAG_NEXT_PAGE = "GET_SKETCHES_FOR_TAG_NEXT_PAGE";
export const LOAD_SKETCHES_FOR_TAG = "LOAD_SKETCHES_FOR_TAG";
export const LOAD_SKETCHES_FOR_TAG_NEXT_PAGE =
  "LOAD_SKETCHES_FOR_TAG_NEXT_PAGE";

export const GET_PROJECT = "GET_PROJECT";
export const GET_PROJECT_NEXT_PAGE = "GET_PROJECT_NEXT_PAGE";
export const LOAD_PROJECT_NEXT_PAGE = "LOAD_PROJECT_NEXT_PAGE";
export const LOAD_PROJECT = "LOAD_PROJECT";
export const MV_SKETCHES_TO_PROJECT = "MV_SKETCHES_TO_PROJECT";
export const DELETE_SKETCHES = "DELETE_SKETCHES";

export const GET_USER = "GET_USER";
export const GET_USER_NEXT_PAGE = "GET_USER_NEXT_PAGE";
export const LOAD_USER = "LOAD_USER";
export const LOAD_USER_NEXT_PAGE = "LOAD_USER_NEXT_PAGE";

export const GET_SKETCH = "GET_SKETCH";
export const GET_SKETCH_SUMMARY = "GET_SKETCH_SUMMARY";
export const LOAD_SKETCH = "LOAD_SKETCH";
export const LOAD_SKETCH_TREE = "LOAD_SKETCH_TREE";
export const LOAD_SKETCH_SUMMARY = "LOAD_SKETCH_SUMMARY";
export const SET_SKETCH_FULLSCREEN = "SET_SKETCH_FULLSCREEN";

export const EDIT_SKETCH = "EDIT_SKETCH";
export const CANCEL_SKETCH_EDIT = "CANCEL_SKETCH_EDIT";
export const SET_SKETCH_PUBLIC = "SET_SKETCH_PUBLIC";
export const LOAD_SKETCH_PUBLIC = "LOAD_SKETCH_PUBLIC";
export const SET_SKETCH_RENDER_WIDTH = "SET_SKETCH_RENDER_WIDTH";
export const SET_SKETCH_DEFAULT_RENDER_WIDTH =
  "SET_SKETCH_DEFAULT_RENDER_WIDTH";

export const PLAY_SKETCH = "PLAY_SKETCH";
export const REPLAY_SKETCH = "REPLAY_SKETCH";
export const REPLAY_SKETCH_COMPLETE = "REPLAY_SKETCH_COMPLETE";
export const TOGGLE_PLAY_SKETCH = "TOGGLE_PLAY_SKETCH";

export const SUBMIT_REGISTRATION = "SUBMIT_REGISTRATION";

export const EDIT_PROJECT = "EDIT_PROJECT";
export const EDIT_PROJECT_RM_USER = "EDIT_PROJECT_RM_USER";
export const EDIT_PROJECT_ADD_USER = "EDIT_PROJECT_ADD_USER";
export const EDIT_PROJECT_MAKE_USER_OWNER = "EDIT_PROJECT_MAKE_USER_OWNER";
export const EDIT_PROJECT_MAKE_USER_MEMBER = "EDIT_PROJECT_MAKE_USER_MEMBER";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const LOAD_PROJECT_USER_INFOS = "LOAD_PROJECT_USER_INFOS";
export const LOAD_ALL_USER_INFOS = "LOAD_ALL_USER_INFOS";

export const ENV_PRICING_SHOW = "ENV_PRICING_SHOW";

const d = (type: string, data: any = {}) => {
  const dispatch = BAD_WAY_TO_GET_REDUX_DISPATCH();
  const action = { ...data, type };
  dispatch({ ...data, type });
  return action;
};

// dev stuff
export const devStatic = (content: { [k: string]: any }) => {
  d(DEV_STATIC, { content: content });
};

// env

export const envPricingShow = show => d(ENV_PRICING_SHOW, { show });

// general stuff
export const home = () => d(HOME);
export const setforbiddenRoute = (route: string) =>
  d(SET_FORBIDDEN_ROUTE, { route });
export const formInput = (inputData: Form.InputData, value: any) =>
  d(FORM_INPUT, { inputData, value });

export const loadProjects = (projects: Project[]) =>
  d(LOAD_PROJECTS, { projects });

// project related
export const getProject = (id: string, filters: Filter[]) =>
  d(GET_PROJECT, { id, filters });

export const getProjectNextPage = () => d(GET_PROJECT_NEXT_PAGE);
export const loadProjectNextPage = (listing: SketchListing) =>
  d(LOAD_PROJECT_NEXT_PAGE, { listing });

export const loadProject = (project: Project, listing: SketchListing) =>
  d(LOAD_PROJECT, { project, listing });

export const loadProjectUserInfos = (userInfos: UserInfo[]) =>
  d(LOAD_PROJECT_USER_INFOS, { userInfos });
export const loadAllUserInfos = (userInfos: UserInfo[]) =>
  d(LOAD_ALL_USER_INFOS, { userInfos });

// sketches
export const getSketch = (id: string) => d(GET_SKETCH, { id });
export const getSketchSummary = (id: string) => d(GET_SKETCH_SUMMARY, { id });
export const loadSketch = (
  sketch: SketchSummary,
  project: Project,
  sketchMeta: SketchMeta
) => d(LOAD_SKETCH, { sketch, project, sketchMeta });
export const loadSketchSummary = (summary: SketchSummary, project?: Project) =>
  d(LOAD_SKETCH_SUMMARY, { summary, project });
export const editSketch = (change: any) => d(EDIT_SKETCH, { change: change });
export const actionCancelSketchEdit = () => actionCreator(CANCEL_SKETCH_EDIT);
export const setSketchPublic = (id: string, sketchPublic: boolean) =>
  d(SET_SKETCH_PUBLIC, { id, sketchPublic });
export const loadSketchPublic = (id: string, sketchPublic: boolean) =>
  d(LOAD_SKETCH_PUBLIC, { id, sketchPublic });
export const setSketchRenderWidth = (renderWidth: number) =>
  d(SET_SKETCH_RENDER_WIDTH, { renderWidth });
export const setSketchDefaultRenderWidth = (defaultRenderWidth: number) =>
  d(SET_SKETCH_DEFAULT_RENDER_WIDTH, { defaultRenderWidth });
export const replaySketchComplete = () => d(REPLAY_SKETCH_COMPLETE, {});

export const loadSketchTree = (tree: SketchTreeNode) =>
  d(LOAD_SKETCH_TREE, { tree });

export const setSketchFullscreen = (fullscreen: boolean) => {
  let playerEl = document.getElementById("app");
  if (playerEl && fscreen.fullscreenEnabled) {
    if (fullscreen) {
      fscreen.requestFullscreen(playerEl);
    } else if (document.fullscreen) {
      fscreen.exitFullscreen();
    }
  }
  return d(SET_SKETCH_FULLSCREEN, { fullscreen });
};

export const playSketch = (progress: number, speed: number) =>
  d(PLAY_SKETCH, { progress, speed });

export const togglePlaySketch = () => d(TOGGLE_PLAY_SKETCH);
export const replaySketch = () => d(REPLAY_SKETCH);
export const next = () => d(NEXT);
export const previous = () => d(PREVIOUS);

// profile related

export const submitRegistration = () => d(SUBMIT_REGISTRATION);

export const editProject = (id: string | null) => d(EDIT_PROJECT, { id });
export const editProjectRmUser = (projectId: string, userId: string) =>
  d(EDIT_PROJECT_RM_USER, { projectId, userId });
export const editProjectAddUser = (
  teamId: string,
  projectId: string,
  email: string
) => d(EDIT_PROJECT_ADD_USER, { teamId, projectId, email });

export const editProjectMakeUserOwner = (projectId: string, userId: string) =>
  d(EDIT_PROJECT_MAKE_USER_OWNER, { projectId, userId });
export const editProjectMakeUserMember = (projectId: string, userId: string) =>
  d(EDIT_PROJECT_MAKE_USER_MEMBER, { projectId, userId });

export const deleteProject = (accountId: string, projectId: string) =>
  d(DELETE_PROJECT, { accountId, projectId });
export const moveSketchesToProject = (projectId: string, sketchIds: string[]) =>
  d(MV_SKETCHES_TO_PROJECT, { projectId, sketchIds });
export const deleteSketches = (sketchIds: string[], context: string) =>
  d(DELETE_SKETCHES, { sketchIds, context });

export const getUser = (userId: string, filters: Filter[]) =>
  d(GET_USER, { userId, filters });
export const getUserNextPage = () => d(GET_USER_NEXT_PAGE);

export const loadUser = (userInfo: UserInfo, listing: SketchListing) =>
  actionCreator(LOAD_USER, { userInfo, listing });

export const loadUserNextPage = (userInfo: UserInfo, listing: SketchListing) =>
  actionCreator(LOAD_USER_NEXT_PAGE, { userInfo, listing });

export const setClientInnerWidth = (innerWidth: number) =>
  d(SET_CLIENT_INNER_WIDTH, { innerWidth });

export const getSketchesInTrash = (projectID: string) =>
  d(GET_SKETCHES_IN_TRASH, { projectID });
export const loadSketchesInTrash = (
  summaries: SketchSummary[],
  project: Project
) => d(LOAD_SKETCHES_IN_TRASH, { summaries, project });

export const searchSketches = (q: string, filters: Filter[]) =>
  d(SEARCH_SKETCHES, { q, filters });
export const loadSearchResult = (result: Result) =>
  d(LOAD_SEARCH_RESULT, { result });
export const loadSearchResultNextPage = (result: Result) =>
  d(LOAD_SEARCH_RESULT_NEXT_PAGE, { result });

export const searchSketchesNextPage = () => d(SEARCH_SKETCHES_NEXT_PAGE);

export const getSketchesForTag = (tag: string, filters: Filter[]) =>
  d(GET_SKETCHES_FOR_TAG, { tag, filters });
export const loadSketchesForTag = (tag: string, listing: SketchListing) =>
  d(LOAD_SKETCHES_FOR_TAG, { tag, listing });
export const getSketchesForTagNextPage = () =>
  d(GET_SKETCHES_FOR_TAG_NEXT_PAGE);
export const loadSketchesForTagNextPage = (
  tag: string,
  listing: SketchListing
) => d(LOAD_SKETCHES_FOR_TAG_NEXT_PAGE, { tag, listing });
