import * as React from "react";
import { BreakpointName, BreakpointSize } from "../theme";

export const breakpointForSize = (size: number): BreakpointName => {
  switch (true) {
    case size >= BreakpointSize.desktopL:
      return BreakpointName.desktopL;
    case size >= BreakpointSize.desktopM:
      return BreakpointName.desktopM;
    case size >= BreakpointSize.desktop:
      return BreakpointName.desktop;
    case size >= BreakpointSize.laptopL:
      return BreakpointName.laptopL;
    case size >= BreakpointSize.laptopM:
      return BreakpointName.laptopM;
    case size >= BreakpointSize.laptop:
      return BreakpointName.laptop;
    case size >= BreakpointSize.tablet:
      return BreakpointName.tablet;
    case size >= BreakpointSize.mobileL:
      return BreakpointName.mobileL;
    case size >= BreakpointSize.mobileM:
      return BreakpointName.mobileM;
    case size >= BreakpointSize.mobileS:
      return BreakpointName.mobileS;
  }
};

const WindowSizeContext = React.createContext<number>(0);
export const WindowSizeProvider: React.FC<{}> = props => {
  const [width, setWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <WindowSizeContext.Provider value={breakpointForSize(width)}>
      {props.children}
    </WindowSizeContext.Provider>
  );
};

export default WindowSizeContext.Consumer;
