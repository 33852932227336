import * as React from "react";

import * as Actions from "../actions";
import * as Form from "../form";

export interface InputOptions {
  value: Form.InputData;
  label?: string;
  placeholder?: string;
  type?: string;
  name?: string;
  lowercase?: boolean;
  autoComplete?: string;
  action?: {
    label: string;
    action: (e) => void;
  };
  onChange?: (value: any) => void;
  readOnly?: boolean;
}

interface InputState {
  focus?: boolean;
  value?: any;
  lastFired?: string;
}

export class Input extends React.Component<InputOptions, InputState> {
  private timeout;

  constructor(props: InputOptions) {
    super(props);
    this.state = {};
  }

  
  static getDerivedStateFromProps(
    props: InputOptions,
    state: InputState
  ): InputState {
    const value = props.value.value ? props.value.value : "";
    if (state) {
      return { value, ...state };
    }
    return { value };
  }

  render() {
    const v = this.props.value;
    let t = this.props.type;
    if (t === undefined) {
      t = "text";
    }
    const valid = !!(v.validation && v.validation.valid);
    return this.renderInput(
      v.validation !== undefined,
      valid,
      v.validation ? v.validation.feedback : undefined
    );
  }
  private handleChange = e => {
    if (this.props.lowercase === true) {
      e.target.value = e.target.value.toLowerCase();
    }
    const v = this.props.value;
    const value = e.target.value;

    this.setState({ value: e.target.value });
    clearTimeout(this.timeout);

    const change = () => {
      if (this.props.onChange) {
        this.props.onChange(value);
      } else {
        Actions.formInput(v, value);
      }
    };

    if (e.type === "blur") {
      change();
    } else {
      this.timeout = setTimeout(() => {
        change();
      }, 400);
    }
  };

  private renderInput = (
    validated: boolean,
    valid: boolean = false,
    feedback: string = undefined
  ) => {
    let hasErrorFeedback = !valid && feedback !== undefined;
    let hasIcon = validated && !feedback;
    let hasAction = this.props.action;
    // console.log({validated, feedback, hasIcon, valid, value : (this.state?this.state.value:"---")});
    return (
      <div className={"field " + (validated && !valid ? "error" : "")}>
        {this.props.label && <label>{this.props.label}</label>}

        <div
          className={
            "ui input " +
            (hasIcon ? "icon " : "") +
            (hasErrorFeedback ? " right labeled" : "") +
            (hasAction ? " action" : "")
          }
        >
          <input
            autoComplete={this.props.autoComplete}
            value={this.state ? this.state.value : ""}
            onChange={this.handleChange.bind(this)}
            onBlur={this.handleChange.bind(this)}
            placeholder={this.props.placeholder}
            type={this.props.type}
            readOnly={this.props.readOnly}
          />
          {hasIcon && (
            <i className={"check link icon " + (!valid ? "red" : "green")} />
          )}
          {hasAction && (
            <div className="ui button" onClick={this.props.action.action}>
              {this.props.action.label}
            </div>
          )}
          {hasErrorFeedback && (
            <div className={"ui basic label red"}>{feedback}</div>
          )}
        </div>
      </div>
    );
  };
}
