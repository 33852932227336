import * as GeneralActions from "../../../actions";
import { ServiceClient as ProjectsClient } from "../../../services/projects";
import { ServiceClient as UsersClient } from "../../../services/users";
import * as Actions from "../actions";
import { numberOfSketchesPerPage } from "../components/sketchList/filteredSketchList";
import * as Middleware from "../middleware";
import { PAGES } from "../Page";
import { State } from "../store";
import { Store } from "redux";

export const SKETCH_DELETION_CONTEXT = "user";

export const userMiddleware = (store: Store) => (next: any) => (
  action: any
) => {
  let st: State = store.getState();
  switch (action.type) {
    case Actions.DELETE_SKETCHES:
      if (action.context === SKETCH_DELETION_CONTEXT) {
        ProjectsClient.defaultInst.deleteSketches(
          action.sketchIds,
          err => {
            Actions.getProject(
              st.project.project.id,
              st.project.listing.filters
            );
          },
          Middleware.handleErr(store.dispatch)
        );
      }
      break;
    case Actions.GET_USER_NEXT_PAGE:
      let userState = st.user;
      if (userState.listing.page < userState.listing.pages) {
        UsersClient.defaultInst.getUserAndSketches(
          userState.userInfo.id,
          userState.listing.filters,
          userState.listing.page + 1,
          numberOfSketchesPerPage(),
          (user, listing, err) => {
            // console.log(
            // 	"GET_USER_NEXT_PAGE => LOAD_USER_NET_PAGE",
            // );
            // debugListing(listing);
            store.dispatch(Actions.loadUserNextPage(user, listing));
          },
          Middleware.handleErr(store.dispatch)
        );
      }
      break;
    case Actions.GET_USER:
      UsersClient.defaultInst.getUserAndSketches(
        action.userId,
        action.filters,
        0,
        numberOfSketchesPerPage(),
        (user, listing, err) => {
          // console.log("GET_USER => LOAD_USER");
          // debugListing(listing);
          store.dispatch(Actions.loadUser(user, listing));
          GeneralActions.setPage(PAGES.user);
        },
        Middleware.handleErr(store.dispatch)
      );
      break;
  }
  return next(action);
};
