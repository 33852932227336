import * as React from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import { Button, Header, Modal } from "semantic-ui-react";
import { GlobalStripeProvider, stripePubKey } from "../stripeProvider";
import { CardCheckoutForm } from "./checkoutForms/CardCheckoutForm";
import { ServiceError } from "../../../../services-async/vo/services";

interface SubscribeModalState {
  stripeInstance?: stripe.Stripe;
  modalOpen: boolean;
}

export const CreatePaymentSourceModal: React.FC<{}> = () => {
  const [modalOpen, setModalOpen] = React.useState(false);

  React.useEffect(() => {
    const stripeLoaded = (stripeInstance: stripe.Stripe) =>
      console.debug("stripe loaded", stripeInstance);

    GlobalStripeProvider.getStripeInstance(stripeLoaded);
    return () => GlobalStripeProvider.unsubscribe(stripeLoaded);
  }, []);

  const handleSubmitFinished = (err: ServiceError | XMLHttpRequest) => {
    if (err) {
      console.error(err);
      return;
    }
    setModalOpen(false);
  };

  return (
    <Modal
      trigger={
        <Button onClick={() => setModalOpen(true)}>Add Payment Method</Button>
      }
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      dimmer="blurring"
      size="small"
    >
      <Header icon="money" content="Create Payment Method" />
      <Modal.Content>
        <h3>Add payment method.</h3>
        <StripeProvider apiKey={stripePubKey}>
          <Elements>
            <CardCheckoutForm onSubmitted={handleSubmitFinished} />
          </Elements>
        </StripeProvider>
      </Modal.Content>
    </Modal>
  );
};
