import { Dispatch, Store } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { setPage } from "../../actions";
import { actionAddErrorToast } from "../../reducers/toast";
import { getClient } from "../../services-async/transport";
import { ServiceClient as UsersClient } from "../../services-async/users";
import { ServiceClient as SketchClient } from "../../services/sketch";
import * as Actions from "./actions";
import { PAGES } from "./Page";
import { signupEmailIsInUse } from "./reducers/userProfileFormReducer";
import * as SignupReducer from "./reducers/signup";
import { loadTeamState } from "./reducers/teams";
import { State } from "./store";

/**
 * reloadSession
 * this function will try to reload/get the session from remote server
 * @param dispatch
 * @param done success callback
 * @param errDone error callback
 */
export const reload = async (
  dispatch: ThunkDispatch<{}, {}, any>,
  done: (user, teams, err) => void,
  errDone?: () => void
) => {
  try {
    const { user, teams, err } = (await dispatch(loadTeamState())) as any;
    done(user, teams, err);
  } catch (e) {
    console.error("Failed somehow", e);
    if (errDone) {
      errDone();
    }
  }
};

export const handleErr = (dispatch: Dispatch) => op => {
  dispatch(
    actionAddErrorToast(
      "UNKNOWN_ERR_TITLE",
      !navigator.onLine ? "ERR_OFFLINE_SERVER" : "ERR_OFFLINE_CON",
      true
    )
  );
  console.error("Service Error:", op);
};

const userClient = getClient(UsersClient);

export const middleware = (store: Store<State>) => (next: any) => (
  action: any
) => {
  if (action === undefined) {
    console.error("action is undefined? WTF? Wlaaaaaaaaaaaaad!");
    return;
  }
  switch (action.type) {
    case Actions.GET_SKETCHES_IN_TRASH:
      SketchClient.defaultInst.getSketchesInTrash(
        action.projectID,
        (summaries, project, err) => {
          if (err === null) {
            setPage(PAGES.trash);
            Actions.loadSketchesInTrash(summaries, project);
          }
        },
        handleErr(store.dispatch)
      );
      break;
    case Actions.FORM_INPUT:
      switch (action.inputData.prefix) {
        case SignupReducer.SIGNUP_FORM_ID:
          if (action.inputData.id === "email") {
            userClient
              .emailIsInUse(action.value)
              .then(inUse => store.dispatch(signupEmailIsInUse(inUse)))
              .catch(err => handleErr(store.dispatch));
          }
          break;
      }
      break;
  }
  return next(action);
};
