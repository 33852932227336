import * as GeneralActions from "../../../actions";
import {
  USER_SET_SELECTION_ENABLED,
  USER_SELECT_SKETCH,
  actionSetSelectionEnabled,
  actionUserSelectSketch
} from "./user";
import { actionSetPage } from "../../../reducers/page";

export interface SketchSelection {
  id: string;
  ids: string[];
  enabled: boolean;
}

export const newSelection = (
  id,
  ids = [],
  enabled = false
): SketchSelection => {
  return { id, ids, enabled };
};

export const sketchSelection = selectionId => {
  return (
    state: SketchSelection,
    action: ReturnType<
      | typeof actionSetPage
      | typeof actionSetSelectionEnabled
      | typeof actionUserSelectSketch
    >
  ) => {
    switch (action.type) {
      case GeneralActions.SET_PAGE:
        return Object.assign({}, state, {
          ids: [],
          enabled: false
        });
      case USER_SET_SELECTION_ENABLED:
        if (action.payload.selectionId === selectionId) {
          return Object.assign({}, state, {
            enabled: action.payload.enabled,
            ids: []
          });
        }
        break;
      case USER_SELECT_SKETCH:
        if (action.payload.selectionId === selectionId) {
          var ids = state.ids.map(id => id);
          if (state.ids.indexOf(action.payload.sketchId) > -1) {
            ids = ids.filter(id => action.payload.sketchId !== id);
          } else {
            ids.push(action.payload.sketchId);
          }
          return {
            id: selectionId,
            ids: ids,
            enabled: state.enabled
          };
        }
        break;
    }
    return state;
  };
};
