import React, { Suspense } from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Store } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { breakpoints as bp } from "../../components/picture";
import FeedbackButton from "./components/FeedbackButton";
import { WindowSizeProvider } from "../../components/WindowSizeProvider";
import { ThemeProvider, theme } from "../../theme";
import * as Actions from "./actions";
import GlobalError from "./components/GlobalError";
import Footer from "./components/footer";
import { Layout } from "./components/Layout";
import SiteMenu from "./components/siteMenu/siteMenu";
import Page, { PageSpinner } from "./Page";
import { loadTeamState } from "./reducers/teams";
import { boot } from "./router";
import { State, storeCreator } from "./store";

export const breakpoints = bp;

const createWindowUnloadHandler = (store: Store<State>) => (
  e: BeforeUnloadEvent
) => {
  console.log({ ...e });
  // there will be more dirty state in the future I assume
  let dirty = false;
  let changes = [];
  if (store.getState().sketch.dirty) {
    changes.push("unsaved sketch");
    dirty = true;
  }
  if (dirty) {
    if (!window.confirm("loose changes: " + changes.join(" ") + " ?")) {
      e.preventDefault();
    }
  }
  let resizeTimeout = -1;
  let resizeHandler = e => {
    Actions.setClientInnerWidth(window.innerWidth);
  };

  window.onresize = () => {
    window.clearTimeout(resizeTimeout);
    resizeTimeout = window.setTimeout(resizeHandler, 100);
  };
};

export const runSite = async () => {
  const store = storeCreator();
  try {
    // this will load team, project and user settings into store
    // this way the router can decide where to redirect to
    await (store.dispatch as ThunkDispatch<{}, {}, any>)(loadTeamState(false));
    // boot the router
    boot("", store);
  } catch (e) {
    console.error("failed to boot application", e);
  }
  // handle window unload if changes not published
  window.onbeforeunload = createWindowUnloadHandler(store);
  renderApp(store);
};

const renderApp = (store: Store<State>) => {
  const appEl = document.getElementById("app");
  // add react-redux and i18n
  const AppComp = (
    <Provider store={store}>
      <WindowSizeProvider>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </WindowSizeProvider>
    </Provider>
  );
  ReactDOM.render(AppComp, appEl);

};

export const App: React.FC = () => (
  <Layout>
    <SiteMenu />
    <GlobalError />
    <main>
      <Suspense fallback={<PageSpinner />}>
        <Page />
      </Suspense>
    </main>
    <Footer/>
    <FeedbackButton />
  </Layout>
);
