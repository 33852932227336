import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Icon, Transition } from "semantic-ui-react";

import {
  actionDismissToast,
  Message,
  ToastLevel,
  actionDismissAllToasts
} from "../../../reducers/toast";
import { styled } from "../../../theme";
import { State } from "../store";

const getBGColorForErrorLevel = (level: ToastLevel) => {
  switch (level) {
    case ToastLevel.Info:
      return "green";
    default:
      return "#ee5253ee";
  }
};

const GlobalToaster = styled.div`
  max-width: 400px;
  z-index: 1001;
  margin: 7px;
  position: fixed;
  right: 0;
`;

const ToastMessage = styled.div<{ level: ToastLevel }>`
  backdrop-filter: blur(5px);
  background-color: ${props => getBGColorForErrorLevel(props.level)};
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  padding: 10px 40px 10px 10px;
  border-radius: 10px;
  display: block;
  position: relative;
  margin-bottom: 7px;
  p {
    color: white;
    margin-bottom: 0;
  }
  .icon {
    margin: 0;
    padding: 0;
    position: absolute;
    display: block;
    right: 0.5rem;
    top: 0.3rem;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.3);
  }
`;

const StyledErrorHeader = styled.h4`
  margin: 0.5rem 0 0 0;
  padding-bottom: 5px;
  color: white;
`;

export interface GlobalErrorProps extends ReturnType<typeof mapStateToProps> {
  dispatch: Dispatch;
}

class ToastManager {
  private messages: Message[] = [];
  private timers: Map<number, number> = new Map();
  public onChange?: () => void;

  public add(msg: Message, dismissTimeout: number | undefined = 3000) {
    this.messages.push(msg);
    if (dismissTimeout) {
      this.timers.set(
        msg.id,
        setTimeout(() => this.removeMessageTimeout(msg.id), dismissTimeout)
      );
    }
  }

  public getMessages = (): Readonly<Message[]> => this.messages;

  public remove = (msgId: number) => {
    this.removeMessageTimeout(msgId);
  };

  private removeMessageTimeout = (id: number) => {
    this.timers.delete(id);
    const oldLength = this.messages.length;
    this.messages = this.messages.filter(msg => msg.id !== id);
    if (oldLength !== this.messages.length && this.onChange) {
      this.onChange();
    }
  };
}

const GlobalError: React.FC<GlobalErrorProps> = ({ messages, dispatch }) => {
  const { t } = useTranslation();
  const [toaster] = useState(new ToastManager());
  // displayed messages
  const [toasts, setToasts] = useState<Readonly<Message[]>>(
    messages ? messages : []
  );

  // update messages
  useEffect(() => (toaster.onChange = () => setToasts(toaster.getMessages())));

  // if external state changes copy toasts into this components and set the timeouts
  useEffect(() => {
    if (messages.length === 0) {
      return;
    }
    messages.forEach(toast => toaster.add(toast));
    setToasts(toaster.getMessages());
    dispatch(actionDismissAllToasts());
  }, [messages, dispatch, toaster]);

  return (
    <GlobalToaster>
      <Transition.Group animation="fade down" duration={200}>
        {toasts.map((toast, i) => (
          <ToastMessage level={toast.level} key={i}>
            <StyledErrorHeader>
              {toast.localize ? t(toast.title) : toast.title}
            </StyledErrorHeader>
            <p>{toast.localize ? t(toast.message) : toast.message}</p>
            <Icon
              name="close"
              onClick={() => dispatch(actionDismissToast(toast.id))}
            />
          </ToastMessage>
        ))}
      </Transition.Group>
    </GlobalToaster>
  );
};

const mapStateToProps = (state: State) => ({
  messages: state.toaster.messages
});

export default connect(mapStateToProps)(GlobalError);
