import * as React from "react";
import { styled } from "../theme";

export enum SpacerSize {
  tiny = "tiny",
  small = "small",
  medium = "medium",
  large = "large"
}

/* @todo obviously this should be SpacerSize, but the compiler hates me */
export const StyledSpacer = styled.div<{spacerSize: SpacerSize;}>`
  padding-top: ${(props): string => {
    switch (props.spacerSize) {
      case SpacerSize.tiny:
        return "1rem";
      case SpacerSize.small:
        return "2rem";
      case SpacerSize.large:
        return "5rem";
      default:
        return "3rem";
    }
  }};
  }
`;

export const Spacer = (props: { size?: SpacerSize }) => {
  return (
    <StyledSpacer spacerSize={props.size ? props.size : SpacerSize.medium} />
  );
};
