/* tslint:disable */
// hello commonjs - we need some imports - sorted in alphabetical order, by go package
import * as github_com_bestbytes_paperroll_server_billing from './billing'; // webpack/src/services/vo/users.ts to webpack/src/services/vo/billing.ts
import * as github_com_bestbytes_paperroll_server_client from './client'; // webpack/src/services/vo/users.ts to webpack/src/services/vo/client.ts
import * as github_com_bestbytes_paperroll_server_protovo from './protovo'; // webpack/src/services/vo/users.ts to webpack/src/services/vo/protovo.ts
import * as github_com_bestbytes_paperroll_server_services from './services'; // webpack/src/services/vo/users.ts to webpack/src/services/vo/services.ts
import * as github_com_bestbytes_paperroll_server_services_dev from './dev'; // webpack/src/services/vo/users.ts to webpack/src/services/vo/dev.ts
import * as github_com_bestbytes_paperroll_server_services_projects from './projects'; // webpack/src/services/vo/users.ts to webpack/src/services/vo/projects.ts
import * as github_com_bestbytes_paperroll_server_services_search from './search'; // webpack/src/services/vo/users.ts to webpack/src/services/vo/search.ts
import * as github_com_bestbytes_paperroll_server_services_subscriptions from './subscriptions'; // webpack/src/services/vo/users.ts to webpack/src/services/vo/subscriptions.ts
import * as github_com_bestbytes_paperroll_server_services_teams from './teams'; // webpack/src/services/vo/users.ts to webpack/src/services/vo/teams.ts
import * as github_com_bestbytes_paperroll_server_services_users from './users'; // webpack/src/services/vo/users.ts to webpack/src/services/vo/users.ts
import * as github_com_stripe_stripe_go from './stripe'; // webpack/src/services/vo/users.ts to webpack/src/services/vo/stripe.ts
// github.com/bestbytes/paperroll-server/services/users.Force
export interface Force {
}
// github.com/bestbytes/paperroll-server/services/users.PaymentInfo
export interface PaymentInfo {
	id:string;
	type:string;
	brand:string;
	expYear:number;
	expMonth:number;
	last4:string;
	name:string;
}
// constants from github.com/bestbytes/paperroll-server/services/users
export const GoConst = {
	ErrorAddStripeSource : "ErrorAddStripeSource",
	ErrorAuthLostPassword : "ErrorAuthLostPassword",
	ErrorAuthPassword : "ErrorAuthPassword",
	ErrorAuthUnconfirmed : "ErrorAuthUnconfirmed",
	ErrorInvalidEmail : "ErrorInvalidEmail",
	ErrorRights : "ErrorRights",
	ErrorTokenInvalid : "ErrorTokenInvalid",
	ErrorUnknownEmail : "ErrorUnknownEmail",
	ErrorUserExists : "ErrorUserExists",
}
// end of common js