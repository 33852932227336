/* tslint:disable */
// hello commonjs - we need some imports - sorted in alphabetical order, by go package
import * as github_com_bestbytes_paperroll_server_billing from './../../services/vo/billing'; // webpack/src/services-async/vo/services.ts to webpack/src/services/vo/billing.ts
import * as github_com_bestbytes_paperroll_server_client from './client'; // webpack/src/services-async/vo/services.ts to webpack/src/services-async/vo/client.ts
import * as github_com_bestbytes_paperroll_server_relay from './relay'; // webpack/src/services-async/vo/services.ts to webpack/src/services-async/vo/relay.ts
import * as github_com_bestbytes_paperroll_server_services from './services'; // webpack/src/services-async/vo/services.ts to webpack/src/services-async/vo/services.ts
import * as github_com_bestbytes_paperroll_server_services_admin from './admin'; // webpack/src/services-async/vo/services.ts to webpack/src/services-async/vo/admin.ts
import * as github_com_bestbytes_paperroll_server_services_projects from './projects'; // webpack/src/services-async/vo/services.ts to webpack/src/services-async/vo/projects.ts
import * as github_com_bestbytes_paperroll_server_services_search from './search'; // webpack/src/services-async/vo/services.ts to webpack/src/services-async/vo/search.ts
import * as github_com_bestbytes_paperroll_server_services_subscriptions from './../../services/vo/subscriptions'; // webpack/src/services-async/vo/services.ts to webpack/src/services/vo/subscriptions.ts
import * as github_com_bestbytes_paperroll_server_services_teams from './teams'; // webpack/src/services-async/vo/services.ts to webpack/src/services-async/vo/teams.ts
import * as github_com_bestbytes_paperroll_server_services_users from './users'; // webpack/src/services-async/vo/services.ts to webpack/src/services-async/vo/users.ts
import * as github_com_stripe_stripe_go from './stripe'; // webpack/src/services-async/vo/services.ts to webpack/src/services-async/vo/stripe.ts
// github.com/bestbytes/paperroll-server/services.ServiceError
export interface ServiceError {
	errorType?:string;
	msg?:string;
}
// constants from github.com/bestbytes/paperroll-server/services
export const GoConst = {
	ErrorAuth : "ErrorAuth",
	ErrorInputData : "ErrorInputData",
	ErrorInternal : "ErrorInternal",
	ErrorRights : "ErrorRights",
}
// end of common js