import * as React from "react";
import { Divider, Grid, Icon } from "semantic-ui-react";

import { FilterOption as FilterOptionVo } from "../../../../services-async/vo/client";
import { Filter, GoConst } from "../../../../services/vo/client";
import { styled, theme, SelectionMode } from "../../../../theme";
import { Tag } from "../Tag";
import { UserList } from "../UserCircleList";

const StyledLabel = styled.p`
  margin: 0.5rem 0;
  font-size: 0.8rem;
  color: ${theme.bodyFontColorSecondary};
`;

const StyledGridRow = styled(Grid.Row)`
  padding: 0 !important;
`;

const StyledFilterInfo = styled.span`
  margin-right: 2rem;
`;

interface FilterRendererProps {
  options: FilterOptionVo[];
  onFilter: (option: FilterOptionVo) => void;
}

const getLabelForFilter = (type: string) => {
  switch (type) {
    case GoConst.FilterTypeProject:
      return "Projects";
    case GoConst.FilterTypeTag:
      return "Tags";
    case GoConst.FilterTypeUser:
      return "Users";
    case GoConst.FilterTypeTeam:
      return "Teams";
    default:
  }
  return "";
};

const UserFilters: React.FC<FilterRendererProps> = props => {
  return <UserList inline users={props.options} onUserClick={props.onFilter} />;
};
const TagFilters: React.FC<FilterRendererProps> = props => {
  return (
    <>
      {props.options.map(options => (
        <Tag
          key={options.value}
          onClick={() => options.enabled && props.onFilter(options)}
          mode={
            options.enabled
              ? options.selected
                ? SelectionMode.selected
                : undefined
              : SelectionMode.disabled
          }
        >
          {options.name}
        </Tag>
      ))}
    </>
  );
};

const StyledAllButton = styled.span`
  margin-right: 15px;
  cursor: pointer;
  display: inline-block;
`;

const FilterBlock: React.FC<{
  filter: Filter;
  onFilter: (filter: Filter) => void;
}> = ({ filter, onFilter }) => {
  if (filter.options.length === 1) {
    return <></>;
  }
  const label = getLabelForFilter(filter.filterType);
  const selected = filter.options.filter(o => o.selected).map(o => o.value);
  const allActive =
    selected.length === 0 || selected.length === filter.options.length;

  const onFilterHandler = (option: FilterOptionVo) => {
    option.selected = !option.selected;
    onFilter(filter);
  };

  return (
    <Grid.Column key={"filter-" + filter.filterType}>
      <StyledLabel>{label}</StyledLabel>
      <div>
        {(filter.filterType === GoConst.FilterTypeTag ||
          filter.filterType === GoConst.FilterTypeProject ||
          filter.filterType === GoConst.FilterTypeTeam) && (
          <TagFilters options={filter.options} onFilter={onFilterHandler} />
        )}
        {filter.filterType === GoConst.FilterTypeUser && (
          <UserFilters onFilter={onFilterHandler} options={filter.options} />
        )}
        {!allActive && (
          <StyledAllButton
            onClick={e => {
              filter.options = filter.options.map(option => {
                option.selected = false;
                return option;
              });
              onFilter(filter);
            }}
          >
            <Icon name="close" />
          </StyledAllButton>
        )}
      </div>
    </Grid.Column>
  );
};

interface SketchFiltersProps {
  filters: Filter[];
  filter: (filters: Filter[]) => void;
  showFilters: boolean;
}

const filterSummaryOrder = [
  GoConst.FilterTypeProject,
  GoConst.FilterTypeTag,
  GoConst.FilterTypeUser
];

const FilterSummary: React.FC<SketchFiltersProps> = props => {
  const enabled: { [k: string]: string[] } = {};
  let gotFilters = false;
  props.filters.forEach(f =>
    f.options.forEach(fo => {
      if (!enabled[f.filterType]) {
        enabled[f.filterType] = [];
      }
      if (fo.selected) {
        enabled[f.filterType].push(fo.name);
        gotFilters = true;
      }
    })
  );
  if (!gotFilters) {
    return null;
  }
  return (
    <>
      <StyledGridRow>
        <Grid.Column>
          {filterSummaryOrder
            .filter(filterType => enabled.hasOwnProperty(filterType) && enabled[filterType].length > 0)
            .map(filterType => {
              const name = getLabelForFilter(filterType);
              return (
                <StyledFilterInfo key={filterType}>
                  {name} filter: <b>{enabled[filterType].join(", ")}</b>
                </StyledFilterInfo>
              );
            })}
        </Grid.Column>
      </StyledGridRow>
      <Divider />
    </>
  );
};

export const SketchListingFilters: React.FC<SketchFiltersProps> = props => {
  const filters = props.filters;
  // if filters are toggled of show filter summary
  return (
    <Grid style={{ marginTop: 0, marginBottom: 0 }}>
      {props.showFilters ? (
        <>
          <StyledGridRow columns={2}>
            {filters.map((filter: Filter) => {
              if (filter.options.length < 1) {
                return null;
              }

              return (
                <FilterBlock
                  key={filter.filterType}
                  filter={filter}
                  onFilter={() => props.filter(filters)}
                />
              );
            })}
          </StyledGridRow>
          <Divider />
        </>
      ) : (
        <FilterSummary {...props} />
      )}
    </Grid>
  );
};
