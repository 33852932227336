import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store
} from "redux";
import thunk, { ThunkDispatch } from "redux-thunk";

import { bootMiddleware } from "../../middleware/boot";
import { logoutMiddleware } from "../../middleware/logout";
import toaster, { ToasterState } from "../../reducers/toast";
import { login, LoginState } from "../../reducers/login";
import { page, PageState } from "../../reducers/page";
import { Info as UserInfo, Project } from "../../services/vo/client";
import { middleware } from "./middleware";
import { allUsersMiddleware } from "./middleware/allUsers";
import { devStaticMiddleware } from "./middleware/devStatic";
import { editProjectMiddleware } from "./middleware/editProject";
import { prevNextMiddleware } from "./middleware/prevNext";
import { projectMiddleware } from "./middleware/project";
import { projectsMiddleware } from "./middleware/projects";
import { searchMiddleware } from "./middleware/search";
import { sketchMiddleware } from "./middleware/sketch";
import { tagMiddleware } from "./middleware/tag";
import { userMiddleware } from "./middleware/user";
import { PAGES } from "./Page";
import { allUsers } from "./reducers/allUsers";
// import { artist, ArtistState } from "./reducers/userProfileFormReducer";
import client, { ClientState } from "./reducers/client";
import { devStatic, DevStaticState } from "./reducers/devStatic";
import { editAccount, EditAccountState } from "./reducers/editAccount";
import { editProject, EditProjectState } from "./reducers/editProject";
import { invite, InviteState } from "./reducers/invite";
import { project, ProjectState } from "./reducers/project";
import { projects } from "./reducers/projects";
import { router, RouterState } from "./reducers/router";
import { search, SearchState } from "./reducers/search";
import { signup, SignupState } from "./reducers/signup";
import { sketch, SketchState } from "./reducers/sketch";
import subscribe, { SubscribeState } from "./reducers/subsribe";
import { tag, TagState } from "./reducers/tag";
import { teams, TeamsState } from "./reducers/teams";
import { trash, TrashState } from "./reducers/trash";
import { user, UserState } from "./reducers/user";
import { EnvState, env } from "./reducers/env";

export const actionCreator = <
  T extends string,
  P extends object | string | number | undefined | boolean
>(
  type: T,
  payload: P = undefined
) => ({ type, payload });

export interface State {
  page: PageState;
  login: LoginState;
  teams: TeamsState;
  projects: Project[];
  // artist: ArtistState;
  signup: SignupState;
  editAccount: EditAccountState;
  editProject: EditProjectState;
  router: RouterState;
  project: ProjectState;
  sketch: SketchState;
  user: UserState;
  toaster: ToasterState;
  allUsers: UserInfo[];
  client: ClientState;
  trash: TrashState;
  search: SearchState;
  tag: TagState;
  devStatic: DevStaticState;
  invite: InviteState;
  subscribe: SubscribeState;
  env:EnvState;
}

export const REDUX_STORE_KEY = "TEAMSKETCH_REDUX_STORE";
export const BAD_WAY_TO_GET_REDUX_DISPATCH = () =>
  ((window as any)[REDUX_STORE_KEY] as Store<State>).dispatch as ThunkDispatch<
    State,
    {},
    AnyAction
  >;

export const BAD_WAY_TO_GET_REDUX_STATE = () =>
  ((window as any)[REDUX_STORE_KEY] as Store<State>).getState();

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const createReducers = () => ({
  devStatic,
  page: page(PAGES.none),
  login,
  teams,
  projects,
  project,
  signup,
  editAccount,
  editProject,
  router,
  sketch,
  toaster,
  user,
  allUsers,
  client,
  trash,
  search,
  tag,
  invite,
  subscribe,
  env,
});

export const storeCreator = (): Store<State> => {
  const middlewares = [
    thunk,
    middleware,
    projectsMiddleware,
    editProjectMiddleware,
    projectMiddleware,
    sketchMiddleware,
    logoutMiddleware,
    allUsersMiddleware,
    userMiddleware,
    prevNextMiddleware,
    searchMiddleware,
    bootMiddleware,
    tagMiddleware,
    devStaticMiddleware
  ];
  const store = createStore(
    combineReducers(createReducers()),
    composeEnhancers(
      applyMiddleware<ThunkDispatch<State, any, AnyAction>>(...middlewares)
    )
  );

  // store the store in window to support legacy global store practice
  (window as any)[REDUX_STORE_KEY] = store;

  return store;
};

// export const state = (): State => store.getState();
