import {
    styled
  } from "../theme";
import { Button } from "semantic-ui-react";

export const CTAButton = styled(Button)`
    color: white !important;
    background-color: #FF9A00 !important;
`;


