/* tslint:disable */
// hello commonjs - we need some imports - sorted in alphabetical order, by go package
import * as github_com_bestbytes_paperroll_server_billing from './../services/vo/billing'; // webpack/src/services-async/subscriptions.ts to webpack/src/services/vo/billing.ts
import * as github_com_bestbytes_paperroll_server_client from './vo/client'; // webpack/src/services-async/subscriptions.ts to webpack/src/services-async/vo/client.ts
import * as github_com_bestbytes_paperroll_server_relay from './vo/relay'; // webpack/src/services-async/subscriptions.ts to webpack/src/services-async/vo/relay.ts
import * as github_com_bestbytes_paperroll_server_services from './vo/services'; // webpack/src/services-async/subscriptions.ts to webpack/src/services-async/vo/services.ts
import * as github_com_bestbytes_paperroll_server_services_admin from './vo/admin'; // webpack/src/services-async/subscriptions.ts to webpack/src/services-async/vo/admin.ts
import * as github_com_bestbytes_paperroll_server_services_projects from './vo/projects'; // webpack/src/services-async/subscriptions.ts to webpack/src/services-async/vo/projects.ts
import * as github_com_bestbytes_paperroll_server_services_search from './vo/search'; // webpack/src/services-async/subscriptions.ts to webpack/src/services-async/vo/search.ts
import * as github_com_bestbytes_paperroll_server_services_subscriptions from './../services/vo/subscriptions'; // webpack/src/services-async/subscriptions.ts to webpack/src/services/vo/subscriptions.ts
import * as github_com_bestbytes_paperroll_server_services_teams from './vo/teams'; // webpack/src/services-async/subscriptions.ts to webpack/src/services-async/vo/teams.ts
import * as github_com_bestbytes_paperroll_server_services_users from './vo/users'; // webpack/src/services-async/subscriptions.ts to webpack/src/services-async/vo/users.ts
import * as github_com_stripe_stripe_go from './vo/stripe'; // webpack/src/services-async/subscriptions.ts to webpack/src/services-async/vo/stripe.ts

export class ServiceClient {
	public static defaultEndpoint = "/service/subscriptions";
	constructor(
		public transport:<T>(method: string, data?: any[]) => Promise<T>
	) {}
	async changeSubscriptionPlan(teamID:string, newPlanID:string):Promise<void> {
		let response = await this.transport<{0:github_com_bestbytes_paperroll_server_services.ServiceError}>("ChangeSubscriptionPlan", [teamID, newPlanID])
		let err = response[0];
		if(err) { throw err }
	}
	async endSubscriptionForTeam(teamID:string):Promise<void> {
		let response = await this.transport<{0:github_com_bestbytes_paperroll_server_services.ServiceError}>("EndSubscriptionForTeam", [teamID])
		let err = response[0];
		if(err) { throw err }
	}
	async getAvailableSubscriptions():Promise<github_com_bestbytes_paperroll_server_billing.SubscriptionProduct[]> {
		let response = await this.transport<{0:github_com_bestbytes_paperroll_server_billing.SubscriptionProduct[]; 1:github_com_bestbytes_paperroll_server_services.ServiceError}>("GetAvailableSubscriptions", [])
		let err = response[1];
		if(err) { throw err }
		return response[0]
	}
	async getBillingSubscriptionForTeam(teamID:string):Promise<github_com_bestbytes_paperroll_server_services_subscriptions.SubscriptionInfo> {
		let response = await this.transport<{0:github_com_bestbytes_paperroll_server_services_subscriptions.SubscriptionInfo; 1:github_com_bestbytes_paperroll_server_services.ServiceError}>("GetBillingSubscriptionForTeam", [teamID])
		let err = response[1];
		if(err) { throw err }
		return response[0]
	}
	async getBillingSubscriptionsForUser(teamID:string, withTeamNames:boolean):Promise<github_com_bestbytes_paperroll_server_services_subscriptions.SubscriptionInfo[]> {
		let response = await this.transport<{0:github_com_bestbytes_paperroll_server_services_subscriptions.SubscriptionInfo[]; 1:github_com_bestbytes_paperroll_server_services.ServiceError}>("GetBillingSubscriptionsForUser", [teamID, withTeamNames])
		let err = response[1];
		if(err) { throw err }
		return response[0]
	}
	async getSubscriptionDetails(subscriptionID:string):Promise<github_com_bestbytes_paperroll_server_services_subscriptions.SubscriptionInfo> {
		let response = await this.transport<{0:github_com_bestbytes_paperroll_server_services_subscriptions.SubscriptionInfo; 1:github_com_bestbytes_paperroll_server_services.ServiceError}>("GetSubscriptionDetails", [subscriptionID])
		let err = response[1];
		if(err) { throw err }
		return response[0]
	}
	async subscribeUserToPlan(planID:string, teamID:string, sourceID:string):Promise<void> {
		let response = await this.transport<{0:github_com_bestbytes_paperroll_server_services.ServiceError}>("SubscribeUserToPlan", [planID, teamID, sourceID])
		let err = response[0];
		if(err) { throw err }
	}
}