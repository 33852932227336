export const setBooleanInLocalStorage = (key: string, value: boolean) => {
  localStorage.setItem(key, value ? "true" : "false");
};
export const getBooleanFromLocalStorage = (key): boolean => {
  let value = typeof localStorage === "object" && localStorage.getItem(key);
  return value === "true" ? true : false;
};

export const scrollToTop = () => {
  typeof window.scroll === "function"
    ? window.scroll({ left: 0, top: 0, behavior: "smooth" })
    : window.scrollTo(0, 0);
};

export const isTouchDevice = () =>
  typeof navigator.maxTouchPoints === "number" && navigator.maxTouchPoints > 0;

export const actionCreator = <
  T extends string,
  P extends object | string | number | undefined | boolean
>(
  type: T,
  payload: P = undefined
) => ({ type, payload });

export const getFullScreen = () => {
  let fullscreen = false;
  if (typeof document["webkitIsFullScreen"] == "boolean") {
    fullscreen = document["webkitIsFullScreen"];
  } else {
    fullscreen = document.fullscreen;
  }
  return fullscreen;
};
