import * as React from "react";
import { Divider, Dropdown, Menu, Confirm } from "semantic-ui-react";

import { DeleteLink } from "../../../components/deleteLink";
import { Project, Team } from "../../../services/vo/client";
import { styled } from "../../../theme";
import * as Actions from "../actions";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { State } from "../store";

const StyledDropdownHeader = styled(Dropdown.Header)`
  margin-top: 2rem !important;
`;

const StyledDropdownDivider = styled(Dropdown.Divider)`
  margin: 0 !important;
`;

const MoveItems: React.FC<{
  teams: Team[];
  projects: Project[];
  selectedIDs: string[];
  currentProjectId?: string;
}> = ({ teams, projects, selectedIDs, currentProjectId }) => {
  return (
    <>
      {teams.reduce((acc: any[], account) => {
        if (acc.length > 0) {
          acc.push(
            <StyledDropdownDivider key={"start-divider-" + account.id} />
          );
        }
        acc.push(
          <StyledDropdownHeader
            content={account.name}
            key={"account-" + account.id}
          />
        );
        acc.push(<StyledDropdownDivider key={"end-divider-" + account.id} />);
        projects
          .filter(project => project.teamId === account.id)
          .map(project => {
            acc.push(
              <Dropdown.Item
                disabled={!!currentProjectId && currentProjectId === project.id}
                key={"project-" + project.id}
                onClick={e =>
                  Actions.moveSketchesToProject(project.id, selectedIDs)
                }
              >
                {project.name}
              </Dropdown.Item>
            );
            return project;
          });
        return acc;
      }, [])}
    </>
  );
};

const StyledMenuMenu = styled(Menu.Menu)`
  width: 100% !important;
`;

const StyledMenuItem = styled(Menu.Item)`
  padding: 0 !important;
`;

interface SketchMoverProps {
  teams: Team[];
  projects: Project[];
  selection: { ids: string[]; id: string };
  currentProjectId?: string;
  deleteEnabled: boolean;
  context?: string;
  style?: any;
}

const SketchMover: React.FC<SketchMoverProps> = props => {
  const { t } = useTranslation();
  const disabled = !props.selection.ids || props.selection.ids.length === 0;
  const [confirm, setConfirm] = React.useState(false);

  return (
    <>
      {/* div required to break line after menu */}
      <div>
        <Menu secondary style={props.style}>
          <StyledMenuMenu>
            <StyledMenuItem>
              <Dropdown
                text={t(
                  props.currentProjectId
                    ? "MOVE_TO_OTHER_PROJECT"
                    : "MOVE_TO_PROJECT"
                )}
                floating
                labeled
                scrolling
                className="icon"
                disabled={disabled}
              >
                <Dropdown.Menu>
                  <MoveItems
                    teams={props.teams}
                    projects={props.projects}
                    selectedIDs={props.selection.ids}
                    currentProjectId={props.currentProjectId}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </StyledMenuItem>
            {props.deleteEnabled && (
              <StyledMenuItem position="right">
                <DeleteLink
                  disabled={disabled}
                  onClick={() => setConfirm(true)}
                >
                  {t("DELETE")}
                </DeleteLink>
                <Confirm
                  open={confirm}
                  onCancel={() => setConfirm(false)}
                  onClose={() => setConfirm(false)}
                  onConfirm={() =>
                    Actions.deleteSketches(props.selection.ids, props.context!)
                  }
                  header={t("DELETE_SKETCHES")}
                  content={t("DELETE_SKETCHES_DESCRIPTION")}
                />
              </StyledMenuItem>
            )}
          </StyledMenuMenu>
        </Menu>
      </div>
      <Divider />
    </>
  );
};
const mapStateToProps = (state: State) => ({
  teams: state.teams.teams,
  projects: state.teams.projects
});
export default connect(mapStateToProps)(SketchMover);
