import * as GeneralActions from "../../../actions";
import { SketchListing } from "../../../services/vo/client";
import * as Actions from "../actions";

import {
  newSelection,
  SketchSelection,
  sketchSelection
} from "./sketchSelection";
import { appendToListing } from "./utils";
import { USER_SET_SELECTION_ENABLED, USER_SELECT_SKETCH } from "./user";

export interface TagState {
  tag: string;
  listing?: SketchListing;
  selection: SketchSelection;
}

const SELECTION_ID = "tagSketchSelection";

let selectionReducer = sketchSelection(SELECTION_ID);

export const tag = (state: TagState | undefined, action: any): TagState => {
  if (state === undefined) {
    return {
      tag: "",
      selection: newSelection(SELECTION_ID)
    };
  }
  switch (action.type) {
    case Actions.LOAD_SKETCHES_FOR_TAG_NEXT_PAGE:
      return appendToListing(state, action);
    case GeneralActions.SET_PAGE:
    case USER_SET_SELECTION_ENABLED:
    case USER_SELECT_SKETCH:
      return Object.assign({}, state, {
        selection: selectionReducer(state.selection, action)
      });
    case Actions.LOAD_SKETCHES_FOR_TAG:
      return Object.assign({}, state, {
        tag: action.tag,
        listing: action.listing
      });
  }
  return state;
};
