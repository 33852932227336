/* tslint:disable */
// hello commonjs - we need some imports - sorted in alphabetical order, by go package
import * as github_com_bestbytes_paperroll_server_billing from './vo/billing'; // webpack/src/services/tags.ts to webpack/src/services/vo/billing.ts
import * as github_com_bestbytes_paperroll_server_client from './vo/client'; // webpack/src/services/tags.ts to webpack/src/services/vo/client.ts
import * as github_com_bestbytes_paperroll_server_protovo from './vo/protovo'; // webpack/src/services/tags.ts to webpack/src/services/vo/protovo.ts
import * as github_com_bestbytes_paperroll_server_services from './vo/services'; // webpack/src/services/tags.ts to webpack/src/services/vo/services.ts
import * as github_com_bestbytes_paperroll_server_services_dev from './vo/dev'; // webpack/src/services/tags.ts to webpack/src/services/vo/dev.ts
import * as github_com_bestbytes_paperroll_server_services_projects from './vo/projects'; // webpack/src/services/tags.ts to webpack/src/services/vo/projects.ts
import * as github_com_bestbytes_paperroll_server_services_search from './vo/search'; // webpack/src/services/tags.ts to webpack/src/services/vo/search.ts
import * as github_com_bestbytes_paperroll_server_services_subscriptions from './vo/subscriptions'; // webpack/src/services/tags.ts to webpack/src/services/vo/subscriptions.ts
import * as github_com_bestbytes_paperroll_server_services_teams from './vo/teams'; // webpack/src/services/tags.ts to webpack/src/services/vo/teams.ts
import * as github_com_bestbytes_paperroll_server_services_users from './vo/users'; // webpack/src/services/tags.ts to webpack/src/services/vo/users.ts
import * as github_com_stripe_stripe_go from './vo/stripe'; // webpack/src/services/tags.ts to webpack/src/services/vo/stripe.ts

export const call = (endPoint:string, method:string, args:any[], success:any, err:any) => {
        var request = new XMLHttpRequest();
        request.withCredentials = true;
        request.open('POST', endPoint + "/" + encodeURIComponent(method), true);
		// this causes problems, when the browser decides to do a cors OPTIONS request
        // request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
        request.send(JSON.stringify(args));
        request.onload = function() {
            if (request.status == 200) {
				try {
					var data = JSON.parse(request.responseText);
				} catch(e) {
	                err(request, e);
				}
				success.apply(null, data);
            } else {
                err(request);
            }
        };
        request.onerror = function() {
            err(request);
        };
    }

export class ServiceClient {
	static defaultInst = new ServiceClient;
	constructor(public endPoint:string = "/service/tags", public transport = call) {  }
	getSketchesForTag(tag:string, filters:github_com_bestbytes_paperroll_server_client.Filter[], page:number, pageSize:number, success:(listing:github_com_bestbytes_paperroll_server_client.SketchListing, err:github_com_bestbytes_paperroll_server_services.ServiceError) => void, err:(request:XMLHttpRequest, e?:Error) => void) {
		this.transport(this.endPoint, "GetSketchesForTag", [tag, filters, page, pageSize], success, err);
	}
}