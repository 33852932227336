import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";

import { GoConst } from "../../../services-async/vo/services";
import { ServiceClient as ProjectsClient } from "../../../services/projects";
import { ServiceClient as UsersClient } from "../../../services/users";
import {
  Filter,
  Project,
  SketchListing,
  SketchSummary,
  Team,
  User
} from "../../../services/vo/client";
import { PAGES } from "../Page";
import Router from "../router";
import { State } from "../store";
import { actionAddErrorToast } from "../../../reducers/toast";
import { actionSetUserSession } from "./user";

export const LOAD_TEAMS_STATE = "LOAD_TEAMS_STATE";
export const TEAMS_RELOAD = "TEAMS_RELOAD";

export interface TeamsState {
  teams: Team[];
  projects: Project[];
  summaries: SketchSummary[];
  listings: { [key: string]: SketchListing };
}

const PROJECT_PAGE_SIZE = 12;

export const loadTeamsState = (teamsState: TeamsState) => ({
  type: LOAD_TEAMS_STATE,
  teamsState
});

/**
 * fetches session info from server and wraps callback into a promise
 */
export const fetchSession = async (): Promise<{
  user: User;
  teams: Team[];
  projects: Project[];
  sessionID: string;
  env: string;
  err?: any;
}> => {
  return new Promise((resolve, reject) => {
    UsersClient.defaultInst.getSession(
      (user, teams, projects, sessionID, env, err) =>
        resolve({ user, teams, projects, sessionID, env, err }),
      err => reject(err)
    );
  });
};

const fetchProject = async (
  projectId: string,
  filters: Filter[] = [],
  page: number = 0,
  pageSize: number = PROJECT_PAGE_SIZE
): Promise<{ project: Project; listing: SketchListing; err?: any }> => {
  return new Promise((resolve, reject) => {
    ProjectsClient.defaultInst.getProject(
      projectId,
      filters,
      page,
      pageSize,
      (project, listing, err) => {
        return resolve({ project, listing, err });
      },
      () => reject()
    );
  });
};

/**
 * fetch all projects for teams and return an array a map of them
 * @param teams
 */
const projectState = {
  listings: {},
  projects: [],
  summaries: [],
  err: undefined
};

const fetchAllProjects = async (
  teams: Team[]
): Promise<{
  listings: { [index: string]: SketchListing };
  projects: Project[];
  summaries: SketchSummary[];
  err?: any;
}> =>
  new Promise((resolve, reject) => {
    if (!teams || teams.length === 0) {
      resolve(projectState);
    }

    const listings: { [index: string]: SketchListing } = {};
    const projects: Project[] = [];
    const summaries: SketchSummary[] = [];
    let totalProjects = 0;
    let finishedProjects = 0;

    // iterate over all projects inside all teams
    teams.forEach(acc =>
      acc.projectIds.forEach(projectId => {
        // fetch project info
        totalProjects++;
        fetchProject(projectId)
          .then(data => {
            projects.push(data.project);
            listings[projectId] = data.listing;
            summaries.push(...data.listing.summaries);
            // check if this is the last project that had to
            // return
            finishedProjects++;
            if (finishedProjects === totalProjects) {
              resolve({ listings, projects, summaries, err: data.err });
              return;
            }
          })
          .catch(err => {
            console.error("failed to fetch project " + projectId, err);
            reject(err);
          });
      })
    );

    if (totalProjects === 0) {
      resolve(projectState);
    }
  });

/**
 * fetch user session and update all projects, listings and teams
 * in the redux state. If failed a error message will be displayed
 */
export const loadTeamState = (routing: boolean = true) => async (
  dispatch: ThunkDispatch<State, void, any>,
  getState: () => State
) => {
  // get session info & wrap it in promise to keep async context
  try {
    const sessionData = await fetchSession();
    if (sessionData.err) {
      // if we don't have rights to load session we are just not logged in so
      // ignore error
      if (sessionData.err.errorType === GoConst.ErrorRights) {
        return;
      }
      throw new Error("failed to fetch session data" + sessionData.err);
    }
    // refresh artist state
    await dispatch(actionSetUserSession(sessionData));

    // FIXME: set session stuff
    await dispatch(reloadTeamState(sessionData.teams));

    // provide a way to skip routing
    if (routing) {
      const state = getState();
      if (state.router.forbidden) {
        Router.tryForbidden(state.router.forbidden);
      } else if (
        state.page.name === PAGES.home ||
        state.page.name === PAGES.login
      ) {
        // also if we are on / redirect to /
        Router.home();
      }
    }

    return {
      teams: sessionData.teams,
      user: sessionData.user,
      err: sessionData.err
    };
  } catch (e) {
    if (e)
      // ignore access rights error
      console.error("Failed to fetch session:", e);
    dispatch(actionAddErrorToast("ERR_TEAM_TITLE", "ERR_TEAM_MSG", true));
    return {
      teams: null,
      user: null,
      err: e
    };
  }
};

export const reloadTeamState = (
  teams: Team[]
): ThunkAction<Promise<any>, State, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, any>
) => {
  // get session info & wrap it in promise to keep async context
  try {
    const projectData = await fetchAllProjects(teams);
    if (projectData.err) {
      throw new Error("failed to fetch project data" + projectData.err);
    }
    // not update the state
    dispatch(
      loadTeamsState({
        teams: teams,
        // sort projects by name
        projects: projectData.projects.sort(
          (a, b) => 0 - (a.name > b.name ? -1 : 1)
        ),
        listings: projectData.listings,
        summaries: projectData.summaries
      })
    );
  } catch (e) {
    console.error("Failed to fetch session:", e);
    return;
  }

  return;
};

type ActionType = ReturnType<typeof loadTeamsState>;

export const teams = (state: TeamsState | undefined, action: ActionType) => {
  if (state === undefined) {
    return { teams: [], projects: [], summaries: [], listings: {} };
  }
  switch (action.type) {
    case LOAD_TEAMS_STATE:
      return { ...action.teamsState };
    case TEAMS_RELOAD:
  }
  return state;
};
