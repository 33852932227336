/* tslint:disable */
// hello commonjs - we need some imports - sorted in alphabetical order, by go package
import * as github_com_bestbytes_paperroll_server_billing from './vo/billing'; // webpack/src/services/users.ts to webpack/src/services/vo/billing.ts
import * as github_com_bestbytes_paperroll_server_client from './vo/client'; // webpack/src/services/users.ts to webpack/src/services/vo/client.ts
import * as github_com_bestbytes_paperroll_server_protovo from './vo/protovo'; // webpack/src/services/users.ts to webpack/src/services/vo/protovo.ts
import * as github_com_bestbytes_paperroll_server_services from './vo/services'; // webpack/src/services/users.ts to webpack/src/services/vo/services.ts
import * as github_com_bestbytes_paperroll_server_services_dev from './vo/dev'; // webpack/src/services/users.ts to webpack/src/services/vo/dev.ts
import * as github_com_bestbytes_paperroll_server_services_projects from './vo/projects'; // webpack/src/services/users.ts to webpack/src/services/vo/projects.ts
import * as github_com_bestbytes_paperroll_server_services_search from './vo/search'; // webpack/src/services/users.ts to webpack/src/services/vo/search.ts
import * as github_com_bestbytes_paperroll_server_services_subscriptions from './vo/subscriptions'; // webpack/src/services/users.ts to webpack/src/services/vo/subscriptions.ts
import * as github_com_bestbytes_paperroll_server_services_teams from './vo/teams'; // webpack/src/services/users.ts to webpack/src/services/vo/teams.ts
import * as github_com_bestbytes_paperroll_server_services_users from './vo/users'; // webpack/src/services/users.ts to webpack/src/services/vo/users.ts
import * as github_com_stripe_stripe_go from './vo/stripe'; // webpack/src/services/users.ts to webpack/src/services/vo/stripe.ts

export const call = (endPoint:string, method:string, args:any[], success:any, err:any) => {
        var request = new XMLHttpRequest();
        request.withCredentials = true;
        request.open('POST', endPoint + "/" + encodeURIComponent(method), true);
		// this causes problems, when the browser decides to do a cors OPTIONS request
        // request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
        request.send(JSON.stringify(args));
        request.onload = function() {
            if (request.status == 200) {
				try {
					var data = JSON.parse(request.responseText);
				} catch(e) {
	                err(request, e);
				}
				success.apply(null, data);
            } else {
                err(request);
            }
        };
        request.onerror = function() {
            err(request);
        };
    }

export class ServiceClient {
	static defaultInst = new ServiceClient;
	constructor(public endPoint:string = "/service/users", public transport = call) {  }
	addPaymentSource(srcID:string, paymentType:string, fingerprint:string, success:(err:github_com_bestbytes_paperroll_server_services.ServiceError) => void, err:(request:XMLHttpRequest, e?:Error) => void) {
		this.transport(this.endPoint, "AddPaymentSource", [srcID, paymentType, fingerprint], success, err);
	}
	confirmationToken(tokenID:string, success:(email:string, password:string, loginQRLink:string, loginLink:string, err:github_com_bestbytes_paperroll_server_services.ServiceError) => void, err:(request:XMLHttpRequest, e?:Error) => void) {
		this.transport(this.endPoint, "ConfirmationToken", [tokenID], success, err);
	}
	deletePaymentSource(sourceID:string, success:(err:github_com_bestbytes_paperroll_server_services.ServiceError) => void, err:(request:XMLHttpRequest, e?:Error) => void) {
		this.transport(this.endPoint, "DeletePaymentSource", [sourceID], success, err);
	}
	emailIsInUse(email:string, success:(inUse:boolean, err:github_com_bestbytes_paperroll_server_services.ServiceError) => void, err:(request:XMLHttpRequest, e?:Error) => void) {
		this.transport(this.endPoint, "EmailIsInUse", [email], success, err);
	}
	force(success:(ret:github_com_bestbytes_paperroll_server_services_users.Force) => void, err:(request:XMLHttpRequest, e?:Error) => void) {
		this.transport(this.endPoint, "Force", [], success, err);
	}
	getAllUsers(success:(users:github_com_bestbytes_paperroll_server_client.Info[], err:github_com_bestbytes_paperroll_server_services.ServiceError) => void, err:(request:XMLHttpRequest, e?:Error) => void) {
		this.transport(this.endPoint, "GetAllUsers", [], success, err);
	}
	getAvailablePaymentSources(success:(sources:github_com_bestbytes_paperroll_server_services_users.PaymentInfo[], err:github_com_bestbytes_paperroll_server_services.ServiceError) => void, err:(request:XMLHttpRequest, e?:Error) => void) {
		this.transport(this.endPoint, "GetAvailablePaymentSources", [], success, err);
	}
	getLoginToken(uri:string, success:(tokenID:string, err:github_com_bestbytes_paperroll_server_services.ServiceError) => void, err:(request:XMLHttpRequest, e?:Error) => void) {
		this.transport(this.endPoint, "GetLoginToken", [uri], success, err);
	}
	getMySketches(success:(summaries:github_com_bestbytes_paperroll_server_client.SketchSummary[], projects:github_com_bestbytes_paperroll_server_client.Project[], err:github_com_bestbytes_paperroll_server_services.ServiceError) => void, err:(request:XMLHttpRequest, e?:Error) => void) {
		this.transport(this.endPoint, "GetMySketches", [], success, err);
	}
	getProjectUsers(projectID:string, success:(infos:github_com_bestbytes_paperroll_server_client.Info[], err:github_com_bestbytes_paperroll_server_services.ServiceError) => void, err:(request:XMLHttpRequest, e?:Error) => void) {
		this.transport(this.endPoint, "GetProjectUsers", [projectID], success, err);
	}
	getSession(success:(user:github_com_bestbytes_paperroll_server_client.User, teams:github_com_bestbytes_paperroll_server_client.Team[], projects:github_com_bestbytes_paperroll_server_client.Project[], sessionID:string, env:string, err:github_com_bestbytes_paperroll_server_services.ServiceError) => void, err:(request:XMLHttpRequest, e?:Error) => void) {
		this.transport(this.endPoint, "GetSession", [], success, err);
	}
	getStripeUser(success:(stripeID:string, err:github_com_bestbytes_paperroll_server_services.ServiceError) => void, err:(request:XMLHttpRequest, e?:Error) => void) {
		this.transport(this.endPoint, "GetStripeUser", [], success, err);
	}
	getTeamUsers(teamID:string, success:(infos:github_com_bestbytes_paperroll_server_client.Info[], err:github_com_bestbytes_paperroll_server_services.ServiceError) => void, err:(request:XMLHttpRequest, e?:Error) => void) {
		this.transport(this.endPoint, "GetTeamUsers", [teamID], success, err);
	}
	getUserAndSketches(userID:string, filters:github_com_bestbytes_paperroll_server_client.Filter[], page:number, pageSize:number, success:(user:github_com_bestbytes_paperroll_server_client.Info, listing:github_com_bestbytes_paperroll_server_client.SketchListing, err:github_com_bestbytes_paperroll_server_services.ServiceError) => void, err:(request:XMLHttpRequest, e?:Error) => void) {
		this.transport(this.endPoint, "GetUserAndSketches", [userID, filters, page, pageSize], success, err);
	}
	invite(email:string, teamID:string, projectIDs:string[], success:(err:github_com_bestbytes_paperroll_server_services.ServiceError) => void, err:(request:XMLHttpRequest, e?:Error) => void) {
		this.transport(this.endPoint, "Invite", [email, teamID, projectIDs], success, err);
	}
	inviteToken(tokenID:string, success:(email:string, password:string, userExists:boolean, err:github_com_bestbytes_paperroll_server_services.ServiceError) => void, err:(request:XMLHttpRequest, e?:Error) => void) {
		this.transport(this.endPoint, "InviteToken", [tokenID], success, err);
	}
	invited(email:string, success:(invited:boolean, err:github_com_bestbytes_paperroll_server_services.ServiceError) => void, err:(request:XMLHttpRequest, e?:Error) => void) {
		this.transport(this.endPoint, "Invited", [email], success, err);
	}
	login(email:string, password:string, success:(user:github_com_bestbytes_paperroll_server_client.User, teams:github_com_bestbytes_paperroll_server_client.Team[], projects:github_com_bestbytes_paperroll_server_client.Project[], sessionID:string, env:string, err:github_com_bestbytes_paperroll_server_services.ServiceError) => void, err:(request:XMLHttpRequest, e?:Error) => void) {
		this.transport(this.endPoint, "Login", [email, password], success, err);
	}
	logout(success:(err:github_com_bestbytes_paperroll_server_services.ServiceError) => void, err:(request:XMLHttpRequest, e?:Error) => void) {
		this.transport(this.endPoint, "Logout", [], success, err);
	}
	promoteUserToProjectMember(userID:string, projectID:string, success:(err:github_com_bestbytes_paperroll_server_services.ServiceError) => void, err:(request:XMLHttpRequest, e?:Error) => void) {
		this.transport(this.endPoint, "PromoteUserToProjectMember", [userID, projectID], success, err);
	}
	promoteUserToProjectOwner(userID:string, projectID:string, success:(err:github_com_bestbytes_paperroll_server_services.ServiceError) => void, err:(request:XMLHttpRequest, e?:Error) => void) {
		this.transport(this.endPoint, "PromoteUserToProjectOwner", [userID, projectID], success, err);
	}
	registerv2(email:string, password:string, success:(user:github_com_bestbytes_paperroll_server_client.User, teams:github_com_bestbytes_paperroll_server_client.Team[], projects:github_com_bestbytes_paperroll_server_client.Project[], sessionID:string, env:string, err:github_com_bestbytes_paperroll_server_services.ServiceError) => void, err:(request:XMLHttpRequest, e?:Error) => void) {
		this.transport(this.endPoint, "Registerv2", [email, password], success, err);
	}
	resetPassword(email:string, success:(err:github_com_bestbytes_paperroll_server_services.ServiceError) => void, err:(request:XMLHttpRequest, e?:Error) => void) {
		this.transport(this.endPoint, "ResetPassword", [email], success, err);
	}
	resetToken(tokenID:string, success:(email:string, password:string, err:github_com_bestbytes_paperroll_server_services.ServiceError) => void, err:(request:XMLHttpRequest, e?:Error) => void) {
		this.transport(this.endPoint, "ResetToken", [tokenID], success, err);
	}
	setPasswordAndLogin(email:string, password:string, newPassword:string, success:(user:github_com_bestbytes_paperroll_server_client.User, teams:github_com_bestbytes_paperroll_server_client.Team[], projects:github_com_bestbytes_paperroll_server_client.Project[], sessionID:string, env:string, err:github_com_bestbytes_paperroll_server_services.ServiceError) => void, err:(request:XMLHttpRequest, e?:Error) => void) {
		this.transport(this.endPoint, "SetPasswordAndLogin", [email, password, newPassword], success, err);
	}
	update(name:string, firstName:string, lastName:string, salutation:string, password:string, description:string, tags:string[], success:(err:github_com_bestbytes_paperroll_server_services.ServiceError) => void, err:(request:XMLHttpRequest, e?:Error) => void) {
		this.transport(this.endPoint, "Update", [name, firstName, lastName, salutation, password, description, tags], success, err);
	}
	useLoginToken(tokenID:string, success:(ret:string, ret_1:string, ret_2:github_com_bestbytes_paperroll_server_services.ServiceError) => void, err:(request:XMLHttpRequest, e?:Error) => void) {
		this.transport(this.endPoint, "UseLoginToken", [tokenID], success, err);
	}
}