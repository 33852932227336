import * as React from "react";

export class ContactPage extends React.Component<{}> {
  render() {
    return (
      <div>
        <h1>Contact</h1>
        <p>This page is comming soon</p>
      </div>
    );
  }
}
