/* tslint:disable */
// hello commonjs - we need some imports - sorted in alphabetical order, by go package
import * as github_com_bestbytes_paperroll_server_billing from './../services/vo/billing'; // webpack/src/services-async/teams.ts to webpack/src/services/vo/billing.ts
import * as github_com_bestbytes_paperroll_server_client from './vo/client'; // webpack/src/services-async/teams.ts to webpack/src/services-async/vo/client.ts
import * as github_com_bestbytes_paperroll_server_relay from './vo/relay'; // webpack/src/services-async/teams.ts to webpack/src/services-async/vo/relay.ts
import * as github_com_bestbytes_paperroll_server_services from './vo/services'; // webpack/src/services-async/teams.ts to webpack/src/services-async/vo/services.ts
import * as github_com_bestbytes_paperroll_server_services_admin from './vo/admin'; // webpack/src/services-async/teams.ts to webpack/src/services-async/vo/admin.ts
import * as github_com_bestbytes_paperroll_server_services_projects from './vo/projects'; // webpack/src/services-async/teams.ts to webpack/src/services-async/vo/projects.ts
import * as github_com_bestbytes_paperroll_server_services_search from './vo/search'; // webpack/src/services-async/teams.ts to webpack/src/services-async/vo/search.ts
import * as github_com_bestbytes_paperroll_server_services_subscriptions from './../services/vo/subscriptions'; // webpack/src/services-async/teams.ts to webpack/src/services/vo/subscriptions.ts
import * as github_com_bestbytes_paperroll_server_services_teams from './vo/teams'; // webpack/src/services-async/teams.ts to webpack/src/services-async/vo/teams.ts
import * as github_com_bestbytes_paperroll_server_services_users from './vo/users'; // webpack/src/services-async/teams.ts to webpack/src/services-async/vo/users.ts
import * as github_com_stripe_stripe_go from './vo/stripe'; // webpack/src/services-async/teams.ts to webpack/src/services-async/vo/stripe.ts

export class ServiceClient {
	public static defaultEndpoint = "/service/teams";
	constructor(
		public transport:<T>(method: string, data?: any[]) => Promise<T>
	) {}
	async createFirstTeam(name:string):Promise<github_com_bestbytes_paperroll_server_client.Team> {
		let response = await this.transport<{0:github_com_bestbytes_paperroll_server_client.Team; 1:github_com_bestbytes_paperroll_server_services.ServiceError}>("CreateFirstTeam", [name])
		let err = response[1];
		if(err) { throw err }
		return response[0]
	}
	async createTeam(name:string, description:string, tags:string[], plan:string):Promise<github_com_bestbytes_paperroll_server_client.Team> {
		let response = await this.transport<{0:github_com_bestbytes_paperroll_server_client.Team; 1:github_com_bestbytes_paperroll_server_services.ServiceError}>("CreateTeam", [name, description, tags, plan])
		let err = response[1];
		if(err) { throw err }
		return response[0]
	}
	async force():Promise<github_com_bestbytes_paperroll_server_services_teams.Force> {
		return (await this.transport<{0:github_com_bestbytes_paperroll_server_services_teams.Force}>("Force", []))[0]
	}
	async removeTeam(teamID:string):Promise<void> {
		let response = await this.transport<{0:github_com_bestbytes_paperroll_server_services.ServiceError}>("RemoveTeam", [teamID])
		let err = response[0];
		if(err) { throw err }
	}
	async removeUserFromTeam(teamID:string, userID:string):Promise<void> {
		let response = await this.transport<{0:github_com_bestbytes_paperroll_server_services.ServiceError}>("RemoveUserFromTeam", [teamID, userID])
		let err = response[0];
		if(err) { throw err }
	}
	async updateTeam(teamID:string, name:string, description:string, tags:string[], plan:string):Promise<void> {
		let response = await this.transport<{0:github_com_bestbytes_paperroll_server_services.ServiceError}>("UpdateTeam", [teamID, name, description, tags, plan])
		let err = response[0];
		if(err) { throw err }
	}
}