import * as React from "react";
import { Card, Image } from "semantic-ui-react";
import { PaymentInfo } from "../../../../services-async/vo/users";

export function getPaymentIconPathForBrand(brand: string): string {
  let imgFileName = brand.toLowerCase();

  // Check for special icon paths
  switch (imgFileName) {
    case "american express":
      imgFileName = "amex";
      break;
  }
  return "/img/payment-icons/" + imgFileName + ".png";
}

export const PaymentSourceCard = (props: {
  payment: PaymentInfo;
  raised?: boolean;
  onClick?: (info: PaymentInfo) => void;
}) => (
  <Card
    raised={props.raised}
    onClick={() => {
      if (props.onClick) {
        props.onClick(props.payment);
      }
    }}
  >
    <Card.Content>
      <Image
        floated="right"
        size="tiny"
        src={getPaymentIconPathForBrand(props.payment.brand)}
      />
      <Card.Header>{props.payment.brand}</Card.Header>
      <Card.Meta>
        {props.payment.expMonth} / {props.payment.expYear}
      </Card.Meta>
      <Card.Description>
        <strong>**** **** **** {props.payment.last4}</strong>
      </Card.Description>
    </Card.Content>
  </Card>
);
