import * as React from "react";
import { Menu } from "semantic-ui-react";

import { TeamSketchLogo } from "../../../../components/TeamSketchLogo";
import { PAGES } from "../../Page";

export const Logo: React.FC<{
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  onMouseOver?: () => void;
  page: string;
}> = props => (
  <Menu.Item
    fitted
    onMouseOver={props.onMouseOver}
    key="LogoMobile"
    active={props.page === PAGES.home || props.page === PAGES.teams}
    onClick={props.onClick}
  >
    <TeamSketchLogo />
  </Menu.Item>
);

Logo.displayName = "Logo";
