import * as GeneralActions from "../../../actions";
import { toaster } from "../../../reducers/toast";
import { ServiceClient as ProjectsClient } from "../../../services/projects";
import * as Actions from "../actions";
import * as Middleware from "../middleware";
import { PAGES } from "../Page";
import Router from "../router";
import { State } from "../store";

export const projectsMiddleware = (store: any) => (next: any) => (
  action: any
) => {
  const { info } = toaster(store.dispatch);
  let st: State = store.getState();
  switch (action.type) {
    case Actions.EDIT_PROJECT:
      st.teams.projects.map(project => {
        if (project.id === action.id) {
          action.project = project;
        }
        return project;
      });
      GeneralActions.setPage(PAGES.editProject);
      break;
    case Actions.DELETE_PROJECT:
      ProjectsClient.defaultInst.removeProject(
        action.projectId,
        () => {
          info("project was deleted");
          Middleware.reload(store.dispatch, () =>
            Router.editTeam(action.accountId)
          );
        },
        Middleware.handleErr(store.dispatch)
      );
      break;
  }
  return next(action);
};
