import * as React from "react";

import { FilterOption } from "../../../services/vo/client";
import UserCircle from "./UserCircle";
import { styled, SelectionMode } from "../../../theme";
import {
  StyledUserCircle,
  StyledAddCircle
} from "../../../components/UserCircle";
import Router from "../router";
import { Dropdown } from "semantic-ui-react";
import { PAGES } from "../Page";

export const StyledUserList = styled.div<{ inline?: boolean }>`
  ${props => props.inline && `display: inline-block;`}
  ${StyledUserCircle} {
    margin-right: 10px;
    margin-bottom: 5px;
  }
`;

const StyledUserListItem = styled.a`
  display: block;
  padding: 7px;
  color: #000;

  &:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }
`;

const ExpandedUsesList: React.FC<{
  trankLength: number;
  users: FilterOption[];
  size?: number;
}> = props => {
  const dropdownContent = props.users.map(user => (
    <StyledUserListItem
      key={user.value!}
      href={`/${PAGES.user}/${user.value}`}
      onClick={e => {
        e.preventDefault();
        Router.user(user.value);
      }}
    >
      <UserCircle
        noPopup
        size={props.size ? props.size : 25}
        name={user.name!}
        id={user.value!}
      />
      {user.name}
    </StyledUserListItem>
  ));

  return (
    <Dropdown
      basic
      icon={null}
      // options={dropdownContent}
      trigger={
        <StyledUserCircle size={40}>{"+" + props.trankLength}</StyledUserCircle>
      }
    >
      <Dropdown.Menu>{dropdownContent}</Dropdown.Menu>
    </Dropdown>
  );
};

interface UserListProps {
  users?: FilterOption[];
  limit?: number;
  addUserButton?: boolean;
  inline?: boolean;
  size?: number;
  className?: string;
  onUserClick?: (option: FilterOption) => void;
}

export const UserList: React.FC<UserListProps> = ({
  users,
  limit,
  addUserButton,
  inline,
  size,
  className,
  onUserClick
}) => {
  const displayLimit = limit ? limit : 7;
  const trankUserList =
    users.length > displayLimit + 1 ? users.slice(0, displayLimit) : users;
  // check if user list is trankated
  const trankLength = users.length - trankUserList.length;
  let userRemainderList: FilterOption[] = [];
  if (trankLength > 0) {
    userRemainderList = users.slice(-trankLength);
  }
  return (
    <StyledUserList className={className} inline={inline}>
      {trankUserList.map(user => (
        <UserCircle
          mode={
            user.enabled === false
              ? SelectionMode.disabled
              : user.selected
              ? SelectionMode.selected
              : undefined
          }
          size={size}
          onClick={onUserClick ? () => onUserClick(user) : undefined}
          key={user.value!}
          name={user.name!}
          id={user.value!}
        />
      ))}
      {trankLength !== 0 && (
        <ExpandedUsesList users={userRemainderList} trankLength={trankLength} />
      )}
      {addUserButton && (
        <StyledAddCircle size={size ? size : 40}>{"+"}</StyledAddCircle>
      )}
    </StyledUserList>
  );
};
