import * as React from "react";
import { Button, Divider, Grid, Header, List, Table } from "semantic-ui-react";
import { Link } from "../../../components/link";
import { Section } from "../../../components/section";
import { Spacer, SpacerSize } from "../../../components/spacer";
import {
  ContentImage,
  PageContainer,
  SectionDivider,
  styled,
  theme
} from "../../../theme";
import Router from "../router";

const StyledListItem = styled(List.Item)`
  padding: 0 !important;
  font-weight: 400;
  margin-top: 10px;
  font-size: 1.2rem;
  margin-bottom: 10px;
`;

const StyledSmallerText = styled.span`
  font-weight: 300;
  font-size: ${theme.fontSize};
`;

export const StyledDivider = styled(Divider)`
  margin: 30px 0 30px !important;
`;

export const PricingPage = (props: {}) => {
  return (
    <div>
      <Section>
        <PageContainer>
          <Grid verticalAlign="middle">
            <Grid.Row>
              <Grid.Column
                mobile={null}
                tablet={1}
                computer={2}
                widescreen={3}
              />
              <Grid.Column mobile={16} tablet={7} computer={6} widescreen={5}>
                <Header
                  as="h1"
                  content="Break free from traditional limitations."
                />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={7} computer={6} widescreen={5}>
                <div>
                  <ContentImage src="/img/getstarted-head.jpg" />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </PageContainer>
      </Section>
      <Section>
        <PageContainer>
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={1} tablet={1} computer={2} widescreen={3} />
              <Grid.Column mobile={14} tablet={14} computer={8} widescreen={6}>
                <Header as="h2" content="Our Pricing-table" />
                <p>
                  Our pricing is still work in progress. Have a special
                  requirement? Please let us know!
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column mobile={1} tablet={1} computer={2} widescreen={3} />
              <Grid.Column
                mobile={15}
                tablet={14}
                computer={12}
                widescreen={10}
              >
                <Spacer size={SpacerSize.tiny} />
                <Table celled fixed padded="very">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>
                        <Header as="h3" content="Single license" />
                        <StyledDivider />
                        <List>
                          <StyledListItem>1 User</StyledListItem>
                          <StyledListItem>
                            10 GB storage per User
                            <br />
                            <StyledSmallerText>
                              (~ 2000 sketches)
                            </StyledSmallerText>
                          </StyledListItem>
                          <StyledListItem>50 €/Month</StyledListItem>
                        </List>
                        <Spacer size={SpacerSize.small} />
                        <Button primary onClick={e => alert("-> ?")}>
                          Buy Single license
                        </Button>
                        <Spacer size={SpacerSize.tiny} />
                      </Table.Cell>

                      <Table.Cell>
                        <Header as="h3" content="Small-Team license" />
                        <StyledDivider />
                        <StyledListItem>3 User</StyledListItem>
                        <StyledListItem>
                          10 GB storage per User
                          <br />
                          <StyledSmallerText>
                            (~ 2000 sketches)
                          </StyledSmallerText>
                        </StyledListItem>
                        <StyledListItem>150 €/Month</StyledListItem>
                        <Spacer size={SpacerSize.small} />
                        <Button primary onClick={e => alert("-> ?")}>
                          Buy Small-Team license
                        </Button>
                        <Spacer size={SpacerSize.tiny} />
                      </Table.Cell>

                      <Table.Cell>
                        <Header as="h3" content="Team license" />
                        <StyledDivider />
                        <StyledListItem>10 User</StyledListItem>
                        <StyledListItem>
                          10 GB storage per User
                          <br />
                          <StyledSmallerText>
                            (~ 2000 sketches)
                          </StyledSmallerText>
                        </StyledListItem>
                        <StyledListItem>500 €/Month</StyledListItem>
                        <Spacer size={SpacerSize.small} />
                        <Button primary onClick={e => alert("-> ?")}>
                          Buy Team license
                        </Button>
                        <Spacer size={SpacerSize.tiny} />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell colSpan="3" textAlign="center">
                        <strong>
                          For larger Teams, more than 10 users, please contact
                          us:
                        </strong>
                        <Link onClick={e => alert("-> ?")}>
                          large-teams@teamsketch.app
                        </Link>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                <Spacer size={SpacerSize.tiny} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column mobile={1} tablet={1} computer={2} widescreen={3} />
              <Grid.Column mobile={14} tablet={14} computer={8} widescreen={6}>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                  dapibus, tellus ac cursus commodo, tortor mauris condimentum
                  nibh, ut fermentum massa justo sit amet risus. Sed posuere
                  consectetur est at lobortis.
                </p>
                <p>
                  Maecenas sed diam eget risus varius blandit sit amet non
                  magna. Morbi leo risus, porta ac consectetur ac, vestibulum at
                  eros. Aenean eu leo quam. Pellentesque ornare sem lacinia quam
                  venenatis vestibulum.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </PageContainer>
      </Section>

      <PageContainer>
        <SectionDivider />
      </PageContainer>

      <Section>
        <PageContainer>
          <Grid stackable>
            <Grid.Row columns={3}>
              <Grid.Column>
                <Header as="h2" content="More TeamSketch facts." />
                <Spacer size={SpacerSize.small} />
              </Grid.Column>
              <Grid.Column>
                <Header as="h3" content="Get started" />
                <p>Start using TeamSketch today!</p>
                <Button basic onClick={e => Router.getstarted()}>
                  Get started
                </Button>
              </Grid.Column>
              <Grid.Column>
                <Header as="h3" content="FAQ" />
                <p>Have a Question?</p>
                <Button basic onClick={e => Router.faq()}>
                  FAQ
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </PageContainer>
      </Section>
    </div>
  );
};
