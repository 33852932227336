import * as React from "react";
import { Button, Header, Image, Loader, Table } from "semantic-ui-react";
import { Spacer, SpacerSize } from "../../../../components/spacer";
import { PaymentInfo } from "../../../../services-async/vo/users";
import { getPaymentIconPathForBrand } from "./PaymentSourceCard";
import {
  withStripeSources,
  PaymentSourceViewInterface
} from "./paymentSourceInjector";
import { useTranslation } from "react-i18next";

const PaymentSourceList: React.FC<{
  sources: PaymentInfo[];
  onDelete?: (id: string) => void;
}> = ({ sources, onDelete }) => {
  if (!sources) {
    return (
      <Table.Row>
        <Table.Cell>No items found</Table.Cell>
      </Table.Row>
    );
  }
  return (
    <>
      {sources.map(source => {
        if (!source) {
          return (
            <Table.Row key={0}>
              <Header.Content>Something is very wrong</Header.Content>
            </Table.Row>
          );
        }
        switch (source.type) {
          case "card":
            return (
              <Table.Row key={source.id}>
                <Table.Cell textAlign="left">
                  <Header as="h4" image>
                    <Image
                      src={getPaymentIconPathForBrand(source.brand)}
                      size="medium"
                    />
                    <Header.Content>{source.brand}</Header.Content>
                  </Header>
                </Table.Cell>
                <Table.Cell textAlign="left">
                  **** **** **** {source.last4}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  {source.expMonth} / {source.expYear}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  <Button onClick={() => onDelete && onDelete(source.id)}>
                    Remove
                  </Button>
                </Table.Cell>
              </Table.Row>
            );
          default:
            return <Table.Row />;
        }
      })}
    </>
  );
};

const PaymentSourcesTable: React.FC<PaymentSourceViewInterface> = props => {
  const { t } = useTranslation();
  return (
    <div>
      <Header as="h3" content="Payment Methods" />
      {props.sources === null ? (
        <>
          <p>{t("NO_PAYMENT_METHODS")}</p>
          <Spacer size={SpacerSize.tiny} />
        </>
      ) : props.sources !== undefined ? (
        <Table basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign="left">Type</Table.HeaderCell>
              <Table.HeaderCell textAlign="left">Card Number</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                Expiration Date
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <PaymentSourceList
              sources={props.sources}
              onDelete={props.onDelete}
            />
          </Table.Body>
        </Table>
      ) : (
        <>
          <Spacer />
          <Loader active inline="centered" />
          <Spacer />
        </>
      )}
    </div>
  );
};

export default withStripeSources(PaymentSourcesTable);
