import * as React from "react";
import { Divider, Loader, Menu, Responsive } from "semantic-ui-react";

import { Spacer, SpacerSize } from "../../../../components/spacer";
import { styled } from "../../../../theme";
import { PAGES } from "../../Page";
import Router from "../../router";
import { MenuContent, StyledMobileMenuItem } from "./components";
import { TeamsMenuContent } from "./MainMenuDesktopContents";

const StyledQuickMenu = styled.div``;

const HeaderLoaderWrapper = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center; */
  height: 250px;
  position: relative;
`;

const MenuContentItems: React.FC<{
  page: string;
  pricing: boolean;
}> = props => {
  return (
    <>
      <StyledMobileMenuItem
        onClick={Router.downloads}
        active={props.page === PAGES.downloads}
        content="Downloads"
      />
      <StyledMobileMenuItem
        key="mmgetstarted"
        onClick={Router.getstarted}
        active={props.page === PAGES.getstarted}
        content="Get started"
      />
      {props.pricing && (
        <StyledMobileMenuItem
          key="mmpricing"
          onClick={Router.pricing}
          active={props.page === PAGES.pricing}
          content="Pricing"
        />
      )}
      <StyledMobileMenuItem
        key="mmfaq"
        onClick={Router.faq}
        active={props.page === PAGES.faq}
        content="FAQ"
      />
    </>
  );
};

export const QuickMenu: React.FC<
  {
    isLoggedIn: boolean;
    pricing: boolean;
    page: string;
  } & MenuContent
> = props => (
  <StyledQuickMenu onClick={props.closeMenu}>
    {props.isLoggedIn && (
      <>
        {props.items ? (
          <TeamsMenuContent {...props} />
        ) : (
          <HeaderLoaderWrapper>
            <Loader active />
          </HeaderLoaderWrapper>
        )}
        <Spacer size={SpacerSize.tiny} />
        <Divider />
      </>
    )}
    <div>
      <Responsive maxWidth="767" as={Menu} text vertical>
        <MenuContentItems page={props.page} pricing={props.pricing}/>
      </Responsive>
      <Responsive minWidth="768" as={Menu} text>
        <MenuContentItems page={props.page} pricing={props.pricing}/>
      </Responsive>
    </div>
  </StyledQuickMenu>
);

QuickMenu.displayName = "QuickMenu";
