import {Dispatch, Store} from 'redux';

import {ServiceClient as UsersClient} from '../../../services/users';
import * as Actions from '../actions';
import * as Middleware from '../middleware';
import {EDIT_ACCOUNT_INIT} from '../reducers/editAccount';
import {LOAD_TEAMS_STATE} from '../reducers/teams';
import {State} from '../store';

const loadUsers = (dispatch: Dispatch) => {
  UsersClient.defaultInst.getAllUsers((users, err) => {
    if (err) {
      console.warn(err);
      return;
    }
    Actions.loadAllUserInfos(users);
  }, Middleware.handleErr(dispatch));
};

export const allUsersMiddleware = (store: Store<State>) => (next: any) =>
    (action: any) => {
      switch (action.type) {
        case LOAD_TEAMS_STATE:
          loadUsers(store.dispatch);
          break;
        case EDIT_ACCOUNT_INIT:
          loadUsers(store.dispatch);
          break;
      }
      return next(action);
    };
