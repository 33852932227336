import * as React from "react";
import { connect } from "react-redux";
import { List } from "semantic-ui-react";

import { PAGES } from "../Page";
import { styled, theme } from "../../../theme";
import Router from "../router";
import { State } from "../store";
import { version } from "../../../env";

const active = (props: { state: State }, page: string) =>
  props.state.page.name === page;

const StyledFooter = styled.div<{ visible: boolean }>`
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  background-color: rgba(255, 255, 255, 0.90);

  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  /* height: 2rem; */
  padding: 0 !important;
  border-top: 1px solid ${theme.borderColor};
  font-size: 10px;
  .env {
    margin: 0;
    font-family: monospace;
  }
  @media print {
    display: none;
  }
  @media (max-width: 767px) {
    .column {
      text-align: center !important;
    }
  }
`;

const StyledListItem = styled(List.Item)`
  display: inline-block;
  padding: 0 .5rem !important;
  color: ${theme.bodyFontColor} !important;
  &:hover,
  &.active {
    color: ${theme.linkColor} !important;
  }

  &:last-child {
    margin-right: -1rem !important;
  }

  @media (min-width: 1024px) {
    &:last-child {
      margin-right: -1.5rem !important;
    }
  }
`;

const Footer = (props: { state: State }) => (
  <StyledFooter visible={props.state.client.showFooter}>
    <List horizontal>
      <StyledListItem
        as="a"
        onClick={() => Router.legal()}
        active={active(props, PAGES.legal)}
      >
        Legal
      </StyledListItem>
      <StyledListItem
        as="a"
        onClick={() => Router.imprint()}
        active={active(props, PAGES.imprint)}
      >
        Imprint
      </StyledListItem>
      <StyledListItem as="code">v{version}</StyledListItem>
    </List>
  </StyledFooter>
);

Footer.displayName = "Footer";

const mapStateToProps = (state: State) => ({ state });

export default connect(mapStateToProps)(Footer);
