import * as React from "react";

import { PAGES } from "../../Page";
import { StyleMenuItemList } from "./StyledMenuItemList";
import Router from "../../router";

const LoggedOutMenu: React.FC<{
  onHideList: () => void;
  currentPage: string;
}> = ({ onHideList, currentPage }) => (
  <StyleMenuItemList
    key="menu-right-logged-out"
    items={[
      {
        label: "Login",
        onClick: Router.login,
        onHideList,
        active: currentPage === PAGES.login
      },
      {
        label: "Sign up",
        onClick: Router.signup,
        onHideList,
        active: currentPage === PAGES.signup
      }
    ]}
  />
);

export default LoggedOutMenu;
