import * as React from "react";
import { styled, theme } from "../theme";

const StyledLink = styled.a<{active: boolean;disabled?: boolean;}>`
  cursor: pointer;
  display: inline-block;
  padding: 0.5rem 1rem !important;
  font-weight: bold !important;
  color: ${theme.linkColor} !important;
  &:hover {
    color: ${theme.activeLinkColor} !important;
  }
  ${props =>
    props.active ? `
    color: ${theme.activeLinkColor} !important;
      
      ` : `
      `}


  ${props =>
    props.disabled
      ? `
    cursor: not-allowed;
    color: #CCC !important;
    &:hover {
      color: #CCC !important;
    }
  `
      : ""}

  @media (min-width: 1024px) {
    padding: 0.5rem 1.5rem !important;
  }
`;

export const Link = (props: {
  title?: string;
  active?: boolean;
  className?: string;
  onClick: (e) => void;
  children;
  disabled?: boolean;
}) => (
  <StyledLink
    active={props.active}
    title={props.title}
    onClick={props.onClick}
    disabled={props.disabled}
    className={props.className}
  >
    {props.children}
  </StyledLink>
);

Link.displayName = "Link";
