import * as React from "react";

import InfinityScroll from "../../../../components/InfinityScroller";
import { SketchListing } from "../../../../services-async/vo/client";
import { SketchSelection } from "../../reducers/sketchSelection";
import SketchMasonryGrid from "../SketchGrid/SketchGrid";

export interface SketchListPagerProps {
  listing: SketchListing | null;
  nextPageLoader?: () => void;
  selection?: SketchSelection;
  emptyHint?: React.ReactChild;
}

export const SketchInifinityScroller: React.FC<
  SketchListPagerProps
> = props => {
  const hasNextPage = props.listing && props.listing.page < props.listing.pages;
  return (
    <InfinityScroll
      onReachedBoundary={() =>
        hasNextPage && props.nextPageLoader && props.nextPageLoader()
      }
    >
      <SketchMasonryGrid
        selection={props.selection}
        sketches={props.listing && props.listing.summaries}
        emptyHint={props.emptyHint}
      />
    </InfinityScroll>
  );
};

SketchInifinityScroller.displayName = "SketchInifinityScroller";
export default SketchInifinityScroller;
