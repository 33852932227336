import * as React from "react";
import { Menu, Responsive } from "semantic-ui-react";

import { PAGES } from "../../Page";
import Router from "../../router";
import { Logo } from "./Logo";
import { MenuTab } from "./siteMenu";
import { StyleMenuItemList } from "./StyledMenuItemList";

const notLoggedInMenuItems = (page: string, addPricing:boolean) => {
  const items =  [{
    active: page === PAGES.getstarted,
    label: "Get Started",
    onClick: Router.getstarted
  }];
  if(addPricing) {
      items.push(  {
        active: page === PAGES.pricing,
        label: "Pricing",
        onClick: Router.pricing
      });
  }
  items.push({
    active: page === PAGES.faq,
    label: "FAQ",
    onClick: Router.faq
  })
  return items;
};

/**
 * Topbar menu to the left with logo
 */
const TopBarMenuLeft: React.FC<{
  loggedIn: boolean;
  page: string;
  menuTab: MenuTab;
  pricing:boolean;
  onLogoClick: () => void;
  // hover or click on menu
  onLogoHover: () => void;
}> = props => (
  <>
    <Responsive key="main-menu-responsive" maxWidth="767" as={Menu.Menu}>
      <Logo
        onMouseOver={props.onLogoHover}
        onClick={props.onLogoClick}
        page={props.page}
      />
    </Responsive>
    <Responsive minWidth="768" as={Menu.Menu} key="logo-responsive">
      <Logo
        onMouseOver={props.onLogoHover}
        onClick={props.onLogoClick}
        page={props.page}
      />
      {!props.loggedIn && (
        <StyleMenuItemList items={notLoggedInMenuItems(props.page, props.pricing)} />
      )}
    </Responsive>
  </>
);

TopBarMenuLeft.displayName = "MainMenuDesktop";

export default TopBarMenuLeft;
