import { Project } from "../../../services/vo/client";
import * as Actions from "../actions";

export const projects = (state: Project[], action: any) => {
  if (state === undefined) {
    return [];
  }
  switch (action.type) {
    case Actions.LOAD_PROJECTS:
      return action.projects;
  }
  return state;
};
