import * as Form from "../../../form";
import { User } from "../../../services/vo/client";
import { UserSession } from "./user";
import { actionCreator } from "../store";

export const ARTIST_SET_FIELD = "ARTIST_SET_FIELD";
export const ARTIST_FORM_ID = "ARTIST_FORM_ID";
export const SIGNUP_EMAIL_IS_IN_USE = "SIGNUP_EMAIL_IS_IN_USE";
export const ACTION_INVITED = "ACTION_INVITED";
export const ACTION_SET_USER = "ACTION_SET_USER";
export const ACTION_SET_USER_FORM_VALUE = "ACTION_SET_USER_FORM_VALUE";

export interface ArtistState {
  // null = not logged in
  // undefined = not checked yet
  artist: User | null | undefined;
  formData: Form.FormData;
  complete: boolean;
  emailIsInUse: boolean;
}

interface ActionSetField {
  type: typeof ARTIST_SET_FIELD;
  field: string;
  value: string;
}

interface ActionSignumEmailIsInUse {
  type: typeof SIGNUP_EMAIL_IS_IN_USE;
  emailIsInUse: boolean;
}

interface ActionInvited {
  type: typeof ACTION_INVITED;
  invited: boolean;
}

/**
 * Action Creator
 */
export const artistSetField = (field: string, value: string) => ({
  type: ARTIST_SET_FIELD,
  field,
  value
});

export const signupEmailIsInUse = (emailIsInUse: boolean) => ({
  type: SIGNUP_EMAIL_IS_IN_USE,
  emailIsInUse
});

export const actionUserFormUpdateSession = (session?: UserSession) =>
  actionCreator(ACTION_SET_USER, session);

export const actionUserFormSetFormValue = (key: string, value: string) =>
  actionCreator(ACTION_SET_USER_FORM_VALUE, { key, value });

type ActionType =
  | ActionSetField
  | ActionSignumEmailIsInUse
  | ActionInvited
  | ReturnType<
      typeof actionUserFormUpdateSession | typeof actionUserFormSetFormValue
    >;

export const initialState = (session?: UserSession): ArtistState => ({
  artist: undefined,
  complete: false,
  emailIsInUse: false,
  formData: Form.formInputDataWithValues(ARTIST_FORM_ID, {
    userName: session.user ? session.user.name : "",
    firstName: session.user ? session.user.firstName : "",
    description: session.user ? session.user.description : "",
    salutation: session.user ? session.user.salutation : "",
    lastName: session.user ? session.user.lastName : "",
    password: "",
    email: session.user ? session.user.email : ""
  })
});

const userProfileFormReducer = (
  state: ArtistState = initialState(),
  action: ActionType
): ArtistState => {
  let validator!: Form.FormValidator, formReducer;
  const resetForm = (state: ArtistState) => {
    validator = getValidator(state);
    formReducer = Form.reducerForPrefix(ARTIST_FORM_ID, "formData", validator);
  };
  resetForm(state);
  switch (action.type) {
    case ACTION_SET_USER_FORM_VALUE:
      const formItem = state.formData[action.payload.key];
      if (formItem) {
        formItem.value = action.payload.value;
        state = { ...state, formData: { ...state.formData } };
      }
      break;
    case ACTION_SET_USER:
      state = {
        ...state,
        formData: Form.formInputDataWithValues(ARTIST_FORM_ID, {
          userName: action.payload.user.name,
          firstName: action.payload.user.firstName,
          salutation: action.payload.user.salutation,
          lastName: action.payload.user.lastName,
          description: action.payload.user.description,
          password: "",
          email: action.payload.user.email
        })
      };
      break;
    case SIGNUP_EMAIL_IS_IN_USE:
      state = { ...state, emailIsInUse: action.emailIsInUse };
      resetForm(state);
      Object.assign(
        state.formData,
        Form.validateForm(state.formData, validator, ["email"])
      );
  }
  state = (formReducer as any)(state!, action);
  resetForm(state!);

  state.formData = {
    ...state.formData,
    ...Form.validateForm(state!.formData, validator, ["password"])
  };

  return { ...state, complete: Form.formIsValid(state!.formData, validator) };
};

export default userProfileFormReducer;

const getValidator = (state: ArtistState) => {
  const v: { [key: string]: Form.Validator } = {
    userName: Form.Validation.combine(
      Form.Validation.required(),
      Form.Validation.maxLength(15)
    ),
    firstName: Form.Validation.combine(
      Form.Validation.required(),
      Form.Validation.maxLength(15)
    ),
    lastName: Form.Validation.combine(
      Form.Validation.required(),
      Form.Validation.maxLength(20)
    ),
    email: Form.Validation.combine(
      Form.Validation.required(),
      Form.Validation.isMailAddress(),
      value => {
        // console.log("email is in use", state.emailIsInUse);
        return Form.validationResult(
          !state.emailIsInUse,
          state.emailIsInUse ? "email is use" : ""
        );
      }
    )
  };
  if (
    state &&
    state.formData &&
    state.formData["password"].value &&
    state.formData["password"].value.length > 0
  ) {
    v["password"] = Form.Validation.combine(
      Form.Validation.required(),
      Form.Validation.minLength(8),
      Form.Validation.maxLength(128)
    );
  }
  return v;
};
