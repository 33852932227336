import * as React from "react";
import { Button, Header, Icon, Image } from "semantic-ui-react";
import { Spacer } from "../../../components/spacer";
import { PageContainer, styled } from "../../../theme";
import { TeamSketchCard } from "../components/BoxContainer";
import { StyledBoxGird, StyledBoxCell } from "./account/UserPage";
export interface DownloadsPageProps {
  headline?: string;
}

enum OSType {
  Win = 1,
  Mac,
  Lux,
  Unix,
  Other
}

const DownloadBlock: React.FC<{
  title: React.ReactElement;
  subtitle: string;
  img: string;
}> = props => {
  return (
    <TeamSketchCard style={{ textAlign: "center" }}>
      <Spacer />
      <Image
        style={{ display: "inline-block", maxWidth: 350, height: 220 }}
        src={props.img}
      />
      <Spacer />
      <Header as="h2">{props.title}</Header>
      <p>{props.subtitle}</p>
      {props.children}
    </TeamSketchCard>
  );
};

const Center = styled.div`
  text-align: center;
  width: 100%;

  img {
    display: inline-block !important;
  }
`;

const FeatureList = styled.ul`
  list-style: none;
`;
export const DownloadsPage: React.FC<DownloadsPageProps> = props => {
  const desktopDownload = (
    <DownloadBlock
      title={
        <>
          <b>TeamSketch</b> for Desktop
        </>
      }
      img="/img/teamsketch-mbp.jpg"
      subtitle="Turn your desktop into an iPad canvas"
    >
      <FeatureList>
        <li>Allow other team members to draw on your screen</li>
        <li>break out of any desktop app and sketch over it with your iPad</li>
      </FeatureList>

      {getDownloadButton()}
    </DownloadBlock>
  );
  // href="https://testflight.apple.com/join/RcTW4iud"
  const mailtoHref =
    "mailto:support@teamsketch.app?subject=" +
    escape("beta invite request") +
    "&amp;body=" +
    escape("I would like to join the beta");

  const mobileDownload = (
    <DownloadBlock
      title={
        <>
          <b>TeamSketch</b> for iOS
        </>
      }
      img="/img/teamsketch-ipad.jpg"
      subtitle="Start sketching on your and your team mates screens today"
    >
      <Center>
        <a href={mailtoHref}>
          {/* <Image src="/img/ios-badge.svg" /> */}
          request a beta invite on testflight
        </a>
      </Center>
      <Center>
        <a href={mailtoHref}>
          <Image
            width={52}
            height={52}
            src={"//testflight.apple.com/images/testflight-128x128_2x.png"}
          />
        </a>
      </Center>
      <br />
      <small>*During beta access available only via Apple's TestFlight.</small>
    </DownloadBlock>
  );

  const isIOS =
    !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  return (
    <PageContainer>
      <h2>
        {props.headline && props.headline !== "" ? props.headline : "Downloads"}
      </h2>
      <StyledBoxGird>
        <StyledBoxCell>
          {isIOS ? mobileDownload : desktopDownload}
        </StyledBoxCell>
        <StyledBoxCell>
          {isIOS ? desktopDownload : mobileDownload}
        </StyledBoxCell>
      </StyledBoxGird>
    </PageContainer>
  );
};

const getDownloadButton = () => {
  let osType = OSType.Other;
  if (navigator.appVersion.indexOf("Win") !== -1) {
    osType = OSType.Win;
  }
  if (navigator.appVersion.indexOf("Mac") !== -1) {
    osType = OSType.Mac;
  }
  if (navigator.appVersion.indexOf("X11") !== -1) {
    osType = OSType.Unix;
  }
  if (navigator.appVersion.indexOf("Linux") !== -1) {
    osType = OSType.Lux;
  }

  let el;
  switch (osType) {
    case OSType.Win:
      el = (
        <div>
          <a href="/downloads/win/TeamSketchSetup.exe">
            <Button size="large">
              <Icon name="windows" />
              Download for Windows
            </Button>
          </a>
          <small>
            Wrong platform?{" "}
            <a href="/downloads/mac/TeamSketch.zip">download macOS App</a>
          </small>
        </div>
      );
      break;
    case OSType.Mac:
      el = (
        <div>
          <a href="/downloads/mac/TeamSketch.zip">
            <Button size="large">
              <Icon name="apple" />
              Download for macOS
            </Button>
          </a>
          <br />
          <small>
            Wrong platform?{" "}
            <a href="/downloads/win/TeamSketchSetup.exe">
              download Windows App
            </a>
          </small>
        </div>
      );
      break;
    default:
      el = (
        <div>
          <Button size="huge">Download Mac App</Button>
          <a href="/downloads/mac/TeamSketch.zip">Mac App</a>
          <div className="spacer" />
          <a href="/downloads/win/TeamSketchSetup.exe">Windows App</a>
        </div>
      );
  }

  return el;
};
