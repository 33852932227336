import * as GeneralActions from "../../../actions";
import { Project, SketchSummary } from "../../../services/vo/client";
import * as Actions from "../actions";

import {
  newSelection,
  SketchSelection,
  sketchSelection
} from "./sketchSelection";
import { USER_SELECT_SKETCH } from "./user";

export interface TrashState {
  summaries: SketchSummary[];
  context: { project?: Project };
  selection?: SketchSelection;
}

const SELECTION_ID = "trashSelection";
const selectionReducer = sketchSelection(SELECTION_ID);

export const trash = (
  state: TrashState | undefined,
  action: any
): TrashState => {
  if (state === undefined) {
    return {
      selection: newSelection(SELECTION_ID, [], true),
      context: {},
      summaries: []
    };
  }
  switch (action.type) {
    case GeneralActions.SET_PAGE:
    case USER_SELECT_SKETCH:
      return {
        ...state,
        selection: selectionReducer(state.selection!, action)
      };
    case Actions.LOAD_SKETCHES_IN_TRASH:
      return Object.assign({}, state, {
        selection: newSelection(SELECTION_ID, [], true),
        summaries: action.summaries,
        context: {
          project: action.project
        }
      });
  }
  return state;
};
