import * as GeneralActions from "../../../actions";
import { ServiceClient as ProjectsClient } from "../../../services/projects";
import { ServiceClient as SketchClient } from "../../../services/sketch";
import { getAudios } from "../../../sketchLib/audio";
import * as Actions from "../actions";
import * as Middleware from "../middleware";
import { PAGES } from "../Page";
import { TeamsState } from "../reducers/teams";
import Router from "../router";
import { State } from "../store";

export const SKETCH_DELETION_CONTEXT = "sketch";

const getSketchEditable = (teams: TeamsState, userID, authorId, projectId) => {
  let adminProjectIds = teams.projects.filter(
    project => project.ownerIds.indexOf(userID) > -1
  );
  let editable = authorId === userID || adminProjectIds.indexOf(projectId) > -1;
  // console.log("editable", authorId === userID,
  // adminProjectIds.indexOf(projectId) > -1, editable);
  return editable;
};

export const sketchMiddleware = (store: any) => (next: any) => (
  action: any
) => {
  let st: State = store.getState();
  switch (action.type) {
    case Actions.DELETE_SKETCHES:
      if (action.context === SKETCH_DELETION_CONTEXT) {
        ProjectsClient.defaultInst.deleteSketches(
          action.sketchIds,
          err => {
            if(st.project && st.project.project) {
              Actions.getProject(
                st.project.project.id,
                st.project.listing.filters
              );
            } else {
              Router.home();
            }
          },
          Middleware.handleErr(store.dispatch)
        );
      }
      break;
    case Actions.SET_SKETCH_PUBLIC:
      SketchClient.defaultInst.setSketchPublic(action.id, action.sketchPublic, (summary, sketchProject, err) => {
        if(!err) {
          Actions.loadSketchPublic(summary.id, summary.isPublic);
        }
      }, Middleware.handleErr(store.dispatch));
      break;
    case Actions.GET_SKETCH:
      GeneralActions.setPage(PAGES.sketch);
      SketchClient.defaultInst.getSketch(
        action.id,
        (sketch, sketchProject, sketchMeta, err) => {
          let s: any = sketch;
          if (err === null) {
            Actions.loadSketch(s, sketchProject, sketchMeta);
          } else {
            Router.login();
          }
        },
        Middleware.handleErr(store.dispatch)
      );
      SketchClient.defaultInst.tree(action.id, tree => {
        Actions.loadSketchTree(tree);
      }, Middleware.handleErr(store.dispatch));
      break;
    case GeneralActions.SET_PAGE:
      if (st.page.name === PAGES.sketch && st.sketch.dirty) {
        alert("FIXME:");
        // confirm(
        //     () => {
        //       Actions.getSketchSummary(st.sketch.id);
        //     },
        //     {
        //       header: 'unsaved changes',
        //       content:
        //           'you have unsaved changes on the sketch page - want to go
        //           back and finish things?',
        //       confirmLabel: 'go back',
        //       cancelLabel: 'loose changes',
        //     },
        //     () => {
        //       Actions.cancelSketch();
        //     },
        // );
      }
      break;
    case Actions.GET_SKETCH_SUMMARY:
      GeneralActions.setPage(PAGES.sketch);
      const loadedSameSketchAndDirty =
        st.sketch.id === action.id && st.sketch.dirty;
      if (!loadedSameSketchAndDirty) {
        Actions.getSketch(action.id);
        /*
        SketchClient.defaultInst.getSketchSummary(
            action.id,
            (sketchSummary, sketchProject, err) => {
              if (err === null) {
                Actions.loadSketchSummary(sketchSummary, sketchProject);
              } else {
                Actions.setforbiddenRoute(
                    '/sketch/' + encodeURIComponent(action.id),
                );
                Router.login();
              }
            },
            Middleware.handleErr(store.dispatch),
        );
        */
      }
      break;

    case Actions.TOGGLE_PLAY_SKETCH:
      getAudios().forEach(a => {
        if (st.sketch.playing) {
          if (a.started && !a.me.paused) {
            a.me.pause();
            a.togglePaused = true;
          }
        } else {
          if (a.togglePaused) {
            a.me.play();
            a.togglePaused = false;
          }
        }
      });
      break;
    case Actions.LOAD_SKETCH_SUMMARY:
      action.editable = getSketchEditable(
        st.teams,
        st.user.session ? st.user.session.user.id : "",
        action.summary.authorId,
        action.summary.projectId
      );
      // if (
      // 	st.sketch.listingContext.summaries.filter(
      // 		s => s.id === action.summary.id,
      // 	).length === 0
      // ) {
      // 	ProjectsClient.defaultInst.getProject(
      // 		action.summary.projectId,
      // 		[],
      // 		0,
      // 		2,
      // 		(project, listing, err) => {
      // 			Actions.loadProject(project, listing);
      // 		},
      // 		Middleware.handleErr(store.dispatch),
      // 	);
      // }
      break;
    case Actions.LOAD_SKETCH:
      action.editable = false;
      if (st.user.session) {
        action.editable = getSketchEditable(
          st.teams,
          st.user.session.user.id,
          action.sketch.authorId,
          action.sketch.projectId
        );
      }
      break;
  }
  return next(action);
};
