import * as React from "react";
import * as ReactDOMServer from "react-dom/server";
import { PAGES } from "../Page";
import * as GeneralActions from "../../../actions";
import { ServiceClient as DevClient } from "../../../services/dev";
import { GoConst } from "../../../services/vo/client";
import * as Actions from "../actions";
import { App } from "../site";
import { State } from "../store";
import { Provider } from "react-redux";
import { Store } from "redux";

const renderPage = (store: Store<State>) =>
  ReactDOMServer.renderToString(
    <Provider store={store}>
      {" "}
      <App />
    </Provider>
  );
export const devStaticGlobalKey = "devStaticSettings";

export const devStaticMiddleware = (store: Store<State>) => (next: any) => (
  action: any
) => {
  switch (action.type) {
    case GeneralActions.SET_PAGE:
      switch (action.page) {
        case PAGES.devStatic:
          (window as any)[devStaticGlobalKey].renderLock = true;
          let staticContent: { [k: string]: any } = {};
          GeneralActions.setPage(PAGES.downloads);

          staticContent[GoConst.ContentDownloads] = renderPage(store);

          GeneralActions.setPage(PAGES.home);
          staticContent[GoConst.ContentHome] = renderPage(store);

          Actions.devStatic(staticContent);
          (window as any)[devStaticGlobalKey].renderLock = false;
          DevClient.defaultInst.updateStatic(
            staticContent,
            err => console.log("update static content", err),
            () => {
              console.error("service fuck up");
            }
          );
      }
  }
  return next(action);
};
