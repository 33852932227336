import {ActionCreator, AnyAction} from 'redux';
import {ThunkAction} from 'redux-thunk';

import {ServiceClient as SubClient} from '../../../services-async/subscriptions';
import {getClient} from '../../../services-async/transport';
import {ServiceClient as UserClient} from '../../../services-async/users';
import {PaymentInfo} from '../../../services-async/vo/users';
import {SubscriptionInfo} from '../../../services/vo/subscriptions';
import {State} from '../store';

const subClient = getClient(SubClient);
const userClient = getClient(UserClient);

/**
 *
 * @param sourceId
 */
export const deletePaymentSource = (sourceId: string):
    ThunkAction<Promise<void>, State, {}, AnyAction> =>
        async (dispatch, getState) => {
      try {
        console.log('Hello', sourceId);
        await userClient.deletePaymentSource(sourceId)
      } catch (e) {
        console.error(e);
      }
    }

/**
 * load available payment sources from server
 */
export const getPaymentSources = ():
    ThunkAction<Promise<PaymentInfo[]|null>, State, {}, AnyAction> =>
        async (dispatch, getState) => {
      try {
        const infos = await userClient.getAvailablePaymentSources()
        return infos;
      } catch (e) {
        return [];
      }
    }

/**
 *
 * @param teamId
 * @param withTeamNames
 */
export const getBillingSubscriptionsForUser: ActionCreator<
    ThunkAction<Promise<SubscriptionInfo[]>, State, {}, AnyAction>> =
    (teamId: string, withTeamNames: boolean = true) =>
        async (dispatch, getState) => {
      try {
        const infos = await subClient.getBillingSubscriptionsForUser(
            teamId, withTeamNames)
        return infos;
      } catch (e) {
        console.error(e);
        return [];
      }
    }