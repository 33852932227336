import {
  getBooleanFromLocalStorage,
  setBooleanInLocalStorage
} from "../../../utils";
import { ACTION_INVITED } from "./userProfileFormReducer";
import { LOAD_TEAMS_STATE } from "./teams";

export interface InviteState {
  invited: boolean;
}

const keyInvited = "teamsketchUserIsInvited";

export const invite = (
  state: InviteState = {
    invited: getBooleanFromLocalStorage(keyInvited)
  },
  action: any
) => {
  switch (action.type) {
    case LOAD_TEAMS_STATE:
      setBooleanInLocalStorage(keyInvited, true);
      return Object.assign({}, state, { invited: true });
    case ACTION_INVITED:
      setBooleanInLocalStorage(keyInvited, action.invited);
      return Object.assign({}, state, { invited: action.invited });
  }
  return state;
};
