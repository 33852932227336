import * as Actions from '../actions';
import {PAGES} from '../Page';
import {SketchState} from '../reducers/sketch';
import Router from '../router';
import {State} from '../store';

const pageSketch =
    (globalSketchEditing: boolean, sketchState: SketchState,
     indexInc: number) => {
      if (globalSketchEditing) {
        return;
      }
      if (!sketchState.listingContext.summaries) {
        return;
      }
      if (sketchState.dirty) {
        // sbdy is working dude
        return;
      }
      let filteredSummaries = sketchState.listingContext.summaries.filter(
          summary => sketchState.id === summary.id);
      if (filteredSummaries.length === 1) {
        let maxIndex = sketchState.listingContext.summaries.length - 1;
        let min = 0;
        let nextIndex =
            sketchState.listingContext.summaries.indexOf(filteredSummaries[0]) +
            indexInc;
        if (nextIndex < min) {
          nextIndex = maxIndex;
        } else if (nextIndex > maxIndex) {
          nextIndex = min;
        }
        Router.sketch(sketchState.listingContext.summaries[nextIndex].id);
      }
    };

export const prevNextMiddleware = (store: any) => (next: any) =>
    (action: any) => {
      let st: State = store.getState();
      switch (action.type) {
        case Actions.NEXT:
          switch (st.page.name) {
            case PAGES.sketch:
              pageSketch(st.client.globalSketchEditing, st.sketch, +1);
          }
          break;
        case Actions.PREVIOUS:
          switch (st.page.name) {
            case PAGES.sketch:
              pageSketch(st.client.globalSketchEditing, st.sketch, -1);
          }
          break;
      }
      return next(action);
    };
