import * as Form from "../../../form";
import { SIGNUP_EMAIL_IS_IN_USE } from "./userProfileFormReducer";

export interface SignupState {
  formData: Form.FormData;
  agree: boolean;
  complete: boolean;
  emailIsInUse: boolean;
}

export const SIGNUP_FORM_ID = "SIGNUP_FORM_ID";

const getValidator = (state: SignupState): Form.FormValidator => {
  return {
    agree: value => {
      return {
        valid: value === true,
        feedback: !value ? "you have to agree to the terms of service" : ""
      };
    },
    userName: Form.Validation.combine(Form.Validation.maxLength(15)),
    email: Form.Validation.combine(
      Form.Validation.required(),
      Form.Validation.isMailAddress(),
      value =>
        Form.validationResult(
          !state.emailIsInUse,
          state.emailIsInUse ? "email is in use" : ""
        )
    ),
    password: Form.Validation.combine(
      Form.Validation.required(),
      Form.Validation.minLength(8)
    )
  };
};

export const signup = (
  state: SignupState | undefined,
  action: any
): SignupState => {
  var validator, formReducer;
  const resetForm = (state: SignupState) => {
    validator = getValidator(state);
    formReducer = Form.reducerForPrefix(SIGNUP_FORM_ID, "formData", validator);
  };
  resetForm(state);
  if (state === undefined) {
    return {
      complete: false,
      emailIsInUse: false,
      agree: false,
      formData: Form.formInputDataWithValues(SIGNUP_FORM_ID, {
        userName: "",
        email: "",
        password: "",
        agree: false
      })
    };
  }
  switch (action.type) {
    case SIGNUP_EMAIL_IS_IN_USE:
      state = Object.assign({}, state, { emailIsInUse: action.emailIsInUse });
      resetForm(state);
      Object.assign(
        state.formData,
        Form.validateForm(state.formData, validator, ["email"])
      );
  }
  state = formReducer(state, action);
  return Object.assign({}, state, {
    complete: Form.formIsValid(state.formData, validator)
  });
};
