import * as GeneralActions from '../../../actions';
import {ServiceClient as ProjectsClient} from '../../../services/projects';
import * as Actions from '../actions';
import {numberOfSketchesPerPage} from '../components/sketchList/filteredSketchList';
import * as Middleware from '../middleware';
import {PAGES} from '../Page';
import Router from '../router';
import {State} from '../store';

export const SKETCH_DELETION_CONTEXT = 'project';

export const projectMiddleware = (store: any) => (next: any) => (
    action: any) => {
  let st: State = store.getState();
  switch (action.type) {
    case Actions.MV_SKETCHES_TO_PROJECT:
      ProjectsClient.defaultInst.moveSketches(
          action.sketchIds, action.projectId, err => {
            Middleware.reload(store.dispatch, () => {
              // Actions.getProject(st.project.project.id, st.project.userIDs);
              Router.project(action.projectId);
            });
          }, Middleware.handleErr(store.dispatch));
      break;
    case Actions.DELETE_SKETCHES:
      if (action.context === SKETCH_DELETION_CONTEXT) {
        ProjectsClient.defaultInst.deleteSketches(action.sketchIds, err => {
          Actions.getProject(st.project.project.id, st.project.listing.filters);
        }, Middleware.handleErr(store.dispatch));
      }
      break;
    case Actions.GET_PROJECT_NEXT_PAGE:
      // console.log("next project page");
      let pState = st.project;
      if (st.project.listing.page < st.project.listing.pages) {
        ProjectsClient.defaultInst.getProject(
            pState.project.id, pState.listing.filters, pState.listing.page + 1,
            numberOfSketchesPerPage(), (project, listing, err) => {
              Actions.loadProjectNextPage(listing);
            }, Middleware.handleErr(store.dispatch));
      }
      break;
    case Actions.GET_PROJECT:
      ProjectsClient.defaultInst.getProject(
          action.id, action.filters, 0,
          numberOfSketchesPerPage(), (project, listing, err) => {
            Actions.loadProject(project, listing);
            GeneralActions.setPage(PAGES.project);
          }, Middleware.handleErr(store.dispatch));
      break;
  }
  return next(action);
};
