import * as React from "react";
import { StyledMenuItem, MenuItemProps } from "./components";
import { Icon } from "semantic-ui-react";

export const StyleMenuItemList = (props: { items: MenuItemProps[] }) => (
  <React.Fragment>
    {props.items.map((item, index) => {
      let p: { [index: string]: any } = {
        onClick: (e: React.SyntheticEvent) => {
          e.preventDefault();
          item.onClick(e);
        },
        active: item.active
      };
      if (item.expandable && item.onHideList) {
        p = {
          onMouseOver: (e: React.SyntheticEvent) => {
            e.preventDefault();
            item.onClick(e);
          }
        };
      }
      return (
        <StyledMenuItem key={"menu-item-" + index + "-" + item.label} {...p}>
          {item.label}
          {typeof item.icon === "string" && <Icon name={item.icon} />}
        </StyledMenuItem>
      );
    })}
  </React.Fragment>
);
StyleMenuItemList.displayName = "StyleMenuItemList";
