import * as React from "react";
import { styled, BoxStyle, BreakpointMedia, HoverStyle } from "../../../theme";

export const StyledCenterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
`;
const StyledBoxContainer = styled.div`
  padding: 1rem;
  border-radius: 15px;
  @media ${BreakpointMedia.tablet} {
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
    padding: 40px 40px;
    max-width: 600px;
    min-width: 450px;
  }
  background-color: rgb(255, 255, 255);
  z-index: 1;
`;

export const BoxContainer: React.FC<{}> = props => {
  return (
    <StyledCenterWrapper>
      <StyledBoxContainer>{props.children}</StyledBoxContainer>
    </StyledCenterWrapper>
  );
};

export const TeamSketchCard = styled.div`
  padding: 7px;
  @media ${BreakpointMedia.tablet} {
    padding: 30px 20px;
  }
  /* margin-left: -20px; */
  margin-bottom: 20px;
  width: 100%;
  ${props => BoxStyle(props.theme.cornerRadius, false)}
`;

export const TeamSketchCardInteractive = styled(TeamSketchCard)`
  ${HoverStyle}
`;
