import * as Form from "./form";

export const BOOT = "BOOT";
export const SET_PAGE = "SET_PAGE";
export const SET_ENV = "SET_ENV";
export const FORM_INPUT = "FORM_INPUT";

export const LOGIN = "LOGIN";
export const LOGIN_PREPARE = "LOGIN_PREPARE";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SET_FIELD = "LOGIN_SET_FIELD";
export const LOGIN_CAPS_LOCK = "LOGIN_CAPS_LOCK";
export const SUBMIT_LOGIN = "SUBMIT_LOGIN";
export const LOGOUT = "LOGOUT";

const d = (type: string, data: any = {}) => {
  const dispatch = window["TEAMSKETCH_REDUX_STORE"].dispatch;
  dispatch({ ...data, type });
};

// general stuff
export const boot = () => d(BOOT);
export const setPage = (name: string, params?: { [index: string]: any }) =>
  d(SET_PAGE, { payload: { name, params } });
export const formInput = (inputData: Form.InputData, value: any) =>
  d(FORM_INPUT, { inputData, value });

export const login = (email: string, password: string) =>
  d(LOGIN, { email, password });
export const loginCapsLock = (lock: boolean) => d(LOGIN_CAPS_LOCK, { lock });
export const loginError = (code: string) => d(LOGIN_ERROR, { code });
export const loginSetField = (field: string, value: string) =>
  d(LOGIN_SET_FIELD, { field, value });

export const logout = () => d(LOGOUT);
export const loginPrepage = (email: string) =>
  d(LOGIN_PREPARE, { email, password: "" });

export const setEnv = (env: string) => d(SET_ENV, { env });
