import * as React from "react";
import styled from "styled-components";
import { theme } from "../theme";

const Wrapper = styled.div<{ width: number; height: number }>`
  background-color: ${props => theme.backgroundGrey};
  border: 1px solid ${props => theme.borderColor};
  padding-top: ${props => (props.height / props.width) * 100}%;
`;

const IFrame = styled.iframe<{ width: number; height: number }>`
  border: none;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  
`;

export const EmbedSketch = (props: {
  id: string;
  width: number;
  height: number;
}) => (
  <Wrapper width={props.width} height={props.height}>
    <IFrame
      width={props.width}
      height={props.height}
      allowFullScreen
      src={"https://teamsketch.app/embed/" + escape(props.id)}
    />
  </Wrapper>
);
