import * as React from "react";
import Masonry, {
  MasonryOptions,
  MasonryPropTypes
} from "react-masonry-component";

import { ImageSize } from "../../../../components/SketchImage";
import WindowSizeConsumer from "../../../../components/WindowSizeProvider";
import { SketchSummary } from "../../../../services-async/vo/client";
import { BreakpointSize, styled } from "../../../../theme";
import { SketchSelection } from "../../reducers/sketchSelection";
import { SketchesPlaceholder } from "../SketchGrid/SketchesPlaceholder";
import {
  SketchItem,
  SketchSelectionState,
  StyledSketch
} from "../SketchGrid/SketchItem";
import { actionUserSelectSketch } from "../../reducers/user";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";

const masonryOptions: MasonryOptions = {
  transitionDuration: 0,
  percentPosition: true,
  columnWidth: ".grid-sizer",
  itemSelector: ".grid-item",
  // horizontalOrder: true,
  gutter: 0
};
// all elements have an additional 10px padding or margin to make the select effect possible
const gutter = 4;

// grid sites
export const masonryGridSizes = [
  { screenWidth: 0, blocks: 1 },
  { screenWidth: BreakpointSize.mobileM, blocks: 2 },
  { screenWidth: BreakpointSize.tablet, blocks: 3 },
  { screenWidth: BreakpointSize.laptop, blocks: 4 },
  { screenWidth: BreakpointSize.laptopM, blocks: 5 },
  { screenWidth: BreakpointSize.laptopL, blocks: 6 },
  { screenWidth: BreakpointSize.desktop, blocks: 8 },
  { screenWidth: BreakpointSize.desktopM, blocks: 10 }
];

// image sizes based on grid
export const sketchImageSizesDefs: ImageSize[] = masonryGridSizes
  .filter(s => s.screenWidth !== 0)
  .map(girdSize => ({
    media: `(min-width: ${girdSize.screenWidth}px)`,
    size: Math.round(100 / girdSize.blocks)
  }));

// generates css styles for sizes
export const sizesForBlocks = (
  blockSizes: typeof masonryGridSizes,
  blockWidth: number = 1
) => {
  let style = "";
  blockSizes.forEach(block => {
    style += `
            @media screen and (min-width: ${block.screenWidth}px) {
                width: ${(100 / block.blocks) * blockWidth}%;
            }
            `;
  });

  return style;
};

const StyledMasonryGrid = styled(Masonry)`
  list-style: none;
  margin-left: -${gutter + 5}px;
  margin-right: -${gutter + 5}px;
  @media screen and (min-width: 768px) {
    margin-left: -${gutter + 10}px;
    margin-right: -${gutter + 10}px;
  }
  @media screen and (min-width: 1440) {
    margin-left: -${gutter + 15}px;
    margin-right: -${gutter + 15}px;
  }
  .grid-item {
    box-sizing: border-box;

    > div {
      margin: 6px;
    }
    padding: ${gutter}px;
  }

  ${StyledSketch} {
    ${sizesForBlocks(masonryGridSizes)}
  }
`;

export interface SketchMasonryGridProps {
  sketches: SketchSummary[];
  selection?: SketchSelection;
  emptyHint?: React.ReactChild;
  dispatch: ThunkDispatch<{}, {}, any>;
}

const SketchMasonryGrid: React.FC<SketchMasonryGridProps> = ({
  sketches,
  children,
  selection,
  emptyHint,
  dispatch
}) => {
  const onSelected = React.useCallback(
    (sketch: SketchSummary) =>
      dispatch(actionUserSelectSketch(sketch.id, selection.id)),
    [selection, dispatch]
  );
  let masonry: React.Component<MasonryPropTypes, any>;
  React.useEffect(() => {
    if (masonry && (masonry as any).masonry) {
      (masonry as any).masonry.layout();
    }
  }, [masonry]);

  return (
    <>
      {(!sketches || sketches.length === 0) && emptyHint}
      <StyledMasonryGrid
        ref={m => (masonry = m)}
        disableImagesLoaded
        className={"masonry-grid"}
        options={masonryOptions} // default {}
      >
        <StyledSketch className="grid-sizer" />
        {children}
        {sketches && sketches.length > 0 ? (
          sketches.map(sketch => {
            const selected = isSelected(sketch.id, selection);
            return (
              <SketchItem
                sizes={sketchImageSizesDefs}
                selection={selected}
                sketch={sketch}
                key={sketch.id}
                onSelect={onSelected}
              />
            );
          })
        ) : (
          <WindowSizeConsumer>
            {t => (
              <SketchesPlaceholder count={t < BreakpointSize.laptop ? 3 : 10} />
            )}
          </WindowSizeConsumer>
        )}
      </StyledMasonryGrid>
    </>
  );
};

export default connect()(SketchMasonryGrid);

const isSelected = (sketchId: string, selection?: SketchSelection) => {
  if (!selection || !selection.enabled) {
    return undefined;
  }

  if (
    selection.id === sketchId ||
    selection.ids.findIndex(id => id === sketchId) !== -1
  ) {
    return SketchSelectionState.selected;
  }

  return SketchSelectionState.notSelected;
};
