import * as Actions from '../actions';
import {actionCreator} from '../apps/site/store';

export interface PageState {
  name: string;
  params?: {[index: string]: any}
}

export const actionSetPage = (name: string, params?: {[index: string]: any}) =>
    actionCreator(Actions.SET_PAGE, {name, params})

type PageActions = ReturnType<typeof actionSetPage>;

export const page = (defaultPage: string) => {
  return (state: PageState, action: PageActions): PageState => {
    if (state === undefined) {
      return {name: defaultPage};
    }
    switch (action.type) {
      case Actions.SET_PAGE:
        return {...action.payload};
    }
    return state;
  };
};
