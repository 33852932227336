import { ThunkDispatch } from "redux-thunk";

import { setPage } from "../../../actions";
import {actionAddErrorToast, actionAddInfoToast} from "../../../reducers/toast";
import { getClient } from "../../../services-async/transport";
import { ServiceClient as UsersClient } from "../../../services-async/users";
import { PAGES } from "../Page";
import { loadTeamState } from "../reducers/teams";
import Router from "../router";

const userClient = getClient(UsersClient);

/**
 * Use login token and redirect to requested page
 * @param tokenId
 */
export const useLoginToken = (tokenId: string) => async (
  dispatch: ThunkDispatch<{}, {}, any>
) => {
  try {
    // get ret = uri and ret_1 = sessionId
    const { ret } = await userClient.useLoginToken(tokenId);
    // reload user state;
    await dispatch(loadTeamState(false));
    Router.go(ret);
  } catch (e) {
    // TODO: localize, but preserve error message from server
    dispatch(actionAddErrorToast("Invalid Token", e.msg, false));
  }
};

/**
 * Use password reset token and show password reset page
 * @param tokenId
 */
export const useResetToken = (tokenId: string) => async (
  dispatch: ThunkDispatch<{}, {}, any>
) => {
  try {
    // get ret = uri and ret_1 = sessionId
    const { email, password } = await userClient.resetToken(tokenId);
    // reload user state;
    await dispatch(loadTeamState(false));
    setPage(PAGES.resetPassword, { email, password });
  } catch (e) {
    // TODO: localize, but preserve error message from server
    dispatch(actionAddErrorToast("Invalid Token", e.msg, false));
  }
};

/**
 * Use account confirmation token and notify user
 * @param tokenId
 */
export const useConfirmationToken = (tokenId: string) => async (
  dispatch: ThunkDispatch<{}, {}, any>
) => {
  try {
    // get ret = uri and ret_1 = sessionId
    await userClient.confirmationToken(tokenId);
    // reload user state;
    await dispatch(loadTeamState(false));

    // show user page
    setPage(PAGES.home);

    dispatch(actionAddInfoToast("Account confirmed", "Welcome onboard!", false));
  } catch (e) {
    // TODO: localize, but preserve error message from server
    dispatch(actionAddErrorToast("Invalid Token", e.msg, false));
  }
};

/**
 * Use account confirmation token and notify user
 * @param tokenId
 */
export const useInviteToken = (tokenId: string) => async (
  dispatch: ThunkDispatch<{}, {}, any>
) => {
  try {
    // get ret = uri and ret_1 = sessionId
    const { email, password, userExists } = await userClient.inviteToken(
      tokenId
    );
    // reload user state;
    await dispatch(loadTeamState(false));

    // if user exists redirect to login page
    if (userExists) {
      userClient
        .getSession()
        .then(() => Router.home())
        .catch(() => Router.login());
      return;
    }

    // new use show password select form
    setPage(PAGES.invitedNewUser, { email, password });

    dispatch(actionAddInfoToast("Invite accepted", "Happy sketching!", false));
  } catch (e) {
    // TODO: localize, but preserve error message from server
    dispatch(actionAddErrorToast("Invalid Token", e.msg, false));
  }
};
