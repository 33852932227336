import {
  getBooleanFromLocalStorage,
  setBooleanInLocalStorage
} from "../../../utils";
import * as Actions from "../actions";
import { actionCreator } from "../store";

const localStorageKeyGlobalFilters = "clientGlobalFilters";
const localStorageKeySketchEditing = "clientGlobalSketchEditing";

const body = document.body;
const html = document.documentElement;

/**
 * Client only action types
 */

export const CLIENT_SET_GLOBAL_FILTERS = "CLIENT_SET_GLOBAL_FILTERS";
export const CLIENT_SET_INNER_WIDTH = "CLIENT_SET_INNER_WIDTH";
export const CLIENT_TOGGLE_GLOBAL_FILTERS = "CLIENT_TOGGLE_GLOBAL_FILTERS";
export const CLIENT_CHECK_FOOTER = "CLIENT_CHECK_FOOTER";
export const CLIENT_SET_SKETCH_IS_EDITING = "CLIENT_SET_SKETCH_IS_EDITING";
export const CLIENT_TOGGLE_SKETCH_IS_EDITING =
  "CLIENT_TOGGLE_SKETCH_IS_EDITING";

/**
 * Client State
 */
export interface ClientState {
  innerWidth: number;
  showFooter: boolean;
  globalFilters: boolean;
  globalSketchEditing: boolean;
  scrollY: number;
}

/**
 * Action creators
 */
export const actionToggleGlobalFilters = () =>
  actionCreator(CLIENT_TOGGLE_GLOBAL_FILTERS);
export const actionSetGlobalFilters = isFilterEnabled =>
  actionCreator(CLIENT_SET_GLOBAL_FILTERS, isFilterEnabled);
export const actionCheckFooter = () => actionCreator(CLIENT_CHECK_FOOTER);
export const actionToggleSketchIsEditing = () =>
  actionCreator(CLIENT_TOGGLE_SKETCH_IS_EDITING);
export const actionSetSketchIsEditing = (isEditing: boolean) =>
  actionCreator(CLIENT_SET_SKETCH_IS_EDITING, isEditing);
export const actionSetInnerWidth = (innerWidth: number) =>
  actionCreator(CLIENT_SET_INNER_WIDTH, innerWidth);

type ClientActionType = ReturnType<
  | typeof actionCheckFooter
  | typeof actionToggleSketchIsEditing
  | typeof actionSetSketchIsEditing
  | typeof actionToggleGlobalFilters
  | typeof actionSetGlobalFilters
  | typeof actionSetInnerWidth
  | typeof Actions.actionCancelSketchEdit
>;

const clientReducer = (
  state: ClientState,
  action: ClientActionType
): ClientState => {
  if (state === undefined) {
    return {
      innerWidth: typeof window === "object" ? window.innerWidth : 1024,
      globalFilters: getBooleanFromLocalStorage(localStorageKeyGlobalFilters),
      globalSketchEditing: getBooleanFromLocalStorage(
        localStorageKeySketchEditing
      ),
      showFooter: showFooter(),
      scrollY: 0
    };
  }
  switch (action.type) {
    case CLIENT_CHECK_FOOTER:
      return { ...state, showFooter: showFooter(), scrollY: window.scrollY };
    case Actions.CANCEL_SKETCH_EDIT:
      return { ...state, globalSketchEditing: false };
    case CLIENT_SET_SKETCH_IS_EDITING:
      setBooleanInLocalStorage(localStorageKeySketchEditing, action.payload);
      return { ...state, globalSketchEditing: action.payload };
    case CLIENT_TOGGLE_SKETCH_IS_EDITING:
      const globalSketchEditing = !state.globalSketchEditing;
      setBooleanInLocalStorage(
        localStorageKeySketchEditing,
        globalSketchEditing
      );
      return { ...state, globalSketchEditing };
    case CLIENT_TOGGLE_GLOBAL_FILTERS:
      let globalFilters = !state.globalFilters;
      setBooleanInLocalStorage(localStorageKeyGlobalFilters, globalFilters);
      return Object.assign({}, state, { globalFilters });
    case CLIENT_SET_INNER_WIDTH:
      return { ...state, innerWidth: action.payload };
  }
  return state;
};

export default clientReducer;

/**
 * Utils
 */
const showFooter = () => {
  let height = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );
  if (window.innerHeight >= height) {
    return true;
  }
  // console.log(height, height - (window.scrollY + window.innerHeight));
  return height - (window.scrollY + window.innerHeight) < 100;
};
