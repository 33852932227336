import React, { useState, HTMLAttributes, useEffect } from "react";

import { AvailableImageSizes, mediaUrl } from "./picture";
import { styled } from "../theme";
import { HtmlImageProps, Loader } from "semantic-ui-react";

export interface ImageSize {
  // media selector
  media: string;
  // size of image in percent
  size: number;
}

interface SketchImageProps {
  src: string;
  sizes: ImageSize[];
  defaultSize: number;
  className?: string;
  alt?: string;
}

const StyledNoImageOverlay = styled.button`
  cursor: pointer;
  position: absolute;
  outline: none;
  border: none;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  color: #dedede;
  background-color: #fff;
  font-weight: 200;

  i {
    opacity: 0.8;
  }
`;

enum LoadStatus {
  OK = "ok",
  Loading = "loading",
  Processing = "processing",
  Error = "error"
}

export const NoImagePlaceholder = (props: {
  status: LoadStatus;
  error: string;
}) => {
  return (
    <StyledNoImageOverlay>
      {props.status === LoadStatus.Processing && (
        <Loader inline={true} active={true} size="small" />
      )}
      {props.status === LoadStatus.Error && props.error}
    </StyledNoImageOverlay>
  );
};

const StyledErrorImg = styled.img<{ failed?: boolean }>`
  width: 100%;
  ${props => (props.failed ? "opacity: 0;" : "")}
`;

/**
 * Fetch a image data via fetch and assign it to image using data url
 * @param maxAttempts
 * @param setSource
 * @param setLoadError
 * @param setLoadStatus
 * @param src
 */
const effectFetchImage = (
  maxAttempts: number,
  setSource: (src: string) => void,
  setLoadError: (err: string) => void,
  setLoadStatus: (status: LoadStatus) => void,
  src: string
) => {
  let attempt = 0;

  const loadImage = () => {
    attempt++;
    if (attempt > maxAttempts) {
      setLoadStatus(LoadStatus.Error);
      setLoadError("too busy, retry later");
      return;
    }
    const waitTimeout = setTimeout(() => {
      setLoadStatus(LoadStatus.Processing);
    }, 500);
    fetch(src)
      .then(async res => {
        clearTimeout(waitTimeout);
        switch (res.status) {
          case 202:
            setLoadStatus(LoadStatus.Processing);
            // set timeout with random delay.
            // make sure all assets are not loaded at the same time
            setTimeout(loadImage, Math.random() * 1000 + 3000);
            break;
          case 200:
            setLoadStatus(LoadStatus.OK);
            setSource(URL.createObjectURL(await res.blob()));
            break;
          default:
            setLoadStatus(LoadStatus.Error);
            setLoadError("" + res.status);
        }
      })
      .catch(e => {
        clearTimeout(waitTimeout);
        setLoadError("e:" + e);
      });

    return () => {
      window.clearTimeout(waitTimeout);
      // TODO: cancel promise here when ES7 arrives and promises can be canceled
    };
  };
  return loadImage;
};
export const ImageWithErrorPlaceholder: React.FC<
  HTMLAttributes<HTMLImageElement> & HtmlImageProps
> = (
  props: HTMLAttributes<HTMLImageElement> &
    HtmlImageProps & { onError: (e) => void }
) => {
  const [error, setLoadError] = useState("loading");
  const [loadStatus, setLoadStatus] = useState(LoadStatus.Loading);
  const [src, setSource] = useState("");
  useEffect(
    effectFetchImage(10, setSource, setLoadError, setLoadStatus, props.src),
    [props.src]
  );
  return (
    <>
      {(src && (
        <StyledErrorImg {...{ alt: props.alt, title: props.title, src }} />
      )) || <NoImagePlaceholder error={error} status={loadStatus} />}
    </>
  );
};

/**
 * Compute the size string for the image
 * @param sizes
 */
const sizeString = (sizes: ImageSize[]): string =>
  sizes
    .slice()
    .reverse()
    .reduce((prev, cur) => prev + cur.media + " " + cur.size + "vw,", "") +
  "100vw";

/**
 * generate source set for a image
 * @param src
 */
const generateSrcSet = (src: string) =>
  AvailableImageSizes.map(size => mediaUrl(src, size) + " " + size + "w").join(
    ",\n"
  );

const SketchImage: React.FC<SketchImageProps> = props => {
  const [sizes, setSizes] = useState(sizeString(props.sizes));
  const [srcSet, setSrcSet] = useState(generateSrcSet(props.src));
  useEffect(() => setSizes(sizeString(props.sizes)), [props.sizes, setSizes]);
  useEffect(() => setSrcSet(generateSrcSet(props.src)), [setSrcSet, props.src]);
  return (
    <ImageWithErrorPlaceholder
      srcSet={srcSet}
      sizes={sizes}
      className={props.className}
      alt={props.alt}
      src={mediaUrl(props.src, props.defaultSize)}
    />
  );
};

export default SketchImage;
