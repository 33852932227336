import { runSite } from "./site";
import "../../i18n";
import "./site.scss";
declare global {
  interface Function {
    displayName: string;
  }
}

runSite();
