import * as GeneralActions from "../../../actions";
import { Project, SketchListing } from "../../../services/vo/client";
import * as Actions from "../actions";
import {
  newSelection,
  SketchSelection,
  sketchSelection
} from "./sketchSelection";
import { appendToListing } from "./utils";
import { USER_SELECT_SKETCH, USER_SET_SELECTION_ENABLED } from "./user";

export interface ProjectState {
  userIDs: string[];
  tagIDs: string[];
  selection?: SketchSelection;
  project?: Project;
  listing?: SketchListing;
}

const SELECTION_ID = "projectSelection";

const selectionReducer = sketchSelection(SELECTION_ID);

export const project = (
  state: ProjectState = {
    selection: newSelection(SELECTION_ID),
    userIDs: [],
    tagIDs: [],
    project: null,
    listing: null
  },
  action: any
) => {
  switch (action.type) {
    case Actions.LOAD_PROJECT_NEXT_PAGE:
      return appendToListing(state, action);
    case GeneralActions.SET_PAGE:
    case USER_SET_SELECTION_ENABLED:
    case USER_SELECT_SKETCH:
      return {
        ...state,
        selection: selectionReducer(state.selection, action)
      };
    case Actions.GET_PROJECT:
      return {
        ...state,
        userIDs: action.userIDs
      };
    case Actions.LOAD_PROJECT:
      return {
        ...state,
        listing: action.listing,
        project: action.project,
        selection: {
          enabled: state.selection.enabled,
          id: SELECTION_ID,
          ids: []
        }
      };
  }
  return state;
};
