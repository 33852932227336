import * as React from "react";
import {  Header, Button } from "semantic-ui-react";
import { styled } from "../../../../theme";
import { CenterBoxPage } from "../CenterBoxPage";
import { Spacer, SpacerSize } from "../../../../components/spacer";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { createFirstTeam } from "../../actions/team";

const StyledWrapper = styled.div`
  text-align: center;
`;

interface NoTeamsProps {
  dispatch: ThunkDispatch<{}, {}, any>;
}

const NoTeams: React.FC<NoTeamsProps> = props => {
  const { t } = useTranslation();

  return (
    <>
      <Header as="h3" content={t("NO_TEAMS_HEADER")} />
      <Spacer size={SpacerSize.tiny} />
      <p>{t("NO_TEAMS_CONTENT")}</p>
      <Spacer size={SpacerSize.tiny} />
      <Button primary onClick={() => props.dispatch(createFirstTeam())}>
        {t("CREATE_FIRST_TEAM")}
      </Button>
    </>
  );
};

const ConnectedNoTeams = connect()(NoTeams);

export const NoTeamsBox: React.FC<{}> = props => (
  <CenterBoxPage>
    <StyledWrapper>
      <ConnectedNoTeams />
    </StyledWrapper>
  </CenterBoxPage>
);

export default ConnectedNoTeams;
