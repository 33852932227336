import * as React from "react";

import { styled } from "../../../../theme";
import { StyledSketch } from "./SketchItem";
import FixedAspectRatioBox from "../../../../components/FixedAspectRationBox";

const StyledPlaceholderWrapper = styled.div``;

const StyledPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: no-drop;
  border: 3px dashed #e4e4e4;
  border-radius: ${props => props.theme.cornerRadius};
  color: #ccc !important;
  font-weight: 600;
`;

export const SketchesPlaceholder: React.FC<{ count?: number }> = ({
  count = 10
}) => {
  const placeholders: React.ReactElement[] = [];

  for (let i = 0; i < count; i++) {
    placeholders.push(
      <StyledPlaceholderWrapper key={i}>
        <StyledSketch className={"grid-item"}>
          <FixedAspectRatioBox ratio={"4:" + Math.round(Math.random() * 3 + 3)}>
            <StyledPlaceholder>{i === 0 && "list is empty"}</StyledPlaceholder>
          </FixedAspectRatioBox>
        </StyledSketch>
      </StyledPlaceholderWrapper>
    );
  }

  return <>{placeholders}</>;
};
