import { AnyAction, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import * as GeneralActions from "../../../actions";
import { toaster } from "../../../reducers/toast";
import { ServiceClient as SubscriptionsClient } from "../../../services-async/subscriptions";
import { ServiceClient as TeamsClient } from "../../../services-async/teams";
import { getClient } from "../../../services-async/transport";
import { ServiceClient as UserClient } from "../../../services-async/users";
import { handleErr, reload } from "../middleware";
import { PAGES } from "../Page";
import { actionInit, actionRefreshUsers } from "../reducers/editAccount";
import {  State } from "../store";
import Router from "../router";

export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const EDIT_ACCOUNT_SUBSCRIPTION = "EDIT_ACCOUNT_SUBSCRIPTION";

export const EDIT_ACCOUNT_ADD_USER = "EDIT_ACCOUNT_ADD_USER";
export const EDIT_ACCOUNT_RM_USER = "EDIT_ACCOUNT_RM_USER";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const END_ACCOUNT_SUBSCRIPTION = "END_ACCOUNT_SUBSCRIPTION";

const client = getClient(TeamsClient);
const userClient = getClient(UserClient);
const subClient = getClient(SubscriptionsClient);

/**
 * add a user to a team
 */
export const addUser = (
  teamId: string,
  projectIds: string[],
  email: string
): ThunkAction<Promise<void>, State, {}, AnyAction> => async (
  dispatch,
  getState
) => {
  const { info, error } = toaster(dispatch);
  try {
    await userClient.invite(email, teamId, projectIds);
    info("user " + email + " was invited to team");
    // refresh team data
    refreshTeam(dispatch, getState, teamId);
  } catch (e) {
    error("could not invite user to team");
    handleErr(dispatch);
  }
};

/**
 * remove a user to a team
 */
export const removeUser = (
  teamId: string,
  userId: string
): ThunkAction<Promise<void>, State, {}, AnyAction> => async (
  dispatch,
  getState
) => {
  const { info, error } = toaster(dispatch);
  try {
    await client.removeUserFromTeam(teamId, userId);
    info("removed user from team");
    // refresh team data
    refreshTeam(dispatch, getState, teamId);
  } catch (e) {
    error("could not remove user from team");
    handleErr(dispatch);
  }
};

/**
 * Thunk action to update general team info like
 * name, description
 * account state will be taken from accountFormData
 */
export const update = (): ThunkAction<
  Promise<void>,
  State,
  {},
  AnyAction
> => async (dispatch, getState) => {
  const state = getState();
  const accountFormData = state.editAccount.formData;
  const team = state.editAccount.team;
  const { info, error } = toaster(dispatch);
  try {
    await client.updateTeam(
      team.id,
      accountFormData["name"].value,
      accountFormData["description"].value,
      team.tags,
      team.quota.plan
    );
    refreshTeam(dispatch, getState, team.id);
    info("team was updated");
  } catch (e) {
    error("could not update team");
    handleErr(dispatch);
  }
};

/**
 * remove team by id
 * @param teamId
 */
export const deleteTeam = (
  teamId: string
): ThunkAction<Promise<void>, State, {}, AnyAction> => async (
  dispatch,
  getState
) => {
  const { info, error } = toaster(dispatch);
  try {
    await client.removeTeam(teamId);
    // open edit page for the first team if not teams present show the no
    // teams page
    reload(dispatch, (user, teams, err) => {
      if (err) {
        console.error(err);
        return;
      }
      info("team was deleted");
      // navigate to edit the first remaining team
      Router.editTeam(teams.length > 0 ? teams[0].id : null);
    });
  } catch (e) {
    error("could not delete team");
    handleErr(dispatch);
  }
};

/**
 * create a new team
 */
export const createTeam = (
  name: string = "new team",
  desc: string = "",
  tags: string[] = [],
  plan: string = "STARTER"
): ThunkAction<Promise<void>, State, {}, AnyAction> => async (
  dispatch,
  getState
) => {
  try {
    const newTeam = await client.createTeam(name, desc, tags, plan);
    // open edit page for new team
    reload(dispatch, () => Router.editTeam(newTeam.id));
  } catch (e) {
    handleErr(dispatch);
  }
};

/**
 * creates the first team (with a demo project)
 */
export const createFirstTeam = (
  name: string = "first team"
): ThunkAction<Promise<void>, State, {}, AnyAction> => async (
  dispatch,
  getState
) => {
  try {
    const newTeam = await client.createFirstTeam(name);
    // open edit page for new team
    reload(dispatch, () => Router.editTeam(newTeam.id));
  } catch (e) {
    handleErr(dispatch);
  }
};

/**
 * manage an accounts subscription
 * @param teamId
 */
export const manageSubscription = (
  teamId: string
): ThunkAction<Promise<void>, State, {}, AnyAction> => async (
  dispatch,
  getState
) => {
  alert("TODO: manage subscription action");
};

export const endSubscription = (
  teamId: string
): ThunkAction<Promise<void>, State, {}, AnyAction> => async (
  dispatch,
  getState
) => {
  try {
    await subClient.endSubscriptionForTeam(teamId);
    refreshTeam(dispatch, getState, teamId);
  } catch (e) {
    console.error(e);
    handleErr(dispatch);
  }
};

/**
 * prepare editAccount page data
 * actions in multiple files
 */
export const prepareEditTeamPage = (
  teamId: string | null
): ThunkAction<Promise<void>, State, {}, AnyAction> => async (
  dispatch,
  getState
) => {
  // toggle account edit page mode
  GeneralActions.setPage(PAGES.editTeam);
  if (!teamId) {
    dispatch(actionInit(null, []));
    return;
  }
  const state = getState();
  // find a team object for id
  const team = state.teams.teams.find(team => team.id === teamId);
  try {
    // load team members but why?
    const teamInfo = await userClient.getTeamUsers(teamId);

    // set team members but why?
    dispatch(actionRefreshUsers(teamId, teamInfo));

    // dispatch action to create initial edit team page state
    dispatch(actionInit(team, teamInfo));
    return;
  } catch (e) {
    handleErr(dispatch);
  }

  dispatch(actionInit(team, []));
};

/**
 * Refresh team edit page state after update
 */
const refreshTeam = (dispatch: Dispatch, getState, teamId: string) =>
  reload(dispatch, (user, teams, err) => {
    if (!err) {
      prepareEditTeamPage(teamId)(dispatch, getState, {});
    }
  });
