import * as React from "react";
import { styled, theme } from "../theme";

const StyledSection = styled.section<{ background: boolean }>`
  padding-bottom: 100px;
  background: ${props => (props.background ? theme.backgroundGrey : "white")};

  &:first-child {
    padding-top: 60px;
  }
`;

export const Section = (props: {
  background?: boolean;
  style?: any;
  children;
}) => (
  <StyledSection background={props.background} style={props.style}>
    {props.children}
  </StyledSection>
);

Section.displayName = "Section";
