import * as React from "react";
import { Container, Grid, Header, List } from "semantic-ui-react";
import { Spacer, SpacerSize } from "../../../components/spacer";
import Router from "../router";
import { ContentImage, PageContainer, styled } from "../../../theme";
import { CTAButton } from "../../../components/CTAButton";
import { Section } from "../../../components/section";
import { PricingTeaser } from "../components/content/PricingTeaser";
import { EmbedSketch } from "../../../sketchLib/EmbedSketch";

const IconWrapper = styled.div`
  margin-left: 85px;
  position: relative;
  padding-bottom: 3rem;

  &:before {
    top: -20px;
    left: -90px;
    position: absolute;
  }
`;

enum HomeIcon {
  arrowDown = "arrow-down",
  arrowLeft = "arrow-left",
  arrowRight = "arrow-right",
  arrowUp = "arrow-up",
  architect = "architect",
  burger = "burger",
  check = "check",
  close = "close",
  cloud = "cloud",
  designer = "designer",
  display = "display",
  everyone = "everyone",
  ipad = "ipad",
  pen = "pen",
  pinterest = "pinterest",
  play = "play",
  search = "search",
  trash = "trash",
  twitter = "twitter"
}

const IconListItem = styled(List.Item)`
  position: relative;
  padding: 0 !important;
  display: inline-block;
  font-weight: 400;
  margin-top: 20px;
  font-size: 1.2rem;
  margin-left: 35px;
  position: relative;
  margin-bottom: 15px;

  &::before {
    position: absolute;
    top: 10px;
    left: -65px;
    line-height: 0 !important;
    font-size: 75px;
  }
`;

const Feature = (props: { title: string; icon: HomeIcon; text: string }) => (
  <Grid.Column mobile={16} tablet={8} computer={8}>
    <IconWrapper className={"icon-" + props.icon}>
      <Header as="h3" content={props.title} />
      <p>{props.text}</p>
    </IconWrapper>
  </Grid.Column>
);

export const HomePage = (props: {}) => {
  return (
    <>
      <Section>
        <PageContainer>
          <Grid verticalAlign="middle">
            <Grid.Row>
              <Grid.Column mobile={16} tablet={8} computer={6} widescreen={7}>
                <Header
                  as="h1"
                  content={
                    "TeamSketch – the digital tracing paper for you and your team"
                  }
                />
                <p>
                  TeamSketch combines the speed and simplicity of freehand
                  sketching with the wonderful and efficient possibilities of
                  digital workflows. Paper rolls finally caught up with CAD.
                </p>
                <CTAButton onClick={e => Router.signup()}>
                  Join the beta
                </CTAButton>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={10} widescreen={9}>
                <div>
                  <ContentImage src="/img/ts-mockup.jpg" />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </PageContainer>
      </Section>

      <Section>
        <PageContainer>
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} computer={4}>
                <EmbedSketch
                  width={4}
                  height={3}
                  id="5b3e14b645869d0001cbe4be"
                />
              </Grid.Column>
              <Grid.Column mobile={16} computer={4}>
                <h2>Embedding sketches</h2>
                <p>These are all team sketches</p>
              </Grid.Column>
              <Grid.Column mobile={16} computer={4}>
                <EmbedSketch
                  width={4}
                  height={3}
                  id="5b4907d349a3df0001513cf8"
                />
              </Grid.Column>
              <Grid.Column mobile={16} computer={4}>
                <EmbedSketch
                  width={4}
                  height={3}
                  id="5d972872f2d25e00017ed7d1"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </PageContainer>
      </Section>
      <Section>
        <PageContainer>
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} computer={6}>
                <Header as="h2" content="What is TeamSketch?" />
                <p>
                  TeamSketch enables you to sketch with your iPad over anything
                  on any computer. It brings the transparent overlay of tracing
                  paper into your digital workflows. No matter if you work alone
                  or together with a team, face-to-face or remotely.
                </p>
                <Spacer size={SpacerSize.small} />
              </Grid.Column>
              <Grid.Column mobile={16} computer={10}>
                <Grid padded="vertically">
                  <Grid.Row style={{ paddingTop: 0 }}>
                    <Feature
                      title="Accelerate your ideation and design process"
                      text="Sketch over anything on your screen. Enhance the communication with your team by visualizing your thoughts digitally within seconds."
                      icon={HomeIcon.arrowUp}
                    />
                    <Feature
                      title="Sketch live on a remote screen"
                      text="Draw remotely on the screen of a client or coworker – no matter if you are sitting next to him/her or at the back of beyond."
                      icon={HomeIcon.display}
                    />
                  </Grid.Row>
                  <Grid.Row style={{ paddingTop: 0 }}>
                    <Feature
                      title="Share your sketches with others"
                      text="Save your sketches locally or share them easily with your team members to collaborate. Add voice recordings while sketching to give additional information."
                      icon={HomeIcon.cloud}
                    />
                    <Feature
                      title="Super smooth sketch engine"
                      text="TeamSketch offers an extremely fast and precise drawing engine with absolutely authentic pen-settings. Export printable high-res image files."
                      icon={HomeIcon.pen}
                    />
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </PageContainer>
      </Section>

      <Section>
        <PageContainer>
          <Grid verticalAlign="middle">
            <Grid.Row>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <div>
                  <ContentImage float="right" src="/img/home-features.jpg" />
                </div>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <Header as="h2" content="Sketch like never before!" />
                <p>
                  Once installed, you can cover every computer monitor with a
                  transparent layer by pushing a single button on your iPad.
                  Visualize your ideas or give corrections and advice to your
                  team members. Just sit next to them and start TeamSketch on
                  your iPad to draw on their screens. Or clip their screens
                  remotely while you are somewhere else in the world. Watch the
                  video to find out how it works.
                </p>
                <List>
                  <IconListItem className="icon-check">
                    Customize your brushes and colors.
                  </IconListItem>
                  <IconListItem className="icon-check">
                    Selective background masking.
                  </IconListItem>
                  <IconListItem className="icon-check">
                    Create different teams and projects to organize and share
                    your sketches.
                  </IconListItem>
                  <IconListItem className="icon-check">
                    Annotate your sketches with voice recordings.
                  </IconListItem>
                  <IconListItem className="icon-check">
                    Export printable image files in high resolution.
                  </IconListItem>
                </List>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </PageContainer>
      </Section>

      <Section>
        <PageContainer>
          <Grid stackable>
            <Grid.Row centered>
              <Grid.Column mobile={16} tablet={16} computer={10}>
                <Container textAlign={"center"}>
                  <Header as="h2" content="TeamSketch is for everybody" />
                  <p>
                    TeamSketch offers digital freehand sketching with a
                    collaborative approach for everybody who wants to be more
                    productive and more creative at the same time. It helps
                    creative people to achieve extraordinary design results
                    fast.
                  </p>
                </Container>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column>
                <i className="icon-architect" />
                <Header as="h3" content="For Architects" />
                <p>TeamSketch is the ultimate tool for collaborative design!</p>
                <p>
                  It allows you to focus on your creative process - instead of
                  being distracted by managing communication with your team.
                </p>
                <p>
                  <strong>Jan Halfar</strong>
                  <br />
                  Creative Director
                </p>
                <Spacer size={SpacerSize.tiny} />
              </Grid.Column>
              <Grid.Column>
                <i className="icon-designer" />
                <Header as="h3" content="For Designers" />
                <p>
                  Sketching is a fast and simple way to bring new ideas to life.
                </p>
                <p>
                  With TeamSketch this becomes as easy as never before - no
                  matter if working alone or collaborating in a team.
                </p>
                <p>
                  <strong>Wlad Meixner</strong>
                  <br />
                  Software Architect / Visionary
                </p>
                <Spacer size={SpacerSize.tiny} />
              </Grid.Column>
              <Grid.Column>
                <i className="icon-everyone" />
                <Header as="h3" content="For Everyone" />
                <p>
                  It doesn’t matter if you are a Architect or a Designer,
                  TeamSketch is for everyone.
                </p>
                <p>The most powerful tools are words and sketches.</p>
                <p>
                  <strong>Max Muster</strong>
                  <br />
                  Graphic Designer
                </p>
                <Spacer size={SpacerSize.tiny} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
              <Grid.Column mobile={16} tablet={16} computer={10}>
                <Container textAlign={"center"}>
                  <PricingTeaser />
                </Container>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </PageContainer>
      </Section>
    </>
  );
};
