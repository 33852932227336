import * as React from "react";
import { Loader, Table } from "semantic-ui-react";

import { Spacer, SpacerSize } from "../../../../components/spacer";
import { ServiceError } from "../../../../services-async/vo/services";
import { SubscriptionInfo } from "../../../../services/vo/subscriptions";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { getBillingSubscriptionsForUser } from "../../actions/subscriptions";
import { State } from "../../store";

export interface UserSubscriptionListProps {
  dispatch: ThunkDispatch<State, {}, any>;
  artistid: string;
  onSelect?: { (info: SubscriptionInfo): void };
}
export interface UserSubscriptionListState {
  subscriptions?: SubscriptionInfo[] | null;
  err?: Error;
}

class UserSubscriptionList extends React.Component<
  UserSubscriptionListProps,
  UserSubscriptionListState
> {
  constructor(props: UserSubscriptionListProps) {
    super(props);
    this.state = {
      subscriptions: undefined
    };
  }

  componentDidMount() {
    const promise = (this.props.dispatch(
      getBillingSubscriptionsForUser(this.props.artistid)
    ) as unknown) as Promise<SubscriptionInfo[]>;
    promise
      .then(subs => this.handlerSubListSuccess(subs, null))
      .catch(err => this.handleSubListError(null, err));
  }

  handlerSubListSuccess = (subs: SubscriptionInfo[], err: ServiceError) => {
    this.setState({
      subscriptions: subs,
      err: err ? { message: err.msg, name: err.errorType } : null
    });
  };

  handleSubListError = (req, e) => {
    console.error(e);
  };

  handleRowSelected = (sub: SubscriptionInfo) => {
    if (!this.props.onSelect) {
      return;
    }
    this.props.onSelect(sub);
  };

  render() {
    return (
      <>
        <h2>Subscriptions</h2>
        {this.state.subscriptions === null ? (
          <>
            <p>There are currently no ongoing subscriptions.</p>
            <Spacer size={SpacerSize.tiny} />
          </>
        ) : this.state.subscriptions !== undefined ? (
          <Table basic="very" selectable={this.props.onSelect !== null}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="left">Account</Table.HeaderCell>
                <Table.HeaderCell textAlign="left">Plan</Table.HeaderCell>
                <Table.HeaderCell textAlign="left">Start Date</Table.HeaderCell>
                <Table.HeaderCell textAlign="left">
                  Billing Date
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {this.state.subscriptions.map(sub => (
                <SubTableRow
                  sub={sub}
                  key={sub.id}
                  onClick={this.handleRowSelected}
                />
              ))}
            </Table.Body>
          </Table>
        ) : (
          <>
            <Spacer />
            <Loader active inline="centered" />
            <Spacer />
          </>
        )}
      </>
    );
  }
}

const SubTableRow: React.FC<{
  sub: SubscriptionInfo;
  onClick?: (sub: SubscriptionInfo) => void;
}> = ({ sub, onClick }) => (
  <Table.Row key={sub.id} onClick={onClick ? () => onClick(sub) : undefined}>
    <Table.Cell>{sub.team.name}</Table.Cell>
    <Table.Cell>
      {sub.plan} {sub.planName}
    </Table.Cell>
    <Table.Cell>{sub.createdAt}</Table.Cell>
    <Table.Cell>{sub.billingDate}</Table.Cell>
  </Table.Row>
);

export default connect()(UserSubscriptionList);
