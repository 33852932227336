import { throttle } from "lodash";
import * as React from "react";
import { AnyAction, Dispatch } from "redux";
import { connect } from "react-redux";

interface InfinityScrollWrapperProps<ScrollItem> {
  onReachedBoundary: () => void;
  dispatch: Dispatch<AnyAction>;
}

const ScrollLimit = 1600;

class InfinityScroll<
  ScrollItem extends React.Component
> extends React.Component<InfinityScrollWrapperProps<ScrollItem>> {
  private lastItem: HTMLElement;
  private scrollDebounceTimeout: number;

  private handleScroll = throttle(() => {
    if (!this.lastItem) {
      return;
    }
    const rect = this.lastItem.getBoundingClientRect();

    // Check if bottom minus ScrollLimit are visible in the viewport
    if (
      rect.bottom - ScrollLimit <=
      (window.innerHeight || document.documentElement.clientHeight)
    ) {
      this.props.onReachedBoundary();
    }
  }, 300);

  render() {
    return <div ref={ref => (this.lastItem = ref)}>{this.props.children}</div>;
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
}

export default connect()(InfinityScroll);
