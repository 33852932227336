import React, { useCallback } from "react";

import UserCircle from "../../../components/UserCircle";
import { SelectionMode } from "../../../theme";
import { PAGES } from "../Page";
import Router from "../router";

/**
 * Default user circle with added user reference out of the box
 */
const SiteUserCircle: React.FC<{
  name: string;
  id: string;
  size?: number;
  mode?: SelectionMode;
  popupText?: (name: string) => React.ReactElement;
  onClick?: (userId: string) => void;
  noPopup?: boolean;
}> = ({ name, id, size, popupText, noPopup, mode, onClick }) => {
  const onClickCallback = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      if (mode === SelectionMode.disabled) {
        return;
      }
      if (onClick) {
        onClick(id);
      } else {
        Router.user(id, []);
      }
    },
    [mode, onClick, id]
  );
  return (
    <UserCircle
      name={name}
      id={id}
      size={size}
      popupText={popupText}
      mode={mode}
      href={!onClick ? `/${PAGES.user}/${id}` : undefined}
      onClick={onClickCallback}
    />
  );
};

export default SiteUserCircle;
