import * as React from "react";
import { styled } from "../theme";

const StyledLinkList = styled.div`
  padding: 0;

  a:first-child {
    padding-left: 0 !important;
  }

  a:last-child {
    padding-right: 0 !important;
  }
`;

export const LinkList = (props: { style?: React.CSSProperties; children }) => (
  <StyledLinkList style={props.style ? props.style : {}}>
    {props.children}
  </StyledLinkList>
);

LinkList.displayName = "LinkList";
