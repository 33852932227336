/* tslint:disable */
// hello commonjs - we need some imports - sorted in alphabetical order, by go package
import * as github_com_bestbytes_paperroll_server_billing from './../../services/vo/billing'; // webpack/src/services-async/vo/client.ts to webpack/src/services/vo/billing.ts
import * as github_com_bestbytes_paperroll_server_client from './client'; // webpack/src/services-async/vo/client.ts to webpack/src/services-async/vo/client.ts
import * as github_com_bestbytes_paperroll_server_relay from './relay'; // webpack/src/services-async/vo/client.ts to webpack/src/services-async/vo/relay.ts
import * as github_com_bestbytes_paperroll_server_services from './services'; // webpack/src/services-async/vo/client.ts to webpack/src/services-async/vo/services.ts
import * as github_com_bestbytes_paperroll_server_services_admin from './admin'; // webpack/src/services-async/vo/client.ts to webpack/src/services-async/vo/admin.ts
import * as github_com_bestbytes_paperroll_server_services_projects from './projects'; // webpack/src/services-async/vo/client.ts to webpack/src/services-async/vo/projects.ts
import * as github_com_bestbytes_paperroll_server_services_search from './search'; // webpack/src/services-async/vo/client.ts to webpack/src/services-async/vo/search.ts
import * as github_com_bestbytes_paperroll_server_services_subscriptions from './../../services/vo/subscriptions'; // webpack/src/services-async/vo/client.ts to webpack/src/services/vo/subscriptions.ts
import * as github_com_bestbytes_paperroll_server_services_teams from './teams'; // webpack/src/services-async/vo/client.ts to webpack/src/services-async/vo/teams.ts
import * as github_com_bestbytes_paperroll_server_services_users from './users'; // webpack/src/services-async/vo/client.ts to webpack/src/services-async/vo/users.ts
import * as github_com_stripe_stripe_go from './stripe'; // webpack/src/services-async/vo/client.ts to webpack/src/services-async/vo/stripe.ts
// github.com/bestbytes/paperroll-server/client.AudioInfo
export interface AudioInfo {
	start:number;
	end:number;
}
// github.com/bestbytes/paperroll-server/client.Entity
export interface Entity {
	limit:number;
	value:number;
}
// github.com/bestbytes/paperroll-server/client.Filter
export interface Filter {
	filterType:string;
	options:github_com_bestbytes_paperroll_server_client.FilterOption[];
}
// github.com/bestbytes/paperroll-server/client.FilterOption
export interface FilterOption {
	value:string;
	name?:string;
	selected:boolean;
	total?:number;
	match?:number;
	enabled:boolean;
}
// github.com/bestbytes/paperroll-server/client.Info
export interface Info {
	id:string;
	name:string;
	screenName:string;
	email:string;
	status:string;
	teamOwner:boolean;
	projectOwner:boolean;
}
// github.com/bestbytes/paperroll-server/client.MemoryUsage
export interface MemoryUsage {
	raw:number;
	media:number;
	total:number;
}
// github.com/bestbytes/paperroll-server/client.Project
export interface Project {
	teamId:string;
	ownerIds:string[];
	userIds:string[];
	id:string;
	version:number;
	name:string;
	description:string;
	timestamp:number;
	tags:string[];
	memoryUsage?:github_com_bestbytes_paperroll_server_client.MemoryUsage;
}
// github.com/bestbytes/paperroll-server/client.Quota
export interface Quota {
	hasSubscription:boolean;
	productID:string;
	plan:string;
	users?:github_com_bestbytes_paperroll_server_client.Entity;
	projects?:github_com_bestbytes_paperroll_server_client.Entity;
	sketches?:github_com_bestbytes_paperroll_server_client.Entity;
}
// github.com/bestbytes/paperroll-server/client.SketchListing
export interface SketchListing {
	filters:github_com_bestbytes_paperroll_server_client.Filter[];
	summaries:github_com_bestbytes_paperroll_server_client.SketchSummary[];
	page:number;
	pages:number;
	pageSize:number;
}
// github.com/bestbytes/paperroll-server/client.SketchMeta
export interface SketchMeta {
	commandCount:number;
	duration:number;
}
// github.com/bestbytes/paperroll-server/client.SketchSize
export interface SketchSize {
	width:number;
	height:number;
}
// github.com/bestbytes/paperroll-server/client.SketchSummary
export interface SketchSummary {
	authorId:string;
	teamId:string;
	authorName:string;
	screenOwnerId:string;
	screenOwnerName:string;
	projectId:string;
	sharingToken:string;
	isPublic:boolean;
	size?:github_com_bestbytes_paperroll_server_client.SketchSize;
	scale:number;
	id:string;
	name:string;
	description:string;
	timestamp:number;
	timestampUpdated:number;
	tags:string[];
	deleted:boolean;
	deletedTimestamp:number;
	complete:boolean;
	image:string;
	backgroundImage:string;
	audioTimestamps:number[];
	audioInfos:github_com_bestbytes_paperroll_server_client.AudioInfo[];
	memoryUsage?:github_com_bestbytes_paperroll_server_client.MemoryUsage;
	serverVersion:string;
	clientVersion:string;
	originSketchID:string;
	node:number;
}
// github.com/bestbytes/paperroll-server/client.SketchTreeNode
export interface SketchTreeNode {
	id?:string;
	name?:string;
	author?:string;
	image?:string;
	children?:github_com_bestbytes_paperroll_server_client.SketchTreeNode[];
	size?:github_com_bestbytes_paperroll_server_client.SketchSize;
	selected:boolean;
	inPath:boolean;
}
// github.com/bestbytes/paperroll-server/client.StripeInfo
export interface StripeInfo {
	customerID:string;
	planIDs:string[];
}
// github.com/bestbytes/paperroll-server/client.Team
export interface Team {
	ownerId:string;
	quota?:github_com_bestbytes_paperroll_server_client.Quota;
	userIds:string[];
	projectIds:string[];
	id:string;
	version:number;
	name:string;
	description:string;
	timestamp:number;
	tags:string[];
	memoryUsage?:github_com_bestbytes_paperroll_server_client.MemoryUsage;
}
// github.com/bestbytes/paperroll-server/client.User
export interface User {
	teamIds:string[];
	status:string;
	email:string;
	firstName:string;
	lastName:string;
	passwordToken:string;
	stripeInfo?:github_com_bestbytes_paperroll_server_client.StripeInfo;
	passwordTokenTimestamp:number;
	salutation:string;
	projectIds:string[];
	id:string;
	version:number;
	name:string;
	description:string;
	timestamp:number;
	tags:string[];
}
// constants from github.com/bestbytes/paperroll-server/client
export const GoConst = {
	ContentDownloads : "downloads",
	ContentHome : "home",
	FilterTypeProject : "project",
	FilterTypeTag : "tag",
	FilterTypeTeam : "team",
	FilterTypeUser : "user",
	UserStatusConfirmed : "confirmed",
	UserStatusInvitedExistingUser : "invited-existing",
	UserStatusInvitedNewUser : "invited-new",
	UserStatusLostPassword : "lostPassword",
	UserStatusUnconfirmed : "unconfirmed",
}
// end of common js