import * as React from "react";
import { styled } from "../theme";

export interface PictureSizes {
  mobile?: number;
  tablet?: number;
  desktop?: number;
  desktopLarge?: number;
  fallback?: number;
}

export interface PictureOptions {
  src: string;
  sizes: PictureSizes;
  className?: string;
  alt?: string;
  style?: any;
  id?: string;
}

export const AvailableImageSizes = [
  100,
  150,
  200,
  300,
  400,
  500,
  600,
  800,
  1000,
  1200,
  1600,
  2000
];

const breakPointNames = ["desktopLarge", "desktop", "tablet", "mobile"];
const breakPointSizes = [1280, 1024, 768, 320];

export const breakpoints = {
  desktopLarge: 1280,
  desktop: 1024,
  tablet: 768,
  mobile: 320
};

const sizeForBucket = size => {
  switch (true) {
    case size > 1999:
      return 2000;
    case size > 1599:
      return 1600;
    case size > 1199:
      return 1200;
    case size > 999:
      return 1000;
    case size > 799:
      return 800;
    case size > 599:
      return 600;
    case size > 499:
      return 500;
    case size > 399:
      return 400;
    case size > 299:
      return 300;
    case size > 199:
      return 200;
    case size > 149:
      return 150;
    default:
      return 100;
  }
};

const glue = (src: string): string => {
  if (src === undefined) {
    src = "";
  }
  return src.indexOf("?") > -1 ? "&" : "?";
};
const source = (from: number, src: string, size: number) => {
  let g = glue(src);
  return (
    <source
      key={from}
      media={"(min-width: " + from + "px)"}
      src-set={
        src +
        g +
        "w=" +
        sizeForBucket(size) +
        ", " +
        src +
        g +
        "w=" +
        sizeForBucket(size * 2) +
        " 2x"
      }
    />
  );
};
const renderSources = (sizes: PictureSizes, src) => {
  let sources = [];
  breakPointNames.forEach((breakPointName, breakPointIndex) => {
    if (sizes.hasOwnProperty(breakPointName)) {
      sources.push(
        source(breakPointSizes[breakPointIndex], src, sizes[breakPointName])
      );
    }
  });
  return sources;
};

export const mediaUrl = (src: string, size: number) => {
  return `${src}${glue(src)}w=${size}`;
};

const StyledImage = styled.img`
  width: 100%;
`;

export class Picture extends React.Component<PictureOptions, {}> {
  render() {
    let className = this.props.className;
    if (className === undefined) {
      className = "";
    }
    return (
      <picture id={this.props.id}>
        {renderSources(this.props.sizes, this.props.src)}
        <StyledImage
          src={mediaUrl(this.props.src, this.getFallbackSize())}
          alt={this.props.alt}
          // className={"lazyload " + className}
          style={this.props.style}
        />
      </picture>
    );
  }
  private getFallbackSize() {
    if (this.props.sizes.hasOwnProperty("fallback")) {
      return sizeForBucket(this.props.sizes.fallback);
    }
    if (this.props.sizes.hasOwnProperty("desktop")) {
      return sizeForBucket(this.props.sizes.desktop);
    }
    return 300;
  }
}
