import * as React from "react";
import { Menu, SemanticICONS } from "semantic-ui-react";

import { styled, theme } from "../../../../theme";
import { FilterOption } from "../../../../services-async/vo/client";
import { UserList } from "../UserCircleList";
import { ThunkDispatch } from "redux-thunk";

export interface MenuItemProps {
  active: boolean;
  onClick: (e?: React.SyntheticEvent) => void;
  onHideList?: (e?: React.SyntheticEvent) => void;
  expandable?: boolean;
  label?: string;
  icon?: SemanticICONS;
}

export interface MenuContentItem {
  name: string;
  id: string;
  users: FilterOption[];
  projects: { id: string; name: string }[];
}

export interface MenuContent {
  closeMenu: () => void;
  items: MenuContentItem[];
  dispatch: ThunkDispatch<{}, {}, any>;
}

export const StyledQuickMenuList = styled.div`
  border: none !important;
  border-radius: 0 !important;
  width: 100% !important;
  background-color: inherit !important;
  box-shadow: none !important;
  .active {
    color: ${theme.linkColor};
  }
`;
StyledQuickMenuList.displayName = "StyledQuickMenuList";

const menuContentPadding = `
padding: 0.3rem 1rem 0.3rem 0 !important;

@media (min-width: 1024px) {
  padding: 0.3rem 1.2rem 0.3rem 0 !important;
}
`;

export const StyledMenuUserList = styled(UserList)`
  ${menuContentPadding}
`;

export const StyledQuickMenuHeader = styled(Menu.Header)`
  padding: 2rem 0 0.3rem !important;

  @media (min-width: 1024px) {
    padding: 2rem 0 0.6rem !important;
  }
`;

export const StyledQuickMenuItem = styled(Menu.Item)`
  float: left;
  color: ${theme.bodyFontColorSecondary} !important;

  :hover {
    color: ${theme.linkColor} !important;
  }
  ${menuContentPadding}
`;

export const StyledMenuItem = styled(Menu.Item)`
  padding: 0.5rem 0.5rem !important;
  cursor: pointer;
  color: ${theme.bodyFontColor};

  &:hover,
  &.active {
    color: ${theme.linkColor} !important;
  }

  @media (min-width: 1024px) {
    padding: 0.5rem 1.5rem !important;
  }
`;

export const StyledMobileQuickMenuHeader = styled(Menu.Header)`
  padding: 1rem 0 0.3rem !important;
`;

export const StyledMobileMenuItem = styled(Menu.Item)`
  color: ${theme.bodyFontColor} !important;
  padding-right: 1.7rem !important;
  &:hover {
    color: ${theme.linkColor} !important;
  }
  &.active {
    color: ${theme.linkColor} !important;
  }
`;

export const QuickMenuList: React.FC<{}> = props => (
  <StyledQuickMenuList className="ui vertical menu">
    {props.children}
  </StyledQuickMenuList>
);
