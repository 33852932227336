import React from "react";
import { styled } from "../../../theme";
import { version } from "../../../env";

export const StyledFeedbackButton = styled.div`
  position: fixed;
  top: 120px;
  right: -1.7rem;
  transform: rotate(-90deg);
  background-color: ${props => props.theme.linkColor};
  color: white;
  padding: 0.3rem;
  font-weight: bold;
  /* box-shadow: -24px 4px 37px -27px rgba(0,0,0,0.75); */
  /* box-shadow: 10px 5px 5px rgba(0,0,0,0.75); */
  box-shadow: -5px -1px 30px 6px rgba(0, 0, 0, 0.29);
  cursor: pointer;
  border-radius: 5px;
  z-index: 999999999999999;
`;

const FeedbackButton: React.FC<{}> = props => {
  return (
    <StyledFeedbackButton
      onClick={() =>
        submitFeedback("TeamSketch feedback for page " + document.location.href)
      }
    >
      feedback
    </StyledFeedbackButton>
  );
};

export default FeedbackButton;

export const submitFeedback = (subject: string) => {
  let navigatorInfo = {};
  let body = "";
  try {
    for (const np in navigator) {
      const prop = navigator[np];
      const t = typeof prop;
      if (["number", "string", "boolean"].indexOf(t) > -1) {
        switch (np) {
          case "userAgent":
          case "cookieEnabled":
          case "onLine":
            navigatorInfo[np] = prop;
        }
      }
    }
    body = `
Hint: if you are reporting an error, it would be great, if you could tell us how to reproduce it and sometimes screenshots help ;)

Please add this to your feedback, if you are comfortable with sharing the information:

version: ${version}
date: ${new Date().toString()} 
browser: ${JSON.stringify(navigatorInfo, null, 2)}
`;
  } catch (e) {
    // nothing
  }

  window.location.href =
    "mailto:support@teamsketch.app?subject=" +
    escape(subject) +
    (body ? "&body=" + escape(body) : "");
  console.log(body);
};
