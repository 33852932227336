import * as Form from '../../../form';
import {Info} from '../../../services-async/vo/client';
import {Info as UserInfo, Team} from '../../../services/vo/client';
import {actionCreator} from '../store';

export interface EditAccountState {
  id: string;
  team?: Team;
  formData: Form.FormData;
  users: UserInfo[];
}
export const EDIT_ACCOUNT_LOAD_USERS = 'EDIT_ACCOUNT_LOAD_USERS';
export const EDIT_ACCOUNT_INIT = 'EDIT_ACCOUNT_INIT';
export const EDIT_ACCOUNT_FORM_ID = 'EDIT_ACCOUNT_FORM_ID';

export const actionRefreshUsers = (teamId: string, users: UserInfo[]) =>
    actionCreator(EDIT_ACCOUNT_LOAD_USERS, {teamId, users})

export const actionInit = (team: Team, users: Info[]) =>
    actionCreator(EDIT_ACCOUNT_INIT, {team, users})



const getValidator = () => {
  return {
    name: Form.Validation.combine(Form.Validation.required()),
    description: Form.Validation.combine(Form.Validation.required())
  };
};

const createState = (team: Team|undefined = undefined, users: Info[] = []) => {
  return {
    team,
    id: team ? team.id : '',
    users: users,
    formData: Form.formInputDataWithValues(EDIT_ACCOUNT_FORM_ID, {
      name: team ? team.name : '',
      description: team ? team.description : ''
    })
  };
};

type ActionTypes =
    ReturnType<typeof actionRefreshUsers>| ReturnType<typeof actionInit>;

export const editAccount = (state: EditAccountState, action: ActionTypes) => {
  var formReducer =
      Form.reducerForPrefix(EDIT_ACCOUNT_FORM_ID, 'formData', getValidator());
  state = formReducer(state, action);
  if (state === undefined) {
    return createState();
  }
  switch (action.type) {
    case EDIT_ACCOUNT_LOAD_USERS:
      if (state.team && action.payload.teamId === state.team.id) {
        return {...state, users: action.payload.users};
      }
      break;
    case EDIT_ACCOUNT_INIT:
      return createState(action.payload.team, action.payload.users);
  }
  return state;
};
