import { SelectionMode, styled, styleForSelectionMode } from "../../../theme";

export const Tag = styled.div<{ mode?: SelectionMode }>`
  display: inline-block;
  line-height: 25px;
  padding: 6px 18px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-width: 1px !important;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 30px;//${props => props.theme.cornerRadius};
  color: white; //rgba(0, 0, 0, 0.55);
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  background-color: #34495e66;
  ${styleForSelectionMode}
`;

export const StyledNakedTag = styled.span`
  text-transform: uppercase;
  display: inline-block;
  margin-left: 15px;
  color: #ccc !important;
  font-weight: 600;
  padding: 10px 3px;
  font-size: 1rem;
`;
