import * as React from "react";
import { Grid } from "semantic-ui-react";

import { GoConst } from "../../../../services-async/vo/client";
import { styled } from "../../../../theme";
import { PAGES } from "../../Page";
import Router from "../../router";
import NoTeams from "../teams/NoTeams";
import {
  MenuContent,
  StyledMenuUserList,
  StyledQuickMenuHeader,
  StyledQuickMenuItem
} from "./components";

const StyledRow = styled(Grid.Row)``;

const StyledNoItems = styled.div`
  padding-top: 45px;
  max-width: 450px;
`;

export const TeamsMenuContent: React.FC<MenuContent> = props => {
  return (
    <div>
      {props.items.length === 0 && (
        <StyledNoItems>
          <NoTeams />
        </StyledNoItems>
      )}
      <Grid>
        <StyledRow>
          {props.items.map((item, index) => (
            <Grid.Column
              mobile={16}
              tablet={8}
              computer={4}
              key={item.name + "-" + index}
            >
              <a
                href={"/" + PAGES.team + "/" + item.id}
                onClick={e => {
                  e.preventDefault();
                  Router.team(item.id);
                  props.closeMenu();
                }}
              >
                <StyledQuickMenuHeader content={item.name} />
              </a>
              <StyledMenuUserList
                size={25}
                limit={20}
                users={item.users ? item.users : []}
                onUserClick={option => {
                  Router.user(option.value, [
                    {
                      filterType: GoConst.FilterTypeTeam,
                      options: [
                        { enabled: true, selected: true, value: item.id }
                      ]
                    }
                  ]);
                  props.closeMenu();
                }}
              />
              {item.projects &&
                item.projects.map(project => (
                  <a
                    href={"/" + PAGES.project + "/" + project.id}
                    onClick={e => {
                      e.preventDefault();
                      Router.project(project.id);
                      props.closeMenu();
                    }}
                    key={project.id}
                  >
                    <StyledQuickMenuItem content={project.name} />
                  </a>
                ))}
            </Grid.Column>
          ))}
        </StyledRow>
      </Grid>
    </div>
  );
};

TeamsMenuContent.displayName = "MainMenuDesktopContents";
