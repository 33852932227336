import * as React from "react";
import { connect } from "react-redux";
import { State } from "../../store";
import { Header, Button } from "semantic-ui-react";
import Router from "../../router";

export const PricingTeaser = connect((state: State) => ({
  pricing: state.env.pricing.show
}))((props: { pricing: boolean }) =>
  props.pricing ? (
    <>
      <Header as="h3" content="Pricing" />
      <p>See our pricing table for all available options.</p>
      <Button basic onClick={e => Router.pricing()}>
        Helpcenter
      </Button>
    </>
  ) : (
    <></>
  )
);
