import * as React from "react";
import { connect } from "react-redux";
import { Dimmer, Loader } from "semantic-ui-react";

import { PricingPage } from "./pages/pricing";
import { ProjectPage } from "./pages/project";
import { ContactPage } from "./pages/contact";
import { DevStaticPage } from "./pages/devStatic";
import { DownloadsPage } from "./pages/downloads";
import { FAQPage } from "./pages/faq";
import { GetStartedPage } from "./pages/getstarted";
import { HomePage } from "./pages/home";
import { ImprintPage } from "./pages/imprint";
import { TrashPage } from "./pages/trash";
import UserSketchesPage from "./pages/UserSketches";
import { State } from "./store";
import { SignupSuccessPage } from "./pages/signupSuccess";

/**
 * Async loaded pages
 */
const ForgotPasswordPage = React.lazy(() =>
  import("./pages/account/ForgotPasswordPage")
);
const ForgotPasswordSuccess = React.lazy(() =>
  import("./pages/account/ForgotPasswordSuccess")
);
const LoginPage = React.lazy(() => import("./pages/account/LoginPage"));
const ResetPasswordPage = React.lazy(() =>
  import("./pages/account/ResetPasswordPage")
);
const SignupPage = React.lazy(() => import("./pages/account/SignupPage"));
const UserPage = React.lazy(() => import("./pages/account/UserPage"));
const EditProjectPage = React.lazy(() => import("./pages/editProject"));
const EditTeamPage = React.lazy(() => import("./pages/EditTeam"));

const InviteUserConfirmationPage = React.lazy(() =>
  import("./pages/InviteUserConfirmationPage")
);
const SearchPage = React.lazy(() => import("./pages/search"));
const SketchPage = React.lazy(() => import("./pages/sketch"));
const SubscribePage = React.lazy(() => import("./pages/subscribe/subscribe"));
const TagSketchesPage = React.lazy(() => import("./pages/TagSketches"));
const TeamPage = React.lazy(() => import("./pages/Team"));
const TeamsPage = React.lazy(() => import("./pages/Teams"));

const LegalPage = React.lazy(() => import("./pages/legal"));
const DataProtectionPage = React.lazy(() => import("./pages/DataProtection"));

export enum PAGES {
  subscribe = "subscribe",
  home = "home",
  getstarted = "getstarted",
  pricing = "pricing",
  faq = "faq",
  invite = "invite",
  contact = "contact",
  downloads = "downloads",
  login = "login",
  signup = "signup",
  signupSuccess = "signup-success",
  project = "project",
  team = "team",
  sketch = "sketch",
  teams = "teams",
  profile = "profile",
  editTeam = "edit-team",
  editProject = "edit-project",
  resetPassword = "reset-password",
  forgotPassword = "forgot-password",
  forgotPasswordSuccess = "forgot-password-success",
  invitedNewUser = "invited-new-user",
  trash = "trash",
  user = "user",
  search = "search",
  tag = "tag",
  imprint = "imprint",
  legal = "legal",
  dataProtection = "data-protection",
  devStatic = "dev-static",
  none = "",
  noTeams = "no-teams"
}

export const uriForPage = (p:PAGES) => "/"+p;

const Page: React.FC<{ page: string; state: State }> = ({ state, page }) => {
  switch (page) {
    case PAGES.teams:
      return <TeamsPage />;
    case PAGES.team:
      return <TeamPage />;
    case PAGES.home:
      return <HomePage />;
    case PAGES.downloads:
      return <DownloadsPage />;
    case PAGES.getstarted:
      return <GetStartedPage />;
    case PAGES.pricing:
      return <PricingPage />;
    case PAGES.faq:
      return <FAQPage />;
    case PAGES.contact:
      return <ContactPage />;
    case PAGES.signup:
      return <SignupPage />;
    case PAGES.signupSuccess:
      return <SignupSuccessPage />;
    case PAGES.project:
      return <ProjectPage state={state} />;
    case PAGES.login:
      return <LoginPage />;
    case PAGES.profile:
      return <UserPage />;
    case PAGES.user:
      return <UserSketchesPage />;
    case PAGES.subscribe:
      return <SubscribePage />;
    case PAGES.tag:
      return <TagSketchesPage />;
    case PAGES.search:
      return <SearchPage />;
    case PAGES.editTeam:
      return <EditTeamPage />;
    case PAGES.editProject:
      return <EditProjectPage />;
    case PAGES.sketch:
      return <SketchPage />;
    case PAGES.trash:
      return <TrashPage state={state} />;
    case PAGES.resetPassword:
      return <ResetPasswordPage />;
    case PAGES.forgotPassword:
      return <ForgotPasswordPage />;
    case PAGES.forgotPasswordSuccess:
      return <ForgotPasswordSuccess />;
    case PAGES.legal:
      return <LegalPage />;
    case PAGES.dataProtection:
      return <DataProtectionPage />;
    case PAGES.imprint:
      return <ImprintPage />;
    case PAGES.invitedNewUser:
      return <InviteUserConfirmationPage />;
    case PAGES.devStatic:
      return <DevStaticPage {...state.devStatic} />;
  }
  return <PageSpinner />;
};

export const PageSpinner: React.FC<{}> = () => {
  return (
    <div style={{ position: "relative" }}>
      <Dimmer active inverted>
        <Loader size="large" />
      </Dimmer>
      <div style={{ minHeight: "60vh" }} />
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  state,
  page: state.page.name
});

export default connect(mapStateToProps)(Page);
