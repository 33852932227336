import * as Form from "../../../form";
import { Info as UserInfo, Project } from "../../../services/vo/client";
import * as Actions from "../actions";


export interface EditProjectState {
  project?: Project;
  id: string;
  formData: Form.FormData;
  userInfos: UserInfo[];
}

export const EDIT_PROJECT_FORM_ID = "EDIT_PROJECT_FORM_ID";

const getValidator = () => {
  return {
    name: Form.Validation.combine(
      Form.Validation.minLength(3),
      Form.Validation.maxLength(128)
    ),
    description: Form.Validation.combine(
      Form.Validation.maxLength(256)
    )
  };
};

const createState = (
  project: Project | undefined = undefined
): EditProjectState => {
  let name: string = "";
  let description: string = "";
  let id = "";

  if (project) {
    id = project.id;
    name = project.name;
    description = project.description;
  }
  const formData = Form.formInputDataWithValues(EDIT_PROJECT_FORM_ID, {
    name,
    description
  });
  return {
    project,
    id,
    userInfos: [],
    formData
  };
};

export const editProject = (
  state: EditProjectState | undefined,
  action: any
): EditProjectState => {
  var formReducer = Form.reducerForPrefix(
    EDIT_PROJECT_FORM_ID,
    "formData",
    getValidator()
  );
  state = formReducer(state, action);
  if (state === undefined) {
    return createState();
  }
  switch (action.type) {
    case Actions.LOAD_PROJECT_USER_INFOS:
      return Object.assign({}, state, { userInfos: action.userInfos });
    case Actions.EDIT_PROJECT:
      let project: Project = action.project;
      return createState(project);
  }
  return state;
};
