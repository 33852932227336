import * as Actions from "../actions";
export interface RouterState {
  forbidden?: string;
}
export const router = (state: any, action: any) => {
  if (state === undefined) {
    return {};
  }
  switch (action.type) {
    case Actions.SET_FORBIDDEN_ROUTE:
      return Object.assign({}, state, { forbidden: action.route });
  }
  return state;
};
