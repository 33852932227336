import Page from "page";
import { Store } from "redux";
import { ThunkDispatch } from "redux-thunk";

import * as GeneralActions from "../../actions";
import { Filter } from "../../services/vo/client";

import * as Actions from "./actions";
import { prepareEditTeamPage } from "./actions/team";
import {
  useConfirmationToken,
  useInviteToken,
  useLoginToken,
  useResetToken
} from "./actions/token";
import { PAGES } from "./Page";
import { actionSetTeamId } from "./reducers/subsribe";
import { State } from "./store";

const searchQueryParamName = "q";
const encodeList = (l: string[]) =>
  l.map(le => encodeURIComponent(le)).join(",");

const extractSketchListingFilters = (
  ctx: PageJS.Context,
  skipQ: boolean = true
): Filter[] => {
  let filters: Array<any> = [];
  if (ctx.querystring.length > 1) {
    filters = ctx.querystring.split("&").map(pair => {
      let filterType = "";
      let value: Array<string> = [];
      pair.split("=").map((v, i) => {
        switch (i) {
          case 0:
            filterType = v;
            break;
          case 1:
            value = v.split(",").map(v => decodeURIComponent(v));
            break;
        }
        return v;
      });
      return {
        filterType,
        options: value.map(v => {
          return { value: v, name: v, selected: true, total: 0, match: 0 };
        })
      };
    });
  }
  return filters.filter(f => {
    if (skipQ && f.filterType === searchQueryParamName) {
      return false;
    } else {
      return true;
    }
  });
};

export const boot = (root: string, store: Store<State>): void => {
  Page.base(root);
  const dispatch = store.dispatch as ThunkDispatch<{}, {}, any>;
  const loggedIn = () => !!store.getState().user.session;
  const checkSession = (ctx: PageJS.Context) => {
    if (loggedIn()) {
      return true;
    }
    Actions.setforbiddenRoute(ctx.path);
    GeneralActions.setPage(PAGES.login);
    return false;
  };

  // pages using default setup
  const defaultSetupPages: PAGES[] = [
    PAGES.contact,
    PAGES.login,
    PAGES.legal,
    PAGES.dataProtection,
    PAGES.imprint,
    PAGES.signup,
    PAGES.forgotPassword,
    PAGES.faq,
    PAGES.pricing,
    PAGES.getstarted,
    PAGES.devStatic
  ];

  // setup all pages that only require basic handling e.g. changing page and no other actions
  defaultSetupPages.forEach(page =>
    Page("/" + page, ctx => GeneralActions.setPage(page, ctx.params))
  );

  /**
   * Custom pages
   */
  Page(`/${PAGES.editTeam}/:id`, ctx => {
    if (checkSession(ctx)) {
      // init page content
      dispatch(prepareEditTeamPage(ctx.params.id));
      // set page
      GeneralActions.setPage(PAGES.editTeam, ctx.params);
    }
  });
  Page(`/${PAGES.noTeams}`, (ctx: PageJS.Context) => {
    dispatch(prepareEditTeamPage(null));
    GeneralActions.setPage(PAGES.editTeam, ctx.params);
  });

  Page(`/${PAGES.editProject}/:id`, ctx => {
    if (checkSession(ctx)) {
      Actions.editProject(ctx.params.id);
    }
  });

  Page(`/${PAGES.project}/:id`, ctx => {
    if (checkSession(ctx)) {
      Actions.getProject(ctx.params.id, extractSketchListingFilters(ctx));
    }
  });

  Page(`/${PAGES.team}/:id`, ctx => {
    if (checkSession(ctx)) {
      GeneralActions.setPage(PAGES.team, ctx.params);
    }
  });

  Page(`/${PAGES.subscribe}/:id`, ctx => {
    if (checkSession(ctx)) {
      // set teamId
      dispatch(actionSetTeamId(ctx.params.id));

      // set page
      GeneralActions.setPage(PAGES.subscribe, ctx.params);
    }
  });

  Page(`/${PAGES.sketch}/:id`, ctx => {
    Actions.getSketchSummary(ctx.params.id);
  });

  Page(`/${PAGES.user}/:id`, ctx => {
    if (checkSession(ctx)) {
      Actions.getUser(ctx.params.id, extractSketchListingFilters(ctx));
    }
  });

  Page(`/${PAGES.tag}/:tag`, ctx => {
    if (checkSession(ctx)) {
      Actions.getSketchesForTag(
        ctx.params.tag,
        extractSketchListingFilters(ctx)
      );
    }
  });

  Page(`/${PAGES.trash}`, ctx => {
    if (checkSession(ctx)) {
      Actions.getSketchesInTrash("");
    }
  });

  Page(`/${PAGES.trash}/:projectID`, ctx => {
    if (checkSession(ctx)) {
      Actions.getSketchesInTrash(ctx.params.projectID);
    }
  });

  Page("/reset-token/:id", ctx => {
    dispatch(useResetToken(ctx.params.id));
  });

  Page("/invite-token/:id", ctx => {
    dispatch(useInviteToken(ctx.params.id));
  });

  Page("/confirmation-token/:id", ctx => {
    dispatch(useConfirmationToken(ctx.params.id));
  });

  Page("/token/:id", ctx => {
    dispatch(useLoginToken(ctx.params.id));
  });

  Page("/" + PAGES.downloads, ctx => {
    if (ctx.path === "/downloads/") {
      document.location.reload(true);
    }
    GeneralActions.setPage(PAGES.downloads);
  });

  Page("/" + PAGES.profile, ctx => {
    if (checkSession(ctx)) {
      console.log("go profile");
      GeneralActions.setPage(PAGES.profile);
    }
  });

  Page("/", ctx => {
    ctx.querystring.split("&").map(paramValue => {
      const parts = paramValue.split("=");
      const name = parts[0];
      switch(name) {
        case "no-pricing":
          Actions.envPricingShow(false);
          break;
        case "show-pricing":
          Actions.envPricingShow(true);
          break;
      }
      return paramValue;
    })
    if (loggedIn()) {
      let q: string | undefined = undefined;
      const filters = extractSketchListingFilters(ctx, false).filter(f => {
        if (f.filterType === searchQueryParamName) {
          q = f.options[0].value;
          return false;
        } else {
          return true;
        }
      });
      if (q !== undefined) {
        Actions.searchSketches(q, filters);
        GeneralActions.setPage(PAGES.search);
      } else {
        Actions.home();
        GeneralActions.setPage(PAGES.teams);
      }
    } else {
      GeneralActions.setPage(PAGES.home);
    }
  });

  Page();
};

const filterQuery = (filters: Filter[]) => {
  let q = filters
    .filter(f => f.options.filter(o => o.selected).length > 0)
    .map(f => {
      return (
        f.filterType +
        "=" +
        encodeList(f.options.filter(o => o.selected).map(o => o.value))
      );
    });
  return q.length > 0 ? "?" + q.join("&") : "";
};

const f = (page: PAGES, id: string, filters: Filter[]) =>
  Page("/" + page + "/" + encodeURIComponent(id) + filterQuery(filters));

const defaultRouter = (page: PAGES) => Page(`/${page}`);

/**
 * General use router
 */
const Router = {
  home: () => Page("/"),
  login: () => defaultRouter(PAGES.login),
  profile: () => defaultRouter(PAGES.profile),
  signup: () => defaultRouter(PAGES.signup),
  contact: () => defaultRouter(PAGES.contact),
  getstarted: () => defaultRouter(PAGES.getstarted),
  pricing: () => defaultRouter(PAGES.pricing),
  faq: () => defaultRouter(PAGES.faq),
  downloads: () => defaultRouter(PAGES.downloads),
  forgotPasswordPage: () => defaultRouter(PAGES.forgotPassword),
  devStatic: () => defaultRouter(PAGES.devStatic),
  legal: () => defaultRouter(PAGES.legal),
  imprint: () => defaultRouter(PAGES.imprint),
  go: (uri: string) => Page(uri),
  user: (id: string, filters: Filter[] = []) => {
    f(PAGES.user, id, filters);
  },
  sketch: (id: string) => Page("/" + PAGES.sketch + "/" + id),
  trash: (projectID?: string) =>
    Page("/" + PAGES.trash + (projectID ? "/" + projectID : "")),

  tryForbidden: (forbidden: string) => {
    Actions.setforbiddenRoute(forbidden);
    Page(forbidden);
  },

  editTeam: (id: string | null) => {
    if (id === null) {
      Page("/" + PAGES.noTeams);
    } else {
      Page("/" + PAGES.editTeam + "/" + id);
    }
  },
  editProject: (id: string) => Page("/" + PAGES.editProject + "/" + id),

  subscribe: (id: string, filters: Filter[] = []) => {
    f(PAGES.subscribe, id, filters);
  },

  project: (id: string, filters: Filter[] = []) => {
    f(PAGES.project, id, filters);
  },
  team: (id: string, filters: Filter[] = []) => {
    f(PAGES.team, id, filters);
  },

  tag: (tag: string, filters: Filter[] = []) => {
    f(PAGES.tag, tag, filters);
  },

  search: (q: string, filters: Filter[] = []) => {
    let f = filterQuery(filters);
    if (f.length > 0) {
      f = "&" + f.substr(1);
    }
    Page("/?q=" + encodeURIComponent(q) + f);
  }
};

export default Router;
