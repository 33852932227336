import * as React from "react";
import { Button, Grid, Header } from "semantic-ui-react";
import { Section } from "../../../components/section";
import { SignUp } from "../components/content/Signup";
import { ContentImage, PageContainer, styled, theme } from "../../../theme";
import { Spacer, SpacerSize } from "../../../components/spacer";
import { uriForPage, PAGES } from "../Page";
import { useTranslation } from "react-i18next";

const Circle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 10px 15px;
  background-color: #eee;
`;

const CircleView = styled.div`
  display: inline-block;

  width: 50px;
  height: 50px;
  @media (min-width: 768px) {
    position: absolute;
    left: -100px;
    top: 40px;
  }
  font-size: 1.5rem;
  background-color: ${theme.linkColor};
  border-radius: 50%;
  text-align: center;
  &::after {
    line-height: 47px;
    counter-increment: step;
    content: counter(step);
    color: #fff;
  }
`;

const Elipsis: React.FC<{ count?: number }> = props => {
  const els: React.ReactElement[] = [];
  const count = props.count ? props.count : 5;
  for (let i = 0; i < count; i++) {
    els.push(<Circle />);
  }
  return <>{els}</>;
};

const Step: React.FC<{
  title: React.ReactNode;
  subtitle: string;
  isLast?: boolean;
}> = props => {
  return (
    <>
      <div style={{ position: "relative" }}>
        <CircleView />
        <Header as="h3" content={props.title} />
        <p>{props.subtitle}</p>
        {props.children}
      </div>
      {!props.isLast && (
        <>
          <Spacer size={SpacerSize.tiny} />
          <Elipsis />
        </>
      )}
    </>
  );
};

export const GetStartedPage: React.FC<{}> = props => {
  const { t } = useTranslation();
  return (
    <>
      <Section>
        <PageContainer>
          <Grid verticalAlign="middle">
            <Grid.Row>
              <Grid.Column
                mobile={null}
                tablet={1}
                computer={2}
                widescreen={3}
              />
              <Grid.Column mobile={16} tablet={7} computer={6} widescreen={5}>
                <Header as="h1" content="Get started" />
                <p>Get up and running with TeamSketch in 1-2-3 steps</p>
                <Spacer />
                <Step
                  title={t("GET_STARTED_STEP_1_TITLE")}
                  subtitle={t("GET_STARTED_STEP_1_SUBTITLE")}
                >
                  <SignUp />
                </Step>
                <Step
                  title={t("GET_STARTED_STEP_2_TITLE")}
                  subtitle={t("GET_STARTED_STEP_2_SUBTITLE")}
                >
                  <Button as="a" primary href={uriForPage(PAGES.downloads)}>
                    Go to downloads
                  </Button>
                </Step>
                <Step
                  isLast
                  title={t("GET_STARTED_STEP_3_TITLE")}
                  subtitle={t("GET_STARTED_STEP_3_SUBTITLE")}
                >
                  <Button as="a" href={uriForPage(PAGES.faq)}>
                    FAQ
                  </Button>
                </Step>
                <Spacer />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={7} computer={6} widescreen={5}>
                <div>
                  <ContentImage src="/img/ts-mockup.jpg" />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </PageContainer>
      </Section>
      <Section>
        <PageContainer>
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={2} tablet={1} computer={2} widescreen={3} />
              <Grid.Column mobile={14} tablet={14} computer={8} widescreen={6}>
                .
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </PageContainer>
      </Section>
    </>
  );
};
