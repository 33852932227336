import { SketchListing } from "../../../services/vo/client";

/**
 * appendToListing will append a listing to a existing object of type T.
 * It is only required that T has at least the optional key listing.
 */
export const appendToListing = <T extends { listing?: SketchListing }>(
  state: T,
  action: { listing: SketchListing }
) => {
  let listing = Object.assign({}, action.listing, {
    summaries: state.listing.summaries.concat(action.listing.summaries)
  });
  return Object.assign({}, state, { listing }) as T;
};
