import * as React from "react";
import { Grid, Header } from "semantic-ui-react";
import { Section } from "../../../components/section";
import { ContentImage, PageContainer, SectionDivider } from "../../../theme";
import { Spacer } from "../../../components/spacer";
import { Vimeo } from "../../../components/Vimeo";

interface FAQContent {
  title: string;
  id: string;
  text?: string | React.ReactElement;
  vimeo?: { id: string; previewId: string };
}

interface ContentBlock {
  title: string;
  id: string;
  contents: Array<FAQContent>;
}

const contentBlocks: Array<ContentBlock> = [
  {
    id: "onboarding",
    title:
      "Onboarding",
    contents: [
      {
        id: "account-creation",
        title: "creating an account",
        text: (
          <p>
            You can <a href={"/signup"}>signup here</a> or ask a team mate to be
            invited into an existing team
          </p>
        )
        // vimeo: { id: "365696229", previewId: "821524896" }
      }
    ]
  },
  {
    id: "ipad-gestures",
    title: "TeamSketch on iPad - Gestures",
    contents: [
      {
        id: "drawing-gestures",
        title: "Standard drawing gestures",
        text:
          "Navigating your sketch - zoom, pan, rotate and reset your viewport.",
        vimeo: { id: "365696229", previewId: "821524896" }
      },
      {
        id: "color-picking",
        title: "Picking colors",
        text:
          <p>A long press with your finger anywhere on the canvas will bring up a color picker</p>
      },
      {
        id: "undo-redo",
        title: "Undo / Redo",
        text:
          "I you do not have the time to go to the menu buttons, use these gestures",
      }
    ]
  }
];

const getAnchor = (blockID: string, contentID: string) =>
  blockID + "-" + contentID;

const ContentBlockToc = (props: ContentBlock) => (
  <>
    <p>{props.title}</p>
    <ul>
      {props.contents.map(c => {
        const anchor = getAnchor(props.id, c.id);
        return (
          <li key={anchor}>
            <a href={"#" + anchor}>
              {c.title}{" "}
            </a>
          </li>
        );
      })}
    </ul>
  </>
);
const ContentBlock = (props: ContentBlock) => (
  <>
    <h2>{props.title}</h2>
    {props.contents.map((c, i) => {
      const anchor = getAnchor(props.id, c.id);
      return (
        <>
          {i > 0 && <hr />}
          <div key={anchor}>
            <a id={anchor} href={"#" + anchor}>
              {c.title}
            </a>
            {c.text && typeof c.text === "string" ? <p>{c.text}</p> : c.text}
            {c.vimeo && (
              <Vimeo
                id={c.vimeo.id}
                previewId={c.vimeo.previewId}
                cookies={false}
              />
            )}
          </div>
          <Spacer />
        </>
      );
    })}
  </>
);

export const FAQPage = (props: {}) => {
  return (
    <>
      <Section>
        <PageContainer>
          <Grid verticalAlign="middle">
            <Grid.Row>
              <Grid.Column
                mobile={null}
                tablet={1}
                computer={2}
                widescreen={3}
              />
              <Grid.Column mobile={16} tablet={7} computer={6} widescreen={5}>
                <Header as="h1" content="Have a Question?" />
                {contentBlocks.map(cb => (
                  <ContentBlockToc key={cb.id} {...cb} />
                ))}
              </Grid.Column>
              <Grid.Column mobile={16} tablet={7} computer={6} widescreen={5}>
                <div>
                  <ContentImage src="/img/getstarted-head.jpg" />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </PageContainer>
      </Section>
      <Section>
        <PageContainer>
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={1} tablet={1} computer={2} widescreen={3} />
              <Grid.Column mobile={15} tablet={14} computer={10} widescreen={8}>
                {contentBlocks.map(cb => (
                  <ContentBlock key={cb.id} {...cb} />
                ))}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </PageContainer>
      </Section>

      <PageContainer>
        <SectionDivider />
      </PageContainer>
    </>
  );
};
