import * as Actions from "./actions";

export interface InputData {
  id: string;
  prefix: string;
  value?: any;
  validation?: Validation;
}

export interface PasswordInputData extends InputData {
  passwordScore?: number;
}

export interface FormData {
  [index: string]: InputData;
}

export interface FormValue {
  [index: string]: any;
}

export const reducerForPrefix = (
  prefix: string,
  prop: string,
  validator: FormValidator
): any => {
  return (
    state: any,
    action: { type: string; inputData: InputData; value: any }
  ): FormData => {
    if (
      action.type === Actions.FORM_INPUT &&
      action.inputData.prefix === prefix
    ) {
      let formState: FormData = state[prop];
      let newInputData = {
        id: action.inputData.id,
        prefix: action.inputData.prefix,
        validation: action.inputData.validation,
        value: action.value
      };
      let formChange: { [k: string]: typeof newInputData } = {};

      formChange[action.inputData.id] = newInputData;

      formState = Object.assign({}, formState, formChange);
      formState[action.inputData.id] = validateField(
        formState,
        action.inputData.id,
        validator
      );

      let change: { [k: string]: FormData } = {};
      change[prop] = formState;

      return Object.assign({}, state, change);
    }
    return state;
  };
};

export const inputData = (
  prefix: string,
  id: string,
  value: any = "",
  validation: Validation | undefined = undefined
) => {
  return {
    prefix,
    id,
    value: value,
    validation: validation
  };
};

export function formInputData(prefix: string, fields: string[]): FormData {
  let formData: FormData = {};
  fields.forEach(key => {
    formData[key] = inputData(prefix, key);
  });
  return formData;
}

export function formInputDataWithValues(
  prefix: string,
  fieldValues: { [index: string]: any }
): FormData {
  let formData: FormData = {};
  for (let key in fieldValues) {
    formData[key] = inputData(prefix, key, fieldValues[key]);
  }
  return formData;
}

export interface Validation {
  valid?: boolean;
  feedback?: string;
}

export interface Validator {
  (value: any): Validation;
}

export interface ElementValidator {
  validate(value: any): Validation;
}

export const validationResult = (
  valid: boolean,
  feedback?: string
): Validation => {
  return {
    valid,
    feedback
  };
};

export interface FormValidator {
  [index: string]: Validator;
}

export const formIsValid = (
  state: FormData,
  validator: FormValidator
): boolean => {
  let validatedState = validateForm(state, validator);
  for (let prop in state) {
    let validation = validatedState[prop].validation;
    if (validation === undefined) {
      return false;
    }
    if (validation.valid === false) {
      return false;
    }
  }
  return true;
};

export const formValue = (formData: FormData): FormValue => {
  let formValue: { [k: string]: any } = {};
  for (let prop in formData) {
    formValue[prop] = formData[prop].value;
  }
  return formValue;
};

export const validateField = (
  state: FormData,
  prop: string,
  validator: FormValidator
): InputData => {
  if (validator.hasOwnProperty(prop)) {
    if (state.hasOwnProperty(prop) && "object" === typeof state[prop]) {
      return {
        prefix: state[prop].prefix,
        id: state[prop].id,
        value: state[prop].value,
        validation: validator[prop](state[prop].value)
      };
    }
  } else {
    return {
      prefix: state[prop].prefix,
      id: state[prop].id,
      value: state[prop].value,
      validation: {
        valid: true
      }
    };
  }
  return {} as InputData;
};

export const validateForm = (
  state: FormData,
  validator: FormValidator,
  fields: string[] = []
): FormData => {
  let validatedState: FormData = {};
  for (let prop in state) {
    if (fields.length === 0 || fields.indexOf(prop) > -1) {
      validatedState[prop] = validateField(state, prop, validator);
    } else {
      validatedState[prop] = state[prop];
    }
  }
  return validatedState;
};

function numberLength(value: number | any) {
  if ("number" === typeof value) {
    return value.toString().length;
  }
  return 0;
}

function hasLength(value: string | object | any) {
  let t = typeof value;
  switch (t) {
    case "string":
      return true;
    case "object":
      if (value && value.hasOwnProperty("length")) {
        return true;
      }
  }
  return false;
}

export class Validation {
  static combine(...validators: Validator[]): Validator {
    return value => {
      for (let i in validators) {
        let r = validators[i](value);
        if (!r.valid) {
          return r;
        }
      }
      return validationResult(true, undefined);
    };
  }

  static minLength(min: number): Validator {
    return value => {
      let valid = false;
      if (hasLength(value)) {
        valid = value.length >= min;
      } else {
        if ("number" === typeof value) {
          valid = numberLength(value) >= min;
        }
      }
      return validationResult(valid, valid ? undefined : "too short");
    };
  }

  static maxLength(max: number): Validator {
    return value => {
      let valid = false;
      if (hasLength(value)) {
        valid = value.length <= max;
      } else {
        if ("number" === typeof value) {
          valid = numberLength(value) <= max;
        }
      }
      return validationResult(valid, valid ? undefined : "too long");
    };
  }

  static mustBeTrue(feeback: string): Validator {
    return value => {
      let valid = "boolean" === typeof value && value === true;
      return validationResult(valid, valid ? undefined : feeback);
    };
  }

  static mustBePositive(feeback: string): Validator {
    return value => {
      let valid = value >= 0;
      return validationResult(valid, valid ? undefined : feeback);
    };
  }

  static isMailAddress(): Validator {
    const pattern = /^[a-z0-9\u007F-\uffff!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9\u007F-\uffff!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9\u007F-\uffff](?:[a-z0-9\u007F-\uffff-]*[a-z0-9\u007F-\uffff])?\.)+[a-z]{2,}$/i;
    return value => {
      let valid =
        "string" === typeof value &&
        value.length > 0 &&
        pattern.exec(value) !== null;
      return validationResult(valid, valid ? undefined : "invalid email");
    };
  }

  static isPassword(): Validator {
    return Validation.combine(
      Validation.minLength(6),
      Validation.maxLength(128)
    );
  }

  static required(): Validator {
    return value => {
      let valid = false;
      switch (typeof value) {
        case "string":
        case "object":
          if (hasLength(value)) {
            valid = value.length > 0;
          }
          break;
        case "boolean":
          valid = true;
          break;
        default:
        // throw new Error("wtf " + (typeof value));
      }
      return validationResult(valid, valid ? undefined : "required");
    };
  }
}
