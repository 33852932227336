import * as GeneralActions from '../../../actions';
import {ServiceClient as ProjectsClient} from '../../../services/projects';
import {ServiceClient as TagsClient} from '../../../services/tags';
import * as Actions from '../actions';
import {numberOfSketchesPerPage} from '../components/sketchList/filteredSketchList';
import * as Middleware from '../middleware';
import {PAGES} from '../Page';
import {State} from '../store';

export const SKETCH_DELETION_CONTEXT = 'tags';

export const tagMiddleware = (store: any) => (next: any) => (action: any) => {
  let st: State = store.getState();
  switch (action.type) {
    case Actions.DELETE_SKETCHES:
      if (action.context === SKETCH_DELETION_CONTEXT) {
        ProjectsClient.defaultInst.deleteSketches(action.sketchIds, err => {
          // Actions.getProject(st.project.project.id,
          // st.project.listing.filters);
          console.log('filter tags and stuff');
        }, Middleware.handleErr(store.dispatch));
      }
      break;
    case Actions.GET_SKETCHES_FOR_TAG_NEXT_PAGE:
      if (st.tag.listing.page < st.tag.listing.pages) {
        TagsClient.defaultInst.getSketchesForTag(
            st.tag.tag, st.tag.listing.filters, st.tag.listing.page + 1,
            numberOfSketchesPerPage(), (listing, err) => {
              Actions.loadSketchesForTagNextPage(action.tag, listing);
            }, Middleware.handleErr(store.dispatch));
      }
      break;
    case Actions.GET_SKETCHES_FOR_TAG:
      TagsClient.defaultInst.getSketchesForTag(
          action.tag, action.filters, 0,
          numberOfSketchesPerPage(), (listing, err) => {
            Actions.loadSketchesForTag(action.tag, listing);
            GeneralActions.setPage(PAGES.tag);
          }, Middleware.handleErr(store.dispatch));
      break;
  }
  return next(action);
};
