import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";

import { actionAddErrorToast } from "../../../reducers/toast";
import { ServiceClient as SubscriptionClient } from "../../../services-async/subscriptions";
import { getClient } from "../../../services-async/transport";
import { SubscriptionProduct } from "../../../services/vo/billing";
import { handleErr } from "../middleware";
import { actionCreator, State } from "../store";

const SUB_RESET = "SUB_RESET";
const SUB_COMPLETE_PAGE = "SUB_COMPLETE_PAGE";
const SUB_SET_TEAM_ID = "SUB_SET_TEAM_ID";
const SUB_FINALIZE = "SUB_FINALIZE";
const SUB_SET_PLAN_ID = "SUB_SET_PLAN_ID";
const SUB_SET_PAGE_INDEX = "SUB_SET_PAGE_INDEX";
const SUB_SET_PAYMENT_METHOD_ID = "SUB_SET_PAYMENT_METHOD_ID";
const SUB_SET_BILLING_RATE = "SUB_SET_BILLING_RATE";
const SUB_SET_AVAILABLE_PLANS = "SUB_SET_AVAILABLE_PLANS";

export interface SubscribeState {
  teamId?: string;
  planId?: string;
  billingRate?: string;
  availablePlans?: SubscriptionProduct[];
  paymentMethodId?: string;
  pageIndex: number;
  lastCompletedPage: number;
  finalized?: boolean;
}
export const actionSetTeamId = (teamId: string | undefined) =>
  actionCreator(SUB_SET_TEAM_ID, { teamId });

export const actionSubReset = () => actionCreator(SUB_RESET);
export const actionSubFinalize = () => actionCreator(SUB_FINALIZE);
export const actionSubCompletePage = (lastCompletedPage: number) =>
  actionCreator(SUB_COMPLETE_PAGE, { lastCompletedPage });

export const actionSetPageIndex = (pageIndex: number) =>
  actionCreator(SUB_SET_PAGE_INDEX, { pageIndex });

export const actionSetPlanId = (planId: string | undefined) =>
  actionCreator(SUB_SET_PLAN_ID, { planId });

export const actionSetPaymentMethodId = (paymentMethodId: string | undefined) =>
  actionCreator(SUB_SET_PAYMENT_METHOD_ID, { paymentMethodId });

export const actionSetBillingRate = (billingRate: string | undefined) =>
  actionCreator(SUB_SET_BILLING_RATE, { billingRate });

export const actionSetAvailablePlans = (
  availablePlans: SubscriptionProduct[] | undefined
) => actionCreator(SUB_SET_AVAILABLE_PLANS, { availablePlans });

type ActionType = ReturnType<
  | typeof actionSetPlanId
  | typeof actionSetTeamId
  | typeof actionSetBillingRate
  | typeof actionSetAvailablePlans
  | typeof actionSetPaymentMethodId
  | typeof actionSetPageIndex
  | typeof actionSubReset
  | typeof actionSubCompletePage
  | typeof actionSubFinalize
>;

const initialState = {
  pageIndex: 0,
  lastCompletedPage: 0
};

const subscriptionReducer = (
  state: SubscribeState = initialState,
  action: ActionType
): SubscribeState => {
  switch (action.type) {
    case SUB_SET_BILLING_RATE:
    case SUB_SET_TEAM_ID:
    case SUB_SET_PLAN_ID:
    case SUB_SET_AVAILABLE_PLANS:
    case SUB_SET_PAYMENT_METHOD_ID:
    case SUB_COMPLETE_PAGE:
      return {
        ...state,
        ...action.payload
      };
    case SUB_SET_PAGE_INDEX:
      return {
        ...state,
        pageIndex: Math.max(action.payload.pageIndex, 0)
      };
    case SUB_RESET:
      // reset but keep team id
      return { ...initialState, teamId: state.teamId };
  }
  return state;
};

export default subscriptionReducer;

const subClient = getClient(SubscriptionClient);

// action creators
export const getAvailablePlans = (): ThunkAction<
  Promise<void>,
  State,
  {},
  AnyAction
> => async (dispatch, getState) => {
  try {
    const plans = await subClient.getAvailableSubscriptions();
    dispatch(actionSetAvailablePlans(plans));
  } catch (e) {
    dispatch(
      actionAddErrorToast(
        "Failed to load available plans",
        "We are really sorry. Please try again later"
      )
    );
    dispatch(actionSetAvailablePlans([]));
  }
};

export const subscribe = (): ThunkAction<
  Promise<void>,
  State,
  {},
  AnyAction
> => async (dispatch, getState) => {
  const state = getState();
  try {
    await subClient.subscribeUserToPlan(
      state.subscribe.planId,
      state.subscribe.teamId,
      state.subscribe.paymentMethodId
    );
    dispatch(actionSubFinalize());
    dispatch(actionSetPageIndex(4));
  } catch (e) {
    console.error(e);
    handleErr(dispatch);
  }
};
