import * as React from "react";
import { styled, theme } from "../theme";

const StyledLink = styled.a<{ disabled: boolean }>`
  cursor: pointer;
  display: inline-block;
  font-weight: bold !important;
  padding: 0.5rem 0 0.5rem 1rem !important;
  color: red !important;
  opacity: ${props => (props.disabled ? "0.45" : "1")} !important;

  @media (min-width: 1024px) {
    padding: 0.5rem 0 0.5rem 1.5rem !important;
  }

  &:hover {
    color: ${theme.bodyFontColor} !important;
  }
`;

export const DeleteLink = (props: {
  title?: string;
  disabled?: boolean;
  onClick: (e) => void;
  children;
}) => (
  <StyledLink
    disabled={props.disabled}
    title={props.title}
    onClick={props.disabled ? null : props.onClick}
  >
    {props.children}
  </StyledLink>
);

DeleteLink.displayName = "DeleteLink";
