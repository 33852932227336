import * as React from "react";
import { Icon, Responsive } from "semantic-ui-react";

import { styled, BreakpointSize } from "../../../../theme";
import { scrollToTop } from "../../../../utils";
import { PAGES } from "../../Page";
import { StyledMenuItem } from "./components";
import SearchInput from "../SearchInput";
import Router from "../../router";
import UserCircle from "../../../../components/UserCircle";

const StyledItem = styled(StyledMenuItem)`
  @media (max-width: ${BreakpointSize.tablet}px) {
    font-size: 1rem !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
`;

const StyledSearchItem = styled(StyledItem)<{ selected?: boolean }>`
  position: inherit;
`;

const LoggedInMenu: React.FC<{
  onHideList: () => void;
  onClose: () => void;
  userName: string;
  userId: string;
  currentPage: string;
  isQuickMenu: boolean;
  onToggleQuickMenu: () => void;
}> = ({
  onHideList,
  currentPage,
  userName,
  userId,
  onClose,
  isQuickMenu,
  onToggleQuickMenu
}) => {
  const onUserClick = () => {
    onClose();
    Router.profile();
  };

  return (
    <>
      <StyledSearchItem active={currentPage === PAGES.search}>
        <Responsive maxWidth="1023">
          <Icon onClick={() => Router.search("")} name="search" />
        </Responsive>
        <Responsive minWidth="1024">
          <SearchInput />
        </Responsive>
      </StyledSearchItem>
      <StyledMenuItem
        active={currentPage === PAGES.profile}
        onClick={onUserClick}
      >
        <UserCircle onClick={onUserClick} name={userName} id={userId} noPopup />
      </StyledMenuItem>
      {/* <Responsive {...Responsive.onlyMobile} {...Responsive.onlyTablet}> */}
      <StyledItem
        onClick={(e: React.SyntheticEvent) => {
          e.preventDefault();
          e.stopPropagation();
          scrollToTop();
          onToggleQuickMenu();
        }}
      >
        <Icon
          style={{
            cursor: "pointer",
            padding: "0.7rem",
            width: "40px",
            height: "37px"
          }}
          name={isQuickMenu ? "close" : "bars"}
        />
      </StyledItem>
      {/* </Responsive> */}
    </>
  );
};

export default LoggedInMenu;
