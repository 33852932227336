import {SketchSummary} from '../services-async/vo/client';

export interface FuckingWindowAudio {
  sketchID: string;
  audios: PlaybackAudio[];
}

export interface PlaybackAudio {
  me: HTMLMediaElement;
  start: number;
  started: boolean;
  togglePaused: boolean;
}

const dataAttrLoadIndex = 'data-loading-index';


// eslint-disable-next-line no-restricted-globals
const getCurrentAudios = (): FuckingWindowAudio|undefined => self['audios'];

export const getAudios = () => {
  const currentAudios = getCurrentAudios();
  return currentAudios ? currentAudios.audios : [];
};

const setAudiosForSketch = (audios: PlaybackAudio[], sketch: SketchSummary) => {
  // eslint-disable-next-line no-restricted-globals
  self['audios'] = {sketchID: sketch.id, audios};
  console.log('=====> loaded audios', audios);
};


export const loadAudiosForSketch = (sketch: SketchSummary) =>
    new Promise((resolve, reject) => {
      const currenAudios = getCurrentAudios();
      if (currenAudios && currenAudios.sketchID === sketch.id) {
        // already loaded for this sketch
        console.log('current audios were loaded before');
        resolve();
        return;
      }

      if (sketch.audioTimestamps.length === 0) {
        // nothing to load for this sketch
        resolve();
        setAudiosForSketch([], sketch);
        return;
      }

      // let us load stuff
      const loaded: {[index: string]: boolean} = {};
      let resolvedForGood = false;
      let audios = sketch.audioTimestamps.map((ts, index) => {
        const audioInfo = sketch.audioInfos[index];
        const audio = document.createElement('audio');
        const loadIndex = 'load-index-' + index;
        loaded[loadIndex] = false;
        audio.setAttribute('src', '/sketch/audio/' + sketch.id + '/' + ts);
        audio.setAttribute(dataAttrLoadIndex, loadIndex);
        console.log('[audio] loading clip ' + audio.src)
        audio.load();
        audio.oncanplaythrough = e => {
          if (resolvedForGood) {
            console.log('already loaded audios for sketch...');
            return;
          }
          const targetElement: HTMLAudioElement =
              e.srcElement as HTMLAudioElement;
          loaded[targetElement.getAttribute(dataAttrLoadIndex)] = true;
          let allLoaded = true;
          for (const key in loaded) {
            if (loaded[key] === false) {
              allLoaded = false;
              break;
            }
          }
          if (allLoaded) {
            resolvedForGood = true;
            audios = audios.sort((a, b) => a.start - b.start);
            setAudiosForSketch(audios, sketch);
            console.log('loaded audios', loaded);
            resolve();
          }
        };
        const r = (reason: string) => reject(reason + ' : ' + audioInfo.start);

        audio.onabort = e => r('audio load was aborted');
        audio.oncancel = e => r('audio load cancelled');
        audio.onstalled = e => r('audio load stalled');
        audio.onerror = e => r('audio load error ' + e.toString());

        return {
          me: audio,
          start: audioInfo.start,
          started: false,
          togglePaused: false
        };
      });
    });
